/** @jsxImportSource react */
import { StaticImageData } from 'next/image';
import Image, { ImageLoaderProps, ImageProps } from 'next/image';
import React from 'react';

interface StaticRequire {
  default: StaticImageData;
}

declare type StaticImport = StaticRequire | StaticImageData;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
interface NextImageProps extends ImageProps {
  // aspectRatio?: string;
  imgStyle?: Record<string, unknown>;
  path?: string;
  src?: string | StaticImport;
  // wrapperRef?: React.RefObject<HTMLDivElement> | null;
  // imageRef?: React.RefObject<HTMLImageElement> | null;
  ref?: React.RefObject<HTMLImageElement> | null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getAlt = (src: any): string => {
  if (!src) {
    return 'Seniorly Image';
  }
  const srcParts = src.split('/')[1].split('.')[0];
  return decodeURIComponent(srcParts);
};

export default function NextImage({
  src,
  width,
  height,
  layout,
  alt,
  path,
  ...rest
}: NextImageProps) {
  if (path) {
    src = path;
    if (!layout) {
      layout = 'fill';
    }
  }
  if (!src && !path) {
    if (!layout) {
      layout = 'fill';
    }
    src =
      'https://d354o3y6yz93dt.cloudfront.net/uploads/react-assets/img-placeholder.png';
  }
  return (
    <Image
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      src={src}
      width={width}
      height={height}
      layout={layout}
      alt={alt || getAlt(src)}
      loader={path ? pathLoader : customLoader}
      // placeholder='blur'
      // blurDataURL={`data:image/svg+xml;base64,${toBase64(
      //   shimmer(width ? +width : 700, height ? +height : 475)
      // )}`}
      // ToDo : OnError handling
      {...rest}
      data-tp-id='gen-Image-110ea9d2-4862-41a2-b31f-6216857b6408'
    ></Image>
  );
}

const pathLoader = ({ src, width }: ImageLoaderProps): string => {
  return `https://d354o3y6yz93dt.cloudfront.net/images/${width}x/${encodeURI(
    src
  )}`;
};

const customLoader = ({ src }: ImageLoaderProps): string => {
  return src;
};

const shimmer = (w: number, h: number) => `
<svg width="${w}" height="${h}" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stop-color='#f6f7f8' offset='0%' />
      <stop stop-color='#edeef1' offset='20%' />
      <stop stop-color='#f6f7f8' offset='40%' />
      <stop stop-color='#f6f7f8' offset='70%' />
    </linearGradient>
  </defs>
  <rect width="${w}" height="${h}" fill="#f6f7f8" />
  <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
</svg>`;

const toBase64 = (str: string) =>
  typeof window === 'undefined'
    ? Buffer.from(str).toString('base64')
    : window.btoa(str);
