/** @jsxImportSource react */
import { getImages } from '@common/helpers/gallery';
import clsxm from '@common/lib/clsxm';
import { eventNames, useEvents } from '@react/services/events';
import Image from '@react/shared/components/Image';
import { Chevron, Image as SvgImage } from '@sly/icons/react';
import cx from 'classnames';
import { memo, useMemo } from 'react';
import React, { useCallback, useState } from 'react';

import { galleryImage, hiddenMap } from './CardClasses';

const ProfileHeroImageContainer = ({
  entity,
  intent,
  preloadFirstImage,
  newTab,
}) => {
  if (!entity) return null;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isPlaceHolder, images } = useMemo(
    () => getImages({ ...entity, size: 5, aspectRatio: '1:1' }),
    [entity]
  );

  return (
    <ProfileHeroImage
      images={images}
      isPlaceHolder={isPlaceHolder}
      communitySlug={entity.id}
      intent={intent}
      url={entity.url}
      preloadFirstImage={preloadFirstImage}
      newTab={newTab}
    />
  );
};

ProfileHeroImageContainer.typeHydrationId = 'CommunityMediaGalleryContainer';
ProfileHeroImageContainer.whyDidYouRender = true;

export default memo(ProfileHeroImageContainer);

const minSwipeDistance = 50;

export const ProfileHeroImage = ({
  images,
  communitySlug,
  intent,
  url,
  preloadFirstImage,
  newTab,
}) => {
  const { events } = useEvents();

  const [currentIndex, setCurrentIndex] = useState(0);
  const imageRef = React.useRef([]);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const hasMultipleImages = images.length > 1;

  const lastImageIndex = images.length - 1;

  const getImageClassName = useCallback(
    (index) => (index === currentIndex ? 'block' : 'hidden'),
    [currentIndex]
  );

  const goLeft = useCallback(
    (event) => {
      if (currentIndex === 0) {
        setCurrentIndex(lastImageIndex);
      } else {
        setCurrentIndex(currentIndex - 1);
      }
      events.track(eventNames?.ButtonClicked, {
        text: 'Previous image',
        location: 'profile hero image section',
        communitySlug,
        cta: `${event} left`,
      });
    },
    [communitySlug, currentIndex, events, lastImageIndex]
  );

  const goRight = useCallback(
    (event) => {
      if (currentIndex === lastImageIndex) {
        setCurrentIndex(0);
      } else {
        setCurrentIndex(currentIndex + 1);
      }
      events.track(eventNames?.ButtonClicked, {
        text: 'Next image',
        location: 'profile hero image section',
        communitySlug,
        cta: `${event} right`,
      });
    },
    [communitySlug, currentIndex, events, lastImageIndex]
  );

  const onTouchStart = useCallback((e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  }, []);

  const onTouchMove = useCallback(
    (e) => setTouchEnd(e.targetTouches[0].clientX),
    []
  );

  const onTouchEnd = useCallback(() => {
    if (!hasMultipleImages) {
      return;
    }
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe || isRightSwipe) {
      isLeftSwipe ? goRight('Swipe') : goLeft('Swipe');
    }
  }, [goLeft, goRight, touchEnd, touchStart, hasMultipleImages]);

  const getLoadingAttribute = (index) => {
    if (currentIndex !== 0 && currentIndex === index - 1) {
      return 'eager';
    } else {
      return 'lazy';
    }
  };

  const navigateToGallery = () => {
    if (newTab) {
      window.open(`${url}?section=photos`);
    } else {
      window.location.href = `${url}?section=photos`;
    }
  };

  return (
    <>
      <div className='relative h-full w-full'>
        <div
          className={clsxm(
            'no-scrollbar mb-0 mt-0 flex min-w-full snap-x snap-mandatory list-none overflow-x-auto pl-0 md:h-full'
          )}
        >
          {images.map(({ id, description, alt, path, ...rest }, index) => {
            const imageClassName =
              index === 0 ? 'md:row-span-2' : 'md:row-span-1';

            return (
              <div
                className={cx(
                  'gallery__item w-full shrink-0 grow-0 basis-full snap-start snap-always overflow-hidden',
                  imageClassName,
                  getImageClassName(index)
                )}
                key={`${id}${path}`}
                onTouchStart={onTouchStart}
                onTouchMove={onTouchMove}
                onTouchEnd={onTouchEnd}
              >
                <div ref={(el) => (imageRef.current[index] = el)}>
                  <Image
                    {...rest}
                    className={galleryImage({ intent })}
                    objectFit='cover'
                    layout='fill'
                    path={path}
                    alt={alt || description}
                    sizes='(min-width: 728px) 328px, 288px'
                    priority={index === 0 && preloadFirstImage}
                    loading={
                      index === 0 && preloadFirstImage
                        ? 'eager'
                        : getLoadingAttribute(index)
                    }
                  />
                </div>
                {index === 4 && (
                  <>
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        navigateToGallery();
                      }}
                      className='absolute left-0 right-0 top-0 bottom-0 z-10 rounded-lg bg-black opacity-50'
                    />
                    <SvgImage
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        navigateToGallery();
                      }}
                      className='absolute left-0 right-0 top-0 bottom-0 z-10 mx-auto my-auto text-white'
                    />
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        navigateToGallery();
                      }}
                      className='absolute left-0 right-0 top-1/2 z-10 mx-auto my-auto mt-4 text-center text-white'
                    >
                      See all photos
                    </div>
                  </>
                )}
              </div>
            );
          })}
          {hasMultipleImages && (
            <>
              {currentIndex !== 0 && (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    goLeft('ButtonClicked');
                  }}
                  className={cx(
                    'absolute left-2 top-1/2 z-10 translate-y-[-50%] cursor-pointer rounded-full bg-white p-1 opacity-80 shadow-sm hover:opacity-100',
                    hiddenMap({ intent })
                  )}
                  data-testid='left-arrow'
                >
                  <Chevron className='-rotate-90 text-black' size='m' />
                </button>
              )}
              {currentIndex !== images.length - 1 && (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    goRight('ButtonClicked');
                  }}
                  className={cx(
                    'absolute right-2 top-1/2 z-10 translate-y-[-50%] cursor-pointer  rounded-full bg-white p-1 opacity-80 shadow-sm hover:opacity-100',
                    hiddenMap({ intent })
                  )}
                  data-testid='right-arrow'
                >
                  <Chevron className='rotate-90 text-black' size='m' />
                </button>
              )}
            </>
          )}
          {hasMultipleImages && (
            <div
              className={cx(
                'absolute bottom-3 left-0 right-0 z-10 mx-auto flex flex-row justify-center gap-2',
                hiddenMap({ intent, display: 'flex' })
              )}
            >
              {images.map((_, index) => (
                <div
                  key={index}
                  className={cx(
                    'h-2 w-2 rounded-full bg-white',
                    index !== currentIndex && 'opacity-60'
                  )}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
