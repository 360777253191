import React, { forwardRef, Ref } from 'react';
import { SimpleIconProps } from '../icon.types';
import Icon from './Icon';

const svg = `<svg viewBox="0 0 24 24" fill="currentColor">
  <path d="M17.8619 20.1712L13.4728 15.7821L15.6984 13.5506L20.0875 17.9397C20.3908 18.2429 20.5424 18.6149 20.5424 19.0555C20.5424 19.496 20.3908 19.8679 20.0875 20.1712C19.7842 20.4745 19.4133 20.6261 18.9747 20.6261C18.5361 20.6261 18.1652 20.4745 17.8619 20.1712ZM4.00217 20.1712C3.6989 19.8679 3.54727 19.496 3.54727 19.0555C3.54727 18.6149 3.6989 18.2429 4.00217 17.9397L9.8163 12.1256L8.06847 10.3777C7.85977 10.5864 7.6181 10.6824 7.34347 10.6658C7.06884 10.6491 6.83786 10.5471 6.65055 10.3598L6.17717 9.8924V11.8587L6.03207 12.0038C5.86214 12.1737 5.66222 12.2587 5.43232 12.2587C5.20244 12.2587 5.00253 12.1737 4.8326 12.0038L2.8337 10.0049C2.66376 9.83497 2.5788 9.63505 2.5788 9.40515C2.5788 9.17527 2.66376 8.97536 2.8337 8.80543L2.9788 8.66033H4.9451L4.49672 8.21195C4.31665 7.97609 4.2336 7.71485 4.24755 7.42825C4.2615 7.14167 4.37046 6.89638 4.57445 6.6924L7.39455 3.87825C7.73586 3.53695 8.10715 3.28831 8.50842 3.13233C8.90969 2.97636 9.3183 2.89838 9.73425 2.89838C10.1502 2.89838 10.5598 2.97736 10.963 3.13533C11.3663 3.29329 11.7386 3.54093 12.0799 3.87825L9.74402 6.20815L10.1924 6.65653C10.4036 6.86776 10.5236 7.11169 10.5522 7.38833C10.5808 7.66494 10.4927 7.91956 10.288 8.15218L12.0418 9.90598L14.3277 7.6201C14.265 7.44075 14.2139 7.25208 14.1742 7.05408C14.1345 6.85608 14.1147 6.65907 14.1147 6.46305C14.1147 5.4558 14.4611 4.60471 15.1541 3.90978C15.847 3.21486 16.6971 2.8674 17.7043 2.8674C17.9743 2.8674 18.2307 2.89539 18.4736 2.95135C18.7166 3.00734 18.9627 3.09529 19.212 3.21523L16.737 5.6962L18.4712 7.43043L20.9522 4.94945C21.0888 5.19475 21.1799 5.44086 21.2255 5.68778C21.2712 5.93469 21.294 6.19312 21.294 6.46305C21.294 7.4703 20.9476 8.32039 20.2546 9.01333C19.5617 9.70626 18.7116 10.0527 17.7043 10.0527C17.5083 10.0527 17.3133 10.0361 17.1193 10.0027C16.9253 9.96939 16.7386 9.91504 16.5592 9.83968L6.2337 20.1712C5.93043 20.4745 5.5585 20.6261 5.11792 20.6261C4.67735 20.6261 4.30544 20.4745 4.00217 20.1712Z"/>
</svg>`;

const Construction = forwardRef(
  (props: SimpleIconProps, ref: Ref<SVGSVGElement> | undefined) => (
    <Icon ref={ref} name='construction' svg={svg} {...props} />
  )
);

Construction.displayName = 'ConstructionIcon';

export default Construction;
