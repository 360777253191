export const setAsyncError = ([fieldName, error], state) => {
  if (error !== undefined) {
    state.formState.asyncErrors[fieldName] = error;
  } else {
    delete state.formState.asyncErrors[fieldName];
  }
};

export const setSubmitting = ([submitting], state) => {
  state.formState.submitting = submitting;
};

export const setValidating = ([validating], state) => {
  state.formState.validating = validating;
};
