/** @jsxImportSource react */
import { eventNames, useEvents } from '@react/services/events';
import ReadMore from '@react/shared/components/ReadMore';
import { arrayOf, bool, node, oneOfType, string } from 'proptypes';

import { entityText } from './CardClasses';

const EntityText = ({ children, slug, className, intent }) => {
  const { events } = useEvents();
  const handleReadMoreClick = (displaySecondary) => {
    events.track(eventNames.ButtonClicked, {
      text: displaySecondary ? 'Read Less' : 'Read More',
      location: 'Entity tile',
      communitySlug: slug,
    });
  };

  return (
    <ReadMore
      buttonClassName='!text-viridian-base'
      className={className}
      clampClassName={entityText({ intent })}
      onClick={handleReadMoreClick}
      data-testid='card-description'
    >
      {children}
    </ReadMore>
  );
};

EntityText.propTypes = {
  inverted: bool,
  slug: string,
  children: oneOfType([arrayOf(node), node]).isRequired,
};

export default EntityText;
