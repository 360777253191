import React, { forwardRef, Ref } from 'react';
import { SimpleIconProps } from '../icon.types';
import Icon from './Icon';

const svg = `<svg viewBox="0 0 24 24" fill="currentColor">
  <path d="M9.55 14.9467L17.9174 6.57934C18.1453 6.35144 18.4157 6.23749 18.7285 6.23749C19.0414 6.23749 19.3118 6.35144 19.5397 6.57934C19.7676 6.80724 19.8815 7.07762 19.8815 7.39049C19.8815 7.70334 19.7676 7.97371 19.5397 8.20161L10.3516 17.3897C10.1237 17.6176 9.85652 17.7315 9.55 17.7315C9.24348 17.7315 8.97628 17.6176 8.74838 17.3897L4.46033 13.1016C4.23243 12.8737 4.12164 12.6033 4.12798 12.2905C4.13433 11.9776 4.25145 11.7072 4.47935 11.4793C4.70725 11.2514 4.97763 11.1375 5.2905 11.1375C5.60335 11.1375 5.87373 11.2514 6.10163 11.4793L9.55 14.9467Z"/>
</svg>`;

const Checkmark = forwardRef(
  (props: SimpleIconProps, ref: Ref<SVGSVGElement> | undefined) => (
    <Icon ref={ref} name='checkmark' svg={svg} {...props} />
  )
);

Checkmark.displayName = 'CheckmarkIcon';

export default Checkmark;
