/* eslint-disable no-async-promise-executor */
import { titleize } from '@common/helpers/strings';
import {
  getCannonicalStatePath,
  getStateAbbr,
  objectToURLQueryParams,
  parseURLQueryParams,
  snakeCase,
  urlize,
} from '@common/helpers/url';
import { amenitiesAndServiceItems } from '@sly/core/constants/amenitiesAndServices';
import { CARE_TYPES_OBJECT } from '@sly/core/constants/careTypes';
import {
  CITY,
  COMMUNITY,
  EXACT_COUNTY,
  GEO,
  GEOMETRY,
  INCLUDE,
  PAGE_NUMBER,
  PAGE_SIZE,
  SIZE,
  SL,
  STARTING_RATE,
  STATE,
  TOC,
} from '@sly/core/constants/filters';
import { formatMoney } from '@sly/core/helpers/numbers';
import omit from 'lodash/omit';
import queryString, { stringify } from 'query-string';
import { isBrowser } from 'sly/config';

import { communitySizeSearchParamMap } from '../constants';

export const getRadiusFromMapBounds = (bounds) => {
  const center = bounds.getCenter();
  const ne = bounds.getNorthEast();

  // r = radius of the earth in miles
  const r = 3963.0;

  // Convert lat or lng from decimal degrees into radians (divide by 57.2958)
  const lat1 = center.lat() / 57.2958;
  const lon1 = center.lng() / 57.2958;
  const lat2 = ne.lat() / 57.2958;
  const lon2 = ne.lng() / 57.2958;

  // distance = circle radius from center to Northeast corner of bounds
  const dis =
    r *
    Math.acos(
      Math.sin(lat1) * Math.sin(lat2) +
        Math.cos(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1)
    );
  return Math.round(dis);
};

const searchParamsWhitelist = [
  'toc',
  'geo',
  'state',
  'city',
  'care-services',
  'non-care-services',
  'room-amenities',
  'community-amenities',
  'communitySlug',
  'size',
  'starting-rate',
  'sort',
  'page-number',
  'page-size',
  'modal',
  'token',
  'entityId',
  'currentStep',
  'redirectTo',
  'action',
  'region',
  'type',
  'name',
  'title',
  'organization',
  'provider',
  'providerType',
  'county',
  'geometryBuffer',
  'geometry',
  'communityService',
  'diningService',
  'healthCareService',
  'curated',
];

const canonicalParamsWhitelist = [
  'toc',
  'state',
  'city',
  'page-number',
  'county',
];

export const tocs = [
  {
    label: 'Assisted Living',
    value: 'assisted-living',
    segment: 'assisted-living',
    searchLabel: 'Assisted Living Communities',
    seoLabel: 'Assisted Living Facilities',
    apiValue: 'Assisted Living',
  },
  {
    label: 'Independent Living',
    value: 'independent-living',
    segment: 'independent-living',
    searchLabel: 'Independent Living Communities',
    seoLabel: 'Independent Living Communities',
    apiValue: 'Independent Living',
  },
  {
    label: 'Board and Care Home',
    value: 'board-and-care-home',
    segment: 'board-and-care-home',
    searchLabel: 'Board and Care Homes',
    seoLabel: 'Board and Care Homes',
    apiValue: 'Board and Care Home',
  },
  {
    label: 'Memory Care',
    value: 'memory-care',
    segment: 'memory-care',
    searchLabel: 'Memory Care Communities',
    seoLabel: 'Memory Care Facilities',
    apiValue: 'Memory Care',
  },
  {
    label: 'Continuing Care Retirement Communities',
    value: 'continuing-care-retirement-community',
    segment: 'continuing-care-retirement-community',
    searchLabel: 'CCRCs',
    seoLabel: 'Continuing Care Retirement Communities',
    apiValue: 'Continuing Care Retirement Community(CCRC)',
  },
  {
    label: 'Active Adult Communities (55+)',
    value: 'active-adult',
    segment: 'active-adult',
    searchLabel: 'Active Adult Communities (55+)',
    seoLabel: 'Active Adult Communities (55+)',
    apiValue: 'Active Adult Communities (55+)',
  },
  {
    label: 'Nursing Homes',
    value: 'skilled-nursing-facility',
    segment: 'skilled-nursing-facility',
    searchLabel: 'Skilled Nursing Facility',
    seoLabel: 'Nursing Homes',
    apiValue: 'Skilled Nursing Facility',
  },
  {
    label: 'Care Home',
    value: 'care-home',
    segment: 'care-home',
    searchLabel: 'Care Homes',
    seoLabel: 'Care Homes',
    apiValue: 'Care Home',
  },
];

export const sizes = [
  { label: 'Small', segment: 'less-than-20-beds', value: 'small' },
  { label: 'Medium', segment: '20-to-51-beds', value: 'medium' },
  { label: 'Large', segment: 'greater-than-51-beds', value: 'large' },
];

export const budgets = [
  { label: 'Up to $2500', segment: '2500-dollars', value: 2500 },
  { label: 'Up to $3000', segment: '3000-dollars', value: 3000 },
  { label: 'Up to $3500', segment: '3500-dollars', value: 3500 },
  { label: 'Up to $4000', segment: '4000-dollars', value: 4000 },
  { label: 'Up to $4500', segment: '4500-dollars', value: 4500 },
  { label: 'Up to $5000', segment: '5000-dollars', value: 5000 },
  { label: 'Up to $5500', segment: '5500-dollars', value: 5500 },
  { label: 'Up to $6000', segment: '6000-dollars', value: 6000 },
  {
    label: 'More than $6000',
    segment: 'greater-than-6000-dollars',
    value: 100000,
  },
];

/** Not used currently
const findAFilter = (ary, filters='') => filters.split('/')
  .reduce((cumul, filter) => {
    return ary
      .reduce((cumul, item) => {
        if (item.segment === filter) return item.segment;
        return cumul;
      }, cumul)
  }, undefined);
 */
const standardizableParams = ['state', 'city']; // add

export const getStandardisedCountyName = (county) => {
  // remove county part
  let result =
    county.indexOf('-county') > -1
      ? county.substring(0, county.indexOf('-county'))
      : county;
  return titleize(result);
};

// Attempt to standardize input geographical state values e.g: CA to california, fl to florida
export const standardizeSearchParam = (key, val) => {
  if (standardizableParams.indexOf(key) > -1) {
    if (key === 'state') {
      val = getCannonicalStatePath(val);
    } else if (key === 'city') {
      val = urlize(titleize(snakeCase(val)));
    }
  }
  return val;
};

export const filterSearchParams = (
  params,
  paramsWhitelist = searchParamsWhitelist
) =>
  Object.keys(params).reduce(
    (cumul, key) => {
      const [keep, remove] = cumul;
      if (paramsWhitelist.includes(key) && params[key]) {
        keep[key] = standardizeSearchParam(key, params[key]);
      } else {
        remove[key] = params[key];
      }
      return cumul;
    },
    [{}, {}]
  );

const validNumber = (x) => typeof x === 'number' || x === undefined;
export const filterLinkPath = (currentFilters, nextFilters = {}) => {
  let pageFilters = {
    'page-number': currentFilters['page-number'] || null,
    'page-size': currentFilters['page-size'] || null,
  };
  if (
    validNumber(nextFilters['page-number']) ||
    validNumber(nextFilters['page-size'])
  ) {
    pageFilters = {
      'page-number': nextFilters['page-number'],
      'page-size': nextFilters['page-size'],
    };
  }

  const [filters] = filterSearchParams({
    ...currentFilters,
    ...nextFilters,
    ...pageFilters,
  });

  const { toc, state, city, county, communitySlug, ...qs } = filters;

  let path = `/${toc}`;
  const qsString = stringify(qs);
  const qsPart = qsString ? `?${qsString}` : '';
  if (state && city && communitySlug) {
    path = `/${toc}/${state}/${city}/${communitySlug}${qsPart}`;
  } else if (state && city) {
    path = `/${toc}/${state}/${city}${qsPart}`;
  } else if (state && county) {
    path = `/${toc}/${state}/${county}${qsPart}`;
  } else if (state) {
    path = `/${toc}/${state}${qsPart}`;
  }

  return path;
};

export const getSearchParams = (query) => {
  if (query.city && query.city.indexOf('-county') > -1) {
    query.county = query.city;
    query.city = null;
  }
  const [filters] = filterSearchParams(query);
  return filters;
};

export const getSearchParamsLegacy = ({ params }, location) => {
  const qs = parseURLQueryParams(location.search);
  const [filters] = filterSearchParams({
    ...params,
    ...qs,
  });
  return filters;
};

export const getCanonicalPath = (
  pathname,
  paginationFilters,
  current,
  total
) => {
  const [filteredParams, rejectParams] = filterSearchParams(
    paginationFilters,
    canonicalParamsWhitelist
  );

  const makePath = (toPage) => {
    if (toPage < 1) return null;
    if (toPage > total) return null;

    const params = {
      ...paginationFilters,
    };

    params['page-number'] = toPage === 1 ? undefined : toPage;

    const qs = objectToURLQueryParams(params);

    return `${pathname.toLowerCase()}${qs ? `?${qs}` : ''}`;
  };

  const canonical = makePath(current);
  const prevLink = makePath(current - 1);
  const nextLink = makePath(current + 1);

  return [
    { canonical, next: nextLink, prev: prevLink },
    filteredParams,
    rejectParams,
  ];
};

export const getAgentParams = ({ params }, location) => {
  const qs = parseURLQueryParams(location.search);
  const filters = {};
  filters['filter[status]'] = 'gt:0';
  if (qs.latitude && qs.longitude) {
    filters['filter[geo]'] = `${qs.latitude},${qs.longitude},20`;
  } else if (params.city) {
    // parse state from city
    const cityParts = params.city.split('-');
    if (cityParts.length > 1) {
      const state = cityParts.pop();
      const city = cityParts.join('-');
      filters['filter[address]'] = `${city},${state}`;
    }
  } else {
    filters['filter[region]'] = params.region;
  }

  return filters;
};

export const getFiltersApplied = (searchParams) => {
  const { size, budget } = searchParams;
  const filtersApplied = [];
  if (size) filtersApplied.push(SIZE);
  if (budget) filtersApplied.push(STARTING_RATE);
  return filtersApplied;
};

export const getEvtHandler = (paramsToRemove, origFn) => {
  return (uiEvt) => {
    origFn({ origUiEvt: uiEvt, paramsToRemove });
  };
};

export const getTocSeoLabel = (toc) => {
  const actualToc = tocs.find((elem) => elem.value === toc);
  if (typeof actualToc === 'undefined') {
    return 'Senior Living';
  }
  return actualToc.seoLabel;
};

export const getTocLabel = (tocValue) => {
  const actualToc = tocs.find((elem) => elem.value === tocValue);
  if (typeof actualToc === 'undefined') {
    return 'Senior Living';
  }
  return actualToc.label;
};

export const getLocationLabel = (searchParams) => {
  const { city, state } = searchParams;
  if (city && state) {
    return `${titleize(city)}, ${getStateAbbr(state)}`;
  } else if (state) {
    return titleize(`${state}`);
  }
  return 'Your Area';
};

// eslint-disable-next-line camelcase
export const getSearchParamFromPlacesResponse = (
  { address_components, geometry },
  toc = tocs[0].value
) => {
  const cityFull = address_components.filter(
    (e) =>
      e.types.indexOf('locality') > -1 ||
      e.types.indexOf('sublocality') > -1 ||
      e.types.indexOf('administrative_area_level_3') > -1
  );

  const stateFull = address_components.filter(
    (e) => e.types.indexOf('administrative_area_level_1') > -1
  );
  const zipFull = address_components.filter(
    (e) => e.types.indexOf('postal_code') > -1
  );
  const countyFull = address_components.filter(
    (e) => e.types.indexOf('administrative_area_level_2') > -1
  );

  if (cityFull.length > 0 && stateFull.length > 0) {
    const city = urlize(cityFull[0].long_name);
    const state = urlize(stateFull[0].long_name);
    const zip = zipFull.length > 0 ? zipFull[0].long_name : '';
    const { lat, lng } = geometry.location.toJSON();
    // only pass geo arg when we have a zipCode
    const geo = zipFull.length ? `${lat},${lng},10` : undefined;
    return {
      toc,
      state,
      city,
      zip,
      geo,
    };
  } else if (countyFull.length > 0) {
    const state = urlize(stateFull[0].long_name);
    const county = urlize(countyFull[0].long_name);
    return {
      toc,
      state,
      county,
    };
  } else if (stateFull.length > 0) {
    const state = urlize(stateFull[0].long_name);
    return { toc, state };
  }
  return { toc };
};

export const getApiFilters = (filters) => {
  const res = {};
  const pageNumber = parseInt(filters[PAGE_NUMBER] || 1, 10);
  if (pageNumber >= 2) {
    res[PAGE_NUMBER] = pageNumber - 1;
  }

  if (filters[PAGE_SIZE]) {
    res[PAGE_SIZE] = parseInt(filters[PAGE_SIZE], 10);
  }

  res[INCLUDE] = COMMUNITY;

  const omitFilters = [];

  if (filters[GEO] || filters[GEOMETRY]) {
    omitFilters.push(CITY, STATE);
  }

  if (filters[TOC] === SL) {
    omitFilters.push(TOC);
  }

  return Object.entries(omit(filters, omitFilters)).reduce(
    (acc, [key, value]) => {
      if (key === GEO) {
        acc[`filter[${key}]`] = Array.isArray(value) ? value.join(',') : value;
      } else if (key === 'county') {
        acc[`filter[${EXACT_COUNTY}]`] = getStandardisedCountyName(value);
      } else if (!acc[key]) {
        acc[`filter[${key}]`] = encodeURIComponent(value);
      }
      return acc;
    },
    res
  );
};

export const getPagination = (requestMeta, location, currentFilters) => {
  let current;
  let total;
  let start;
  let end;
  let count;

  if (requestMeta) {
    const pn = requestMeta['page-number'] ?? 0;
    const fc = requestMeta['filtered-count'] ?? 0;
    const ps = requestMeta['page-size'] ?? 20;
    current = pn + 1;
    total = Math.ceil(fc / ps);
    count = fc;
    const present = pn * ps;
    start = present + 1;
    end = present + ps > count ? count : present + ps;
  }
  const paginationFilters = { ...currentFilters };
  // remove city/state/toc/page-number from map
  ['toc', 'state', 'city', 'page-number', 'county'].forEach(
    (e) => delete paginationFilters[e]
  );
  const qs = queryString.stringify(paginationFilters);

  let basePath = location.pathname;
  if (qs.length > 0) {
    basePath = `${basePath}?${qs}`;
  }

  const [linkRel, filteredParams, rejectParams] = getCanonicalPath(
    location.pathname,
    paginationFilters,
    current,
    total
  );

  return {
    current,
    total,
    start,
    end,
    count,
    basePath,
    linkRel,
    filteredParams,
    rejectParams,
  };
};

const emptySearchPreferenceObject = {
  housingInfo: {},
  financialInfo: {},
  locationInfo: {},
};

export const getSearchPreferenceFromLocalStorage = () => {
  if (!isBrowser) {
    return emptySearchPreferenceObject;
  }
  if (!localStorage.getItem('SearchPreference')) {
    localStorage.setItem(
      'SearchPreference',
      JSON.stringify(emptySearchPreferenceObject)
    );
    return emptySearchPreferenceObject;
  }
  return JSON.parse(localStorage.getItem('SearchPreference'));
};

// value which is used in search filters will be converted to Standard Value
// E.g. assisted-living to Assisted Living
export const getCareTypeApiValueFromValue = (value) => {
  const result = tocs.find((toc) => toc.value === value);
  return result ? result.apiValue : null;
};

// Standard Care Type Value converted to search filter care-type
// E.g. Assisted Living to assisted-living
export const getCareTypeValueFromApiValue = (apiValue) => {
  const result = tocs.find((toc) => toc.apiValue === apiValue);
  return result ? result.value : null;
};

// Standard Care Type Label converted to search filter care-type
// E.g. Continuing Care Retirement Communities to continuing-care-retirement-community
export const getCareTypeValueFromLabel = (label) => {
  const result = tocs.find((toc) => toc.label === label);
  return result ? result.value : null;
};

// up to 20 Beds will be converted to small
export const getSizeFromApiValue = (apiValue) => {
  return communitySizeSearchParamMap[apiValue];
};

// small to be converted to up to 20 Beds
export const getSizeApiValueFromValue = (value) => {
  const apiSizeValues = Object.keys(communitySizeSearchParamMap);
  for (let i = 0; i < apiSizeValues.length; i++) {
    if (communitySizeSearchParamMap[apiSizeValues[i]] === value) {
      return apiSizeValues[i];
    }
  }
  return null;
};

// 2500 will be converted to '$2K-$3K'
const getPricingRangeFromValue = (value) => {
  value = Number(value);
  if (value < 2000) {
    return '<$2K';
  } else if (value >= 2000 && value <= 3000) {
    return '$2K-$3K';
  } else if (value > 3000 && value <= 4000) {
    return '$3K-$4K';
  } else if (value > 4000 && value <= 5000) {
    return '$4K-$5K';
  }
  return '$5K+';
};

// '$2K-$3K' will be converted to 3000
const getPricingValueFromRange = (range) => {
  if (range === '<$2K') {
    return 2500;
  } else if (range === '$2K-$3K') {
    return 3000;
  } else if (range === '$3K-$4K') {
    return 4000;
  } else if (range === '$4K-$5K') {
    return 5000;
  }
  return 100000;
};

// Search Page Url / Params will be modified with stored search preference
export const constructSearchPathWithPreference = (data) => {
  const { params, currentPath } = data;
  let currentFilters = {};
  if (!currentPath && !params) {
    return {
      modifiedParams: params,
      modifiedPath: currentPath,
    };
  }

  if (currentPath) {
    const pathParams = currentPath.split('?')[0].split('/');
    const toc = pathParams[1];
    const state = pathParams[2];
    const city = pathParams[3];
    currentFilters = getSearchParamsLegacy(
      { params: { toc, state, city } },
      { search: currentPath.split('?')[1] || '' }
    );
  } else if (params) {
    currentFilters = params;
  }

  return {
    modifiedParams: currentFilters,
    modifiedPath: filterLinkPath({}, currentFilters),
  };
};

const zipCodeSearch = {
  values: ['postal_code'],
  label: 'zip',
  allowed: ['zip'],
  precedence: 1,
};
const citySearch = {
  values: ['locality', 'sublocality', 'administrative_area_level_3'],
  label: 'city',
  allowed: ['city', 'state'],
  precedence: 2,
};
const countySearch = {
  values: ['administrative_area_level_2'],
  label: 'county',
  allowed: ['county', 'state'],
  precedence: 3,
};
const stateSearch = {
  values: ['administrative_area_level_1'],
  label: 'state',
  allowed: ['state'],
  precedence: 4,
};

const searchParamArray = [zipCodeSearch, countySearch, citySearch, stateSearch];

export const getLocationSearchParamsList = ({
  address_components: addressComponent,
}) => {
  const search = addressComponent.reduce((acc, current) => {
    searchParamArray.forEach(({ label, values, allowed, precedence }) => {
      const currentTypes = current?.types || [];
      if (
        currentTypes.length > 0 &&
        currentTypes.some((r) => values.indexOf(r) >= 0)
      ) {
        acc[label] = { value: current?.long_name, allowed, precedence };
      }
    });
    return acc;
  }, {});

  const [currentFitler] = Object.entries(search).sort(
    ([, a], [, b]) => a.precedence - b.precedence
  );

  const filters = Object.entries(search).reduce((acc, current) => {
    const [filterName, { value }] = current;
    if (currentFitler[1]?.allowed.indexOf(filterName) >= 0) {
      acc[filterName] = value;
    }
    return acc;
  }, {});

  return filters;
};

const getLocationSearchParams = (address, validSearchParams) => {
  const currentTypes = address?.types || [];
  const searchParam = {};
  validSearchParams.forEach(({ label, values }) => {
    if (
      currentTypes.length > 0 &&
      currentTypes.some((r) => values.indexOf(r) >= 0)
    ) {
      searchParam[label] = address?.long_name;
    }
  });
  return searchParam;
};

export const getExactMatchSearchParam = ({
  address_components: addressComponent,
}) => {
  const [firstAddressComponent] = addressComponent || {};
  return getLocationSearchParams(firstAddressComponent, searchParamArray);
};

export const GetSeoContent = ({
  state,
  city,
  toc,
  totalEntities,
  priceRange,
}) => {
  const high = formatMoney(priceRange.high);
  const low = formatMoney(priceRange.low);

  switch (toc) {
    case CARE_TYPES_OBJECT.snf:
      return (
        <>
          {`Get an overview of skilled nursing facilities in ${city}, ${state} in order to start reviewing your options.
         With detailed information on ${totalEntities} SNFs in ${city}, Seniorly is here to help you find the right facility.
         Simply browse the list below to find important information about therapies offered, proximity to nearby hospitals, amenities — and of course,
         estimated pricing.`}

          {priceRange.high > 0 && priceRange.low > 0 && (
            <div
              className='mt-xs'
              data-tp-id='gen-div-718569d4-2af1-435e-9ab2-0bb9fe682810'
            >
              {`Monthly skilled nursing costs in ${city} range from ${low} to
            ${high}, so its important to understand the unique offerings of each community.`}
            </div>
          )}
        </>
      );
    case CARE_TYPES_OBJECT.assistedLiving:
      return (
        <>
          {`If you're searching for assisted living communities in ${city},
        ${state}, you've come to the right place. With detailed information on
        ${totalEntities} assisted living facilities in ${city}, Seniorly is
        here to help you find the right one. Simply browse the list below to
        find important information about therapies offered, proximity to
        nearby hospitals, amenities — and of course, estimated pricing.`}

          {priceRange.high > 0 && priceRange.low > 0 && (
            <div
              className='mt-xs'
              data-tp-id='gen-div-7c312c5b-deb8-465b-a59d-d9fecf22cf66'
            >
              {`Monthly assisted living costs in ${city} range from ${low} to
            ${high}, so its important to understand each community's unique offerings.`}
            </div>
          )}
        </>
      );
    case CARE_TYPES_OBJECT.independentLiving:
      return (
        <>
          {`If you're searching for independent living communities in ${city}, ${state}, you've come to the right place.
        With detailed information on ${totalEntities} independent living communities in ${city}, Seniorly is here to help you find the right one.
        Simply browse the list below to find important information about activities offered, proximity to nearby hospitals, amenities — and of course,
        estimated pricing.`}

          {priceRange.high > 0 && priceRange.low > 0 && (
            <div
              className='mt-xs'
              data-tp-id='gen-div-8ccf9d1e-f340-4a29-a334-95ed8bb1dec7'
            >
              {`Monthly independent living costs in ${city} range from ${low} to
          ${high}, so its important to understand each community's unique offerings.`}
            </div>
          )}
        </>
      );
    case CARE_TYPES_OBJECT.boardAndCareHome:
      return (
        <>
          {`Start your search for board and care homes in ${city}, ${state} with an overview of your options.
        With detailed information on ${totalEntities} board and care homes in ${city}, Seniorly is here to help you find the right one.
        Simply browse the list below to find important information about therapies offered, proximity to nearby hospitals, amenities — and of course,
        estimated pricing. `}

          {priceRange.high > 0 && priceRange.low > 0 && (
            <div
              className='mt-xs'
              data-tp-id='gen-div-3155f804-ba25-43e9-98b5-c6cd67c009b2'
            >
              {`Monthly board and care homes costs in ${city} range from ${low} to
            ${high}, so its important to understand each community's unique offerings.`}
            </div>
          )}
        </>
      );
    case CARE_TYPES_OBJECT.memoryCare:
      return (
        <>
          {`Explore an overview of memory care communities in ${city}, ${state} to see all your options at once.
        With detailed information on ${totalEntities} memory care facilities in ${city}, Seniorly is here to help you find the right one.
        Simply browse the list below to find important information about therapies offered, proximity to nearby hospitals, amenities — and of course,
        estimated pricing. `}

          {priceRange.high > 0 && priceRange.low > 0 && (
            <div
              className='mt-xs'
              data-tp-id='gen-div-cd39e6fa-f30a-4a4a-8fda-1134ce06bb14'
            >
              {`Monthly memory care costs in ${city} range from ${low} to
              ${high}, so its important to understand each community's unique offerings.`}
            </div>
          )}
        </>
      );
    case CARE_TYPES_OBJECT.ccrc:
      return (
        <>
          {`If you're searching for continuing care retirement communities in ${city}, ${state}, you've come to the right place.
        With detailed information on ${totalEntities} CCRCs in ${city}, Seniorly is here to help you find the right one.
        Simply browse the list below to find important information about therapies offered, proximity to nearby hospitals, amenities — and of course,
        estimated pricing.`}

          {priceRange.high > 0 && priceRange.low > 0 && (
            <div
              className='mt-xs'
              data-tp-id='gen-div-696b4b5f-7d29-4ca5-b9e9-83ab670683a1'
            >
              {`Monthly continuing care retirement community costs in ${city} range from ${low} to
                ${high}, so its important to understand each community's unique offerings.`}
            </div>
          )}
        </>
      );
    case CARE_TYPES_OBJECT.activeAdult:
      return (
        <>
          {`If you're searching for 55+ communities in ${city}, ${state}, you've come to the right place. With detailed information on
        ${totalEntities} active adult facilities in ${city}, Seniorly is here to help you find the right one. Simply browse the list below to
        find important information about activities offered,
        proximity to nearby attractions, amenities — and of course, estimated pricing.`}

          {priceRange.high > 0 && priceRange.low > 0 && (
            <div
              className='mt-xs'
              data-tp-id='gen-div-d3f3018d-0c7a-4bbe-95c2-45b85f75c75c'
            >
              {`Monthly 55+ community costs in ${city} range from ${low} to
                  ${high}, so its important to understand each community's unique offerings.`}
            </div>
          )}
        </>
      );
    default:
      return <></>;
  }
};

export const constructCmsSearchUrl = (params) => {
  let forUrl = '/' + params.toc + '/' + params.state;
  if (params.city) {
    forUrl += '/' + params.city;
  }
  return forUrl;
};

export const getMoreFiltersOptions = (serviceType) => {
  const result = [];
  const serviceItems = amenitiesAndServiceItems[serviceType];
  if (serviceItems) {
    Object.keys(serviceItems).forEach((key) => {
      if (!serviceItems[key].excludeForFilter) {
        result.push({
          value: serviceItems[key].label,
          label: serviceItems[key].label,
        });
      }
    });
    return result;
  }
  return result;
};

export const getCommunityFromPin = (pin, communities) => {
  if (!communities?.length || !pin) {
    return;
  }
  return communities.find((community) => community.id === pin.id);
};
