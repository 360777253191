import { apiCall } from './actions';
import apiFetch from './apiFetch';
import applyUrlWithPlaceholders from './applyUrlWithPlaceholders';

const defaultConfigure = (options) => options;

export default function buildApi(endpoints, config = {}) {
  const {
    baseUrl,
    configureOptions = defaultConfigure,
    configureHeaders = defaultConfigure,
  } = config;

  // wrap config
  const request = (endpointBaseUrl) => (path, placeholders, requestOptions) => {
    const augmentedOptions = {
      ...requestOptions,
      headers: configureHeaders({
        'Content-Type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        ...requestOptions.headers,
      }),
    };

    return apiFetch(
      endpointBaseUrl,
      applyUrlWithPlaceholders(path, placeholders, requestOptions),
      configureOptions(augmentedOptions)
    );
  };

  return Object.keys(endpoints).reduce((acc, key) => {
    const {
      path,
      required,
      method,
      ssrIgnore,
      baseUrl: endpointBaseUrl = baseUrl,
      jsonApi = true,
      intercept,
    } = endpoints[key];

    const requiredPlaceholders = required || [];
    const placeholderRegexp = RegExp(':([^/$]+)', 'g');
    let match;

    // eslint-disable-next-line no-cond-assign
    while ((match = placeholderRegexp.exec(path))) {
      requiredPlaceholders.push(match[1]);
    }

    const normalizeArguments = (...args) => {
      const { placeholders = {}, options = {} } = method(...args);
      const missingPlaceholders = requiredPlaceholders.filter(
        (key) =>
          // eslint-disable-next-line no-prototype-builtins
          !placeholders.hasOwnProperty(key) || placeholders[key] == null
      );

      if (missingPlaceholders.length > 0) {
        throw new Error(
          `The "${key}" API call cannot be performed. The following params were not specified: ${missingPlaceholders.join(
            ', '
          )}`
        );
      }

      return { placeholders, options };
    };

    acc[key] = (...args) => {
      const { placeholders, options } = normalizeArguments(...args);
      return request(endpointBaseUrl)(path, placeholders, options);
    };

    acc[key].actionName = key;
    acc[key].method = method;
    /**
     * @param {({id: string}|{include: string, id: *}|{_sort: string, status: string}|{slug: any}|{forUrl: string}|{state: *}|{stringWithDataFromRelations_contains}|{providerSlug}|{pageNumber: *|number, _sort: string, slug: *}|{board: string}|{_limit: number, "mainTopic.slug": string, _sort: string}|{caretype: *, city: *, state: *, published: boolean}|{caretype: *, state: ({length}|*), published: boolean})[]} args
     */
    acc[key].asAction = (...args) => {
      const { placeholders, options } = normalizeArguments(...args);
      return apiCall(request(endpointBaseUrl), {
        placeholders,
        path,
        options,
        actionName: key,
        isJsonApi: jsonApi,
        intercept,
      });
    };
    acc[key].ssrIgnore = ssrIgnore;
    acc[key].isJsonApi = jsonApi;

    return acc;
  }, {});
}
