const parseName = (name) => {
  if (!name) return {};

  let parts = name
    .trim()
    .replace(/\b\s+(,\s+)\b/, '$1')
    .replace(/\b,\b/, ', ');

  parts = (parts.match(/[^\s"]+|"[^"]+"/g) || parts.split(/\s+/)).map((n) =>
    n.match(/^".*"$/) ? n.slice(1, -1) : n
  );

  const attrs = {};

  if (!parts.length) {
    return attrs;
  }

  attrs.fullName = parts.join(' ');

  if (parts.length === 1) {
    attrs.firstName = parts[0];
  }

  if (parts.length > 1) {
    attrs.firstName = parts[0];
    attrs.lastName = parts.slice(1, name.length).join(' ');
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const [k, v] of Object.entries(attrs)) {
    attrs[k] = v.trim();
  }
  return attrs;
};

export default parseName;
