import RetentionPopupWrapper from '@react/services/retentionPopup/RetentionPopupWrapper';
import { node } from 'proptypes';
import React, { useContext, useState } from 'react';

export const RetentionPopupContext = React.createContext({
  disableRetentionPopup: false,
  setDisableRetentionPopup: () => {},
});

const RetentionPopupProvider = (props) => {
  // Exit intent is disabled site wide as of now, will be enabled via strapi profile page versions
  // Hence setting default value as true
  const [disableRetentionPopup, setDisableRetentionPopup] = useState(true);

  const contextValue = {
    disableRetentionPopup,
    setDisableRetentionPopup,
  };

  return (
    <>
      <RetentionPopupContext.Provider value={contextValue}>
        {!disableRetentionPopup && <RetentionPopupWrapper />}
        {props.children}
      </RetentionPopupContext.Provider>
    </>
  );
};

export const useRetentionPopup = () => {
  return useContext(RetentionPopupContext);
};

RetentionPopupProvider.propTypes = {
  children: node,
};

export default RetentionPopupProvider;
