import cx, { Argument } from 'classnames';

/** Merge classes with tailwind-merge with clsx full feature */
export default function clsxm(...classes: Argument[]) {
  return cx(...classes);
}

export { cx };

const breakpoints = ['', 'md', 'lg'];

export const sx = (...classes: string[]) =>
  cx(
    ...classes.reduce((acc: string[], clx: string, i: number) => {
      const chunks = clx
        .split(/\s+/g)
        .map((x) => `${i ? `${breakpoints[i]}:` : ''}${x}`);
      acc.push(...chunks);
      return acc;
    }, [])
  );
