/** @jsxImportSource react */
import cx from 'classnames';
import { arrayOf, bool, node, oneOfType } from 'proptypes';

const EntityInfoDescription = ({ inverted, children, className, ...props }) => {
  return (
    <span
      className={cx(
        'overflow-hidden whitespace-nowrap',
        'font-b-s flex w-full text-ellipsis',
        inverted ? 'text-white' : 'text-slate-base',
        'mb-xxs',
        className
      )}
      {...props}
      data-tp-id='gen-span-39e16b37-4560-4f4e-9515-e0a16c27c2b9'
    >
      {children}
    </span>
  );
};

EntityInfoDescription.propTypes = {
  inverted: bool,
  children: oneOfType([arrayOf(node), node]).isRequired,
};

export default EntityInfoDescription;
