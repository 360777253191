import {
  ASSESSMENT_WIZARD_COMPLETED,
  ASSESSMENT_WIZARD_MATCHED_AGENT
} from '@sly/core/constants/assessment';
import { isBrowser } from 'sly/config';

export const completedAssessment =
  isBrowser && !!localStorage.getItem(ASSESSMENT_WIZARD_COMPLETED);
export const agentId = isBrowser
  ? localStorage.getItem(ASSESSMENT_WIZARD_MATCHED_AGENT) || ''
  : '';

export const getIsAssessmentComplete = () =>
  isBrowser && !!localStorage.getItem(ASSESSMENT_WIZARD_COMPLETED);

const contactInfoFields = ['firstName', 'lastName', 'email', 'phone'];

export const getHasContactInfo = initialValues => {
  return Object.entries(initialValues).reduce((_, [key, value]) => {
    return contactInfoFields.includes(key) && !!value;
  }, false);
};
