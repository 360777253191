/** @jsxImportSource react */

import { Dropdown } from '@sly/icons/react';
import cx from 'classnames';
import React from 'react';

import Button from '../Button';

const DropdownButton = ({ children, onClick, isOpen, className, ...props }) => (
  <Button
    onClick={onClick}
    variant='custom'
    className={cx('flex', className)}
    data-tp-id='gen-Button-b8ca24d1-9f18-4c7b-a1fc-d2203e013f57'
    {...props}
  >
    <span data-tp-id='gen-span-c0a40179-8242-4dff-a3cd-f0c27f7a3cd3'>
      {children}
    </span>
    <Dropdown
      color='viridian.base'
      className={cx('my-auto', !isOpen && 'rotate-180')}
    />
  </Button>
);

export default DropdownButton;
