/** @jsxImportSource react */
import {
  invalidateRequests,
  purgeFromRelationships as purgeFromRelationshipsAction,
} from '@react/services/api/actions';
import { useApi } from '@react/services/api/context';
import { hasSession } from '@react/services/api/helpers';
import {
  defaultRequest,
  getRelationship as selectRelationship,
} from '@react/services/api/selectors';
import hoistNonReactStatic from 'hoist-non-react-statics';
import { object } from 'proptypes';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isServer } from 'sly/config';

const defaultDispatcher = (call) => call();

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export function usePrefetch(apiCall, ...args) {
  const {
    skipApiCalls = false,
    apiClient: { store, api },
  } = useApi() || {};

  const { placeholders = {}, options = {} } = api[apiCall].method(...args);
  const argsKey = JSON.stringify(placeholders);

  const fetch = useCallback(async () => {
    try {
      return await store.dispatch(api[apiCall].asAction(placeholders, options));
    } catch (e) {
      console.log(e);
    }
  }, [apiCall, argsKey]);

  const invalidate = useCallback(
    () => store.dispatch(invalidateRequests(apiCall, placeholders)),
    [apiCall, argsKey]
  );

  const purgeFromRelationships = useCallback(
    (relationship) =>
      store.dispatch(
        purgeFromRelationshipsAction(apiCall, placeholders, relationship)
      ),
    [apiCall, argsKey]
  );

  const getCurrentRequestInfo = useCallback(
    () => store.getRequestInfo(apiCall, argsKey),
    [apiCall, argsKey]
  );

  const [request, setRequest] = useState(
    getCurrentRequestInfo() || { ...defaultRequest }
  );

  const setRequestCallback = useCallback(
    (newRequest) => {
      if (!options.keepLastRequest || newRequest.hasFinished) {
        setRequest(newRequest);
        return;
      }
      setRequest({
        ...newRequest,
        response: request.response,
        result: request.result,
        meta: request.meta,
        normalized: request.normalized,
      });
    },
    [setRequest, request, options.keepLastRequest]
  );

  const getRelationship = useCallback(
    (entity, relationship) =>
      selectRelationship(request.entities, entity, relationship),
    [request]
  );

  const shouldBail =
    options.shouldBail || (options.sessionOnly && !hasSession());

  useEffect(() => {
    store.on(apiCall, argsKey, setRequestCallback);
    const currentRequest = getCurrentRequestInfo() || defaultRequest;
    if (!shouldBail && currentRequest !== request) {
      fetch();
    }
    return () => store.off(apiCall, argsKey, setRequestCallback);
  }, [apiCall, argsKey, shouldBail]);

  const prefetch = useMemo(
    () => ({
      requestInfo: request,
      fetch,
      invalidate,
      getRelationship,
      purgeFromRelationships,
      getCurrentRequestInfo,
    }),
    [request, apiCall, argsKey]
  );

  // initial server fetch, server does not run the effects
  if (isServer) {
    const { hasStarted, isInvalid } = request;
    const shouldSkip = skipApiCalls || api[apiCall].ssrIgnore;
    if (isInvalid || (!shouldBail && !shouldSkip && !hasStarted)) {
      fetch();
    }
  }

  return prefetch;
}

function prefetch(propName, apiCall, dispatcher = defaultDispatcher) {
  return (InnerComponent) => {
    const Wrapper = ({ status = {}, ...props }) => {
      const {
        requestInfo: request,
        fetch,
        invalidate,
        getRelationship,
        purgeFromRelationships,
      } = usePrefetch(apiCall, ...dispatcher((...args) => args, props));

      const innerProps = {
        ...props,
        [propName]: request.normalized,
        status: {
          ...status,
          [propName]: {
            ...request,
            refetch: fetch,
            invalidate,
            getRelationship,
            purgeFromRelationships,
          },
        },
      };
      return <InnerComponent {...innerProps} />;
    };

    hoistNonReactStatic(Wrapper, InnerComponent);

    Wrapper.displayName = `prefetch(${getDisplayName(
      InnerComponent
    )}, ${propName})`;
    Wrapper.WrappedComponent = InnerComponent;
    Wrapper.propTypes = {
      status: object,
    };

    return Wrapper;
  };
}

export default prefetch;
