export const RETENTION_POPUP_MOUSEOUT_TIMEOUT = 25000;
export const RETENTION_POPUP_FOCUS_TIMEOUT = 10000;
// export const RETENTION_POPUP_EBOOK_TIMEOUT = 120000;
export const RETENTION_POPUP_EBOOK_TIMEOUT = 10000;

export const RETENTION_POPUP_IDLE_TIMEOUT = 10;

export const RETENTION_POPUP_MODAL_SHOWN = 'modal-shown';
export const RETENTION_POPUP_EXIT_INTENT = 'exit-intent';
export const RETENTION_POPUP_FOCUS = 'stay-intent';
export const RETENTION_POPUP_IDLE = 'idle';

// export const RETENTION_POPUP_DISABLED_PAGES = [/^\/custom-pricing/, /^\/dashboard/, /^\/$/];
export const RETENTION_POPUP_DISABLED_PAGES = [
  /^\/custom-pricing/,
  /^\/dashboard/
];

export const DEFAULT_EMAIL_VALUE = 'email_value';
