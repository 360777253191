/** @jsxImportSource react */
// import Head from 'next/head';
import clsxm from '@common/lib/clsxm';
import { Close } from '@sly/icons/react';
import { any, bool, element, func, node } from 'proptypes';
import React, {
  Component,
  createContext,
  createRef,
  forwardRef,
  useContext,
} from 'react';
import { createPortal } from 'react-dom';
import ScrollLock from 'react-scrolllock';

const ActiveModalContext = createContext(false);

export const HeaderWithClose = forwardRef(
  ({ children, onClose, noBorder, className, Icon, avatar, ...props }, ref) => (
    <div
      className={clsxm(
        className,
        'font-t-m flex h-[76px] flex-shrink-0 items-center justify-between px-l py-0',
        noBorder ? 'border-none' : 'border-b border-slate-lighter-90'
      )}
      ref={ref}
      {...props}
      data-tp-id='gen-div-2d3cdd5d-8e0c-4c38-adfd-c1475dc398fd'
    >
      <div
        className={`flex items-center ${!!Icon && 'gap-s'}`}
        data-tp-id='gen-div-7a9ba772-00b2-44d9-aa27-ea4472e150ee'
      >
        {!!avatar && avatar}
        {!!Icon && !avatar && (
          <Icon
            className='rounded-full bg-viridian-lighter-90 p-[6px]'
            size='l'
            color='text-viridian-base'
            data-tp-id='gen-Icon-481ece43-73ec-4c81-aebf-40dad835a07a'
          />
        )}

        <h3
          className='font-t-m'
          data-tp-id='gen-h3-e2d58b7d-42ea-44ad-b49f-b4937f4383ec'
        >
          {children}
        </h3>
      </div>

      <Close
        icon='close'
        className='flex-grow-0 cursor-pointer bg-transparent p-0'
        palette='slate'
        size='m'
        onClick={onClose}
      />
    </div>
  )
);

HeaderWithClose.propTypes = {
  children: element,
  Icon: node,
  onClose: func,
  noBorder: bool,
};

export const ModalBody = forwardRef((props, ref) => {
  const isActive = useContext(ActiveModalContext);
  const { className } = props;
  return (
    <ScrollLock isActive={isActive}>
      <div
        className={clsxm('p-xl', className, 'overflow-y-auto')}
        ref={ref}
        {...props}
        data-tp-id='gen-div-ae2def05-8775-480b-8e73-657a7443634d'
      />
    </ScrollLock>
  );
});

export const ModalActions = forwardRef((props, ref) => (
  <div
    className={clsxm(
      props?.className,
      'flex flex-shrink-0 border-t py-l px-xl'
    )}
    ref={ref}
    {...props}
    data-tp-id='gen-div-50befc3a-0982-463c-bea6-02caa7b494ad'
  />
));

ModalActions.displayName = 'ModalActions';

const PORTAL_ELEMENT_CLASS = 'modal-portal';

// TODO: @fonz todo a proper modal from this hack; animate entry and leave;
export default class NewModal extends Component {
  static typeHydrationId = 'NewModal';
  static el = null;
  static instanceNumber = 0;

  overlayRef = createRef();
  modalRef = createRef();
  state = { mounted: false };

  mouseDownTarget = createRef();

  onMouseDown = (event) => {
    this.mouseDownTarget.current = event.target;
  };

  componentDidMount() {
    NewModal.instanceNumber++;
    if (!NewModal.el) {
      NewModal.el = document.createElement('div');
      NewModal.el.setAttribute('class', PORTAL_ELEMENT_CLASS);
      document.body.appendChild(NewModal.el);
    }
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({ mounted: true });
  }

  componentWillUnmount() {
    this;

    NewModal.instanceNumber--;

    if (NewModal.instanceNumber === 0) {
      document.body.removeChild(NewModal.el);
      NewModal.el = null;
    }
  }

  onClick = (e) => {
    e.stopPropagation();
    const { onClose } = this.props;

    if (this.mouseDownTarget.current !== e.target) return;

    if (e.target === this.overlayRef.current) {
      if (onClose) {
        onClose(e);
      }
    }
  };

  render() {
    const {
      children,
      isOpen,
      transparent,
      className,
      wrapperClassName,
      ...props
    } = this.props;

    return (
      this.state.mounted &&
      createPortal(
        <div
          className={clsxm(
            wrapperClassName,
            isOpen ? ' flex' : 'hidden',
            'fixed inset-0 z-[10001] items-center justify-center overflow-auto',
            transparent ? 'bg-transparent' : 'bg-slate-base bg-opacity-80'
          )}
          ref={this.overlayRef}
          onClick={this.onClick}
          onMouseDown={this.onMouseDown}
          data-tp-id='gen-div-a26e7610-2f53-41c5-89b2-611c9fd53d7b'
        >
          <div
            className={clsxm(
              isOpen ? 'flex' : 'none',
              'h-full w-full max-w-[31.500rem] flex-col overflow-y-auto rounded-md bg-white md:h-auto md:max-h-[calc(100vh-48px)]',
              className
            )}
            ref={this.modalRef}
            {...props}
            data-tp-id='gen-div-bb91fb6c-37de-475f-a832-d8bbde557b0e'
          >
            <ActiveModalContext.Provider value={isOpen}>
              {children}
              {isOpen && (
                <style>{`
                  body {
                    overflow: hidden;
                  }
                  .roko-instabot-widget-button {
                    z-index: 0 !important;
                  }
                  .roko-instabot-widget {
                    z-index: 0 !important;
                    display: none !important;
                  }
                  .rw-widget-container {
                    z-index: 0 !important;
                  }
                `}</style>
              )}
              {/* {isOpen && (
                // <Head>
              //   <style>
              //     {`
              //   body{
              //     overflow: hidden
              //   }
              // `}
              //   </style>
                // </Head>
              )} */}
            </ActiveModalContext.Provider>
          </div>
        </div>,
        NewModal.el
      )
    );
  }
}

NewModal.propTypes = {
  children: any,
  onClose: func,
  isOpen: bool,
  transparent: bool,
};

NewModal.defaultProps = {
  isOpen: true,
};
