import React, { forwardRef, Ref } from 'react';
import { SimpleIconProps } from '../icon.types';
import Icon from './Icon';

const svg = `<svg viewBox="0 0 24 24" fill="currentColor">
  <path d="M19.9619 21.2033C17.8543 21.2033 15.772 20.7442 13.7149 19.8259C11.6579 18.9076 9.78405 17.6041 8.09347 15.9155C6.40289 14.2269 5.09844 12.3534 4.18014 10.2951C3.26186 8.23671 2.80272 6.15304 2.80272 4.04405C2.80272 3.68769 2.92094 3.39071 3.15737 3.15313C3.3938 2.91554 3.68935 2.79675 4.04402 2.79675H8.08804C8.41304 2.79675 8.69112 2.89286 8.92227 3.08508C9.15344 3.27729 9.29764 3.52359 9.35489 3.82395L9.99892 7.1984C10.0482 7.52485 10.0408 7.80166 9.97689 8.02883C9.91294 8.25601 9.79039 8.4542 9.60924 8.6234L7.14837 11.0256C7.46575 11.5984 7.84166 12.1564 8.27609 12.6995C8.71051 13.2426 9.19474 13.7729 9.72879 14.2903C10.2255 14.787 10.7373 15.2422 11.2641 15.656C11.7909 16.0698 12.3431 16.444 12.9206 16.7788L15.3125 14.4049C15.5063 14.2151 15.744 14.0786 16.0255 13.9957C16.3071 13.9127 16.5944 13.8959 16.8875 13.9451L20.1761 14.6272C20.4931 14.7178 20.7436 14.8715 20.9274 15.0883C21.1113 15.3052 21.2033 15.5638 21.2033 15.8642V19.956C21.2033 20.3124 21.0843 20.6093 20.8465 20.8469C20.6086 21.0845 20.3138 21.2033 19.9619 21.2033ZM6.06087 8.90438L7.70489 7.30818L7.29184 5.07178H5.10869C5.18406 5.72721 5.29367 6.37419 5.43752 7.01273C5.58137 7.65126 5.78915 8.28181 6.06087 8.90438ZM15.0348 17.8843C15.6609 18.1556 16.3005 18.3727 16.9535 18.5354C17.6066 18.698 18.2648 18.8084 18.9282 18.8663V16.6962L16.6978 16.2332L15.0348 17.8843Z"/>
</svg>`;

const Phone = forwardRef(
  (props: SimpleIconProps, ref: Ref<SVGSVGElement> | undefined) => (
    <Icon ref={ref} name='phone' svg={svg} {...props} />
  )
);

Phone.displayName = 'PhoneIcon';

export default Phone;
