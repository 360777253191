import { usePrefetch } from '@react/services/api/prefetch';
import { useCallback, useMemo } from 'react';

const useUser = (force = false) => {
  const uuidAux = usePrefetch(
    'getUuidAux',
    { id: 'me' },
    { sessionOnly: !force }
  );
  const user = usePrefetch('getUser', { id: 'me' }, { sessionOnly: !force });
  const getCurrentUser = useCallback(
    () => user.getCurrentRequestInfo().normalized,
    []
  );

  return useMemo(() => {
    return {
      uuidAux: uuidAux.requestInfo.normalized,
      rawUuidAux: uuidAux.requestInfo.result,
      fetchUuidAux: uuidAux.fetch,
      invalidateUuidAux: uuidAux.invalidate,

      user: user.requestInfo.normalized,
      rawUser: user.requestInfo.result,
      fetchUser: user.fetch,
      invalidateUser: user.invalidate,
      getCurrentUser,

      info: {
        uuidAux: uuidAux.requestInfo,
        user: user.requestInfo,
      },
    };
  }, [uuidAux, user]);
};

export default useUser;
