/** @jsxImportSource react */
import { events } from '@common/events/events';
import { eventNames } from '@sly/core/constants/events';
import { node } from 'proptypes';
import React, { useContext } from 'react';

export const SegmentContext = React.createContext({
  segmentIds: [],
  setSegmentIds: () => {},
  events: {
    identify: () => {},
    // TODO improve typing
    track: (_eventName = '', _eventObject = {}) => {},
    page: (_eventName = '', _eventObject = {}) => {},
  },
  actionEventHandler: () => {},
  sendCommunityListViewedEvent: () => {},
});

const SegmentContextProvider = ({ segmentIds = [], children }) => {
  const setSegmentIds = (newSegmentIds) => {
    segmentIds = newSegmentIds;
  };

  const track = (...args) => {
    if (args?.[1] && segmentIds?.length > 0) {
      args[1].segmentIds = segmentIds;
    }
    events.track(...args);
  };

  const identify = (...args) => {
    if (args?.[1] && segmentIds?.length > 0) {
      args[1].segmentIds = segmentIds;
    }
    events.identify(...args);
  };

  const page = (...args) => {
    if (args?.[1] && segmentIds?.length > 0) {
      args[1].segmentIds = segmentIds;
    }
    events.page(...args);
  };

  const actionEventHandler = (...args) => {
    return () => {
      track(...args);
    };
  };

  const sendCommunityListViewedEvent = (
    entities,
    city,
    state,
    location,
    care
  ) => {
    const communities = entities.map((entity, i) => {
      return {
        position: i,
        communitySlug: entity.id,
        city,
        state,
        care: care || entity?.mainService,
      };
    });

    track(eventNames.CommunityListViewed, { communities, location });
  };

  const contextValue = {
    segmentIds,
    setSegmentIds,
    events: {
      identify,
      track,
      page,
    },
    actionEventHandler,
    sendCommunityListViewedEvent,
  };

  return (
    <SegmentContext.Provider value={contextValue}>
      {children}
    </SegmentContext.Provider>
  );
};

export const useSegmentContext = () => {
  return useContext(SegmentContext);
};

SegmentContextProvider.propTypes = {
  children: node,
};

export default SegmentContextProvider;
