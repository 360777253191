import React, { forwardRef, Ref } from 'react';
import { SimpleIconProps } from '../icon.types';
import Icon from './Icon';

const svg = `<svg viewBox="0 0 24 24" fill="currentColor">
  <path d="M2.79075 21.1016C2.57954 21.1016 2.38879 21.0492 2.2185 20.9444C2.04824 20.8396 1.9158 20.7014 1.8212 20.5299C1.7259 20.36 1.67409 20.1754 1.66575 19.9761C1.65742 19.7768 1.70924 19.5822 1.8212 19.3924L11.0054 3.50002C11.1174 3.30943 11.2637 3.16749 11.4444 3.07419C11.625 2.98091 11.8104 2.93427 12.0006 2.93427C12.1908 2.93427 12.376 2.98091 12.5563 3.07419C12.7365 3.16749 12.8826 3.30943 12.9946 3.50002L22.1788 19.3924C22.2908 19.5822 22.3426 19.7768 22.3343 19.9761C22.3259 20.1754 22.2741 20.36 22.1788 20.5299C22.0835 20.6998 21.9509 20.8376 21.781 20.9432C21.611 21.0488 21.4205 21.1016 21.2093 21.1016H2.79075ZM4.59945 18.9163H19.4006L12 6.16741L4.59945 18.9163ZM12 17.9821C12.2873 17.9821 12.5308 17.8823 12.7304 17.6826C12.9301 17.483 13.0299 17.2395 13.0299 16.9522C13.0299 16.6649 12.9301 16.4224 12.7304 16.2247C12.5308 16.0271 12.2873 15.9283 12 15.9283C11.7127 15.9283 11.4692 16.0271 11.2696 16.2247C11.0699 16.4224 10.9701 16.6649 10.9701 16.9522C10.9701 17.2395 11.0699 17.483 11.2696 17.6826C11.4692 17.8823 11.7127 17.9821 12 17.9821ZM12 15C12.2833 15 12.5208 14.9042 12.7125 14.7125C12.9042 14.5208 13 14.2833 13 14V11.0658C13 10.7824 12.9042 10.5449 12.7125 10.3533C12.5208 10.1616 12.2833 10.0658 12 10.0658C11.7167 10.0658 11.4792 10.1616 11.2875 10.3533C11.0958 10.5449 11 10.7824 11 11.0658V14C11 14.2833 11.0958 14.5208 11.2875 14.7125C11.4792 14.9042 11.7167 15 12 15Z"/>
</svg>`;

const Warning = forwardRef(
  (props: SimpleIconProps, ref: Ref<SVGSVGElement> | undefined) => (
    <Icon ref={ref} name='warning' svg={svg} {...props} />
  )
);

Warning.displayName = 'WarningIcon';

export default Warning;
