import React, { forwardRef, Ref } from 'react';
import { SimpleIconProps } from '../icon.types';
import Icon from './Icon';

const svg = `<svg viewBox="0 0 24 24" fill="currentColor">
  <path d="M8.96359 17.6636L12 15.8353L15.0364 17.6886L14.2353 14.2261L16.9147 11.9098L13.3962 11.5967L12 8.32227L10.6038 11.5777L7.08531 11.8848L9.76466 14.2201L8.96359 17.6636ZM12 18.5022L7.83206 21.0141C7.62083 21.1467 7.40126 21.2037 7.17336 21.185C6.94546 21.1664 6.74654 21.0908 6.57661 20.9581C6.40668 20.8255 6.27507 20.6588 6.18179 20.4579C6.08849 20.257 6.07082 20.0342 6.12879 19.7897L7.23476 15.0467L3.54184 11.8538C3.35125 11.6839 3.23214 11.4901 3.18449 11.2725C3.13684 11.055 3.14834 10.8426 3.21901 10.6353C3.28966 10.4281 3.4046 10.2561 3.56384 10.1195C3.72309 9.98296 3.93332 9.898 4.19454 9.86467L9.06249 9.43967L10.9494 4.96574C11.0447 4.73386 11.1908 4.56194 11.3878 4.44999C11.5847 4.33803 11.7888 4.28204 12 4.28204C12.2112 4.28204 12.4153 4.33803 12.6122 4.44999C12.8091 4.56194 12.9552 4.73386 13.0505 4.96574L14.9375 9.43967L19.8054 9.86467C20.0667 9.898 20.2769 9.98296 20.4361 10.1195C20.5954 10.2561 20.7103 10.4281 20.781 10.6353C20.8516 10.8426 20.8631 11.055 20.8155 11.2725C20.7678 11.4901 20.6487 11.6839 20.4581 11.8538L16.7652 15.0467L17.8712 19.7897C17.9292 20.0342 17.9115 20.257 17.8182 20.4579C17.7249 20.6588 17.5933 20.8255 17.4234 20.9581C17.2534 21.0908 17.0545 21.1664 16.8266 21.185C16.5987 21.2037 16.3791 21.1467 16.1679 21.0141L12 18.5022Z"/>
  <path style="fill: var(--active-color)" d="M12 18.5022L7.83206 21.0141C7.62083 21.1467 7.40126 21.2037 7.17336 21.185C6.94546 21.1664 6.74654 21.0908 6.57661 20.9581C6.40668 20.8255 6.27507 20.6588 6.18179 20.4579C6.08849 20.257 6.07082 20.0342 6.12879 19.7897L7.23476 15.0467L3.54184 11.8538C3.35125 11.6839 3.23214 11.4901 3.18449 11.2725C3.13684 11.055 3.14834 10.8426 3.21901 10.6353C3.28966 10.4281 3.4046 10.2561 3.56384 10.1195C3.72309 9.98296 3.93332 9.898 4.19454 9.86467L9.06249 9.43967L10.9494 4.96574C11.0447 4.73386 11.1908 4.56194 11.3878 4.44999C11.5847 4.33803 11.7888 4.28204 12 4.28204C12.2112 4.28204 12.4153 4.33803 12.6122 4.44999C12.8091 4.56194 12.9552 4.73386 13.0505 4.96574L14.9375 9.43967L19.8054 9.86467C20.0667 9.898 20.2769 9.98296 20.4361 10.1195C20.5954 10.2561 20.7103 10.4281 20.781 10.6353C20.8516 10.8426 20.8631 11.055 20.8155 11.2725C20.7678 11.4901 20.6487 11.6839 20.4581 11.8538L16.7652 15.0467L17.8712 19.7897C17.9292 20.0342 17.9115 20.257 17.8182 20.4579C17.7249 20.6588 17.5933 20.8255 17.4234 20.9581C17.2534 21.0908 17.0545 21.1664 16.8266 21.185C16.5987 21.2037 16.3791 21.1467 16.1679 21.0141L12 18.5022Z"/>
</svg>`;

const Star = forwardRef(
  (props: SimpleIconProps, ref: Ref<SVGSVGElement> | undefined) => (
    <Icon ref={ref} name='star' svg={svg} {...props} />
  )
);

Star.displayName = 'StarIcon';

export default Star;
