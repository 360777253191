/** @jsxImportSource react */
import {
  getPrivacyLink,
  getTosLink,
} from '@react/shared/components/TosAndPrivacy';
import React from 'react';

export function TocComponent({ submitText }) {
  return (
    <div className='font-b-s text-center text-slate-lighter-40'>
      By clicking “{submitText}”, you consent to receiving phone calls and text
      messages from us and our local advisors.
    </div>
  );
}

export function TocEmailComponent() {
  return (
    <div className='font-b-s text-center text-slate-lighter-40'>
      By submitting your email, you agree to Seniorly&apos;s {getTosLink(true)}{' '}
      and {getPrivacyLink(true)}.
    </div>
  );
}
