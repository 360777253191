/** @jsxImportSource react */
import { getImageProps } from '@common/helpers/images';
import clsxm from '@common/lib/clsxm';

import { QwikImageProps } from './types';

const BaseImage = ({
  path,
  aspectRatio,
  crop = true,
  height,
  sources,
  alt,
  loading = 'lazy',
  className,
  wrapperClassName,
  src,
  sizes = '(max-width: 1079px) 100vw, 680px',
  id,
}: QwikImageProps) => {
  const { aspectRatioString, imageProps, imageAlt, imageSizes } = getImageProps(
    {
      aspectRatio,
      sources,
      height,
      path,
      crop,
      alt,
      sizes,
      src,
    }
  );

  const picture = (
    <img
      loading={loading}
      alt={imageAlt}
      className={className}
      {...imageProps}
      sizes={imageSizes}
      id={id}
    />
  );

  if (!aspectRatio) {
    return picture;
  }

  return (
    <div
      style={{
        paddingTop: aspectRatioString,
      }}
      className={clsxm('responsive-image-wrapper', wrapperClassName)}
    >
      {picture}
    </div>
  );
};

export default BaseImage;
