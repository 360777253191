import { cleanError } from '@react/services/logging';
import { renderToString } from 'react-dom/server';

export default async function renderAndPrefetch(element, apiContext, url = '') {
  let result;

  let apiPromises;
  // we repeat until no component has requested new promises
  do {
    result = renderToString(element);
    // we don't mind the result, just completion
    apiPromises = Object.entries(apiContext.apiClient.store.promises).map(
      ([key, promise]) =>
        promise.catch((e) => {
          const errorObj = {
            ts: new Date().toISOString(),
            status: e.status,
            url,
            apiCall: key,
            apiPath: promise.path,
            message: 'Unexpected error calling an Api method',
            error: cleanError(e),
          };
          console.error(errorObj);
        })
    );
    // eslint-disable-next-line no-await-in-loop
    await Promise.all(apiPromises);
  } while (apiPromises.length);

  return result;
}
