import React, { forwardRef, Ref } from 'react';
import { SimpleIconProps } from '../icon.types';
import Icon from './Icon';

const svg = `<svg viewBox="0 0 24 24" fill="currentColor">
  <path d="M12 19.1886C14.0054 17.3538 15.4948 15.6988 16.4682 14.2237C17.4416 12.7485 17.9283 11.3993 17.9283 10.1761C17.9283 8.38747 17.356 6.92292 16.2115 5.78245C15.0669 4.642 13.6631 4.07178 11.9998 4.07178C10.3365 4.07178 8.93271 4.642 7.78833 5.78245C6.64394 6.92292 6.07175 8.38747 6.07175 10.1761C6.07175 11.3993 6.55843 12.7475 7.5318 14.2207C8.50517 15.6939 9.99457 17.3498 12 19.1886ZM11.9986 21.4924C11.7502 21.4924 11.5023 21.4488 11.2546 21.3615C11.007 21.2741 10.7859 21.1432 10.5913 20.9685C9.48405 19.9566 8.50579 18.9736 7.65653 18.0196C6.80724 17.0656 6.09845 16.1388 5.53015 15.2392C4.96187 14.3395 4.53089 13.4682 4.23723 12.6253C3.94356 11.7824 3.79673 10.966 3.79673 10.1761C3.79673 7.62144 4.62133 5.58621 6.27053 4.07043C7.91973 2.55464 9.82955 1.79675 12 1.79675C14.1705 1.79675 16.0803 2.55464 17.7295 4.07043C19.3787 5.58621 20.2033 7.62144 20.2033 10.1761C20.2033 10.966 20.0564 11.7824 19.7628 12.6253C19.4691 13.4682 19.0381 14.3395 18.4699 15.2392C17.9016 16.1388 17.1928 17.0656 16.3435 18.0196C15.4942 18.9736 14.516 19.9566 13.4087 20.9685C13.2136 21.1432 12.9919 21.2741 12.7436 21.3615C12.4952 21.4488 12.2469 21.4924 11.9986 21.4924ZM12 12.0598C12.5699 12.0598 13.0557 11.859 13.4574 11.4574C13.859 11.0557 14.0598 10.57 14.0598 10C14.0598 9.43009 13.859 8.94431 13.4574 8.54268C13.0557 8.14106 12.5699 7.94025 12 7.94025C11.4301 7.94025 10.9443 8.14106 10.5427 8.54268C10.141 8.94431 9.94023 9.43009 9.94023 10C9.94023 10.57 10.141 11.0557 10.5427 11.4574C10.9443 11.859 11.4301 12.0598 12 12.0598Z"/>
</svg>`;

const Location = forwardRef(
  (props: SimpleIconProps, ref: Ref<SVGSVGElement> | undefined) => (
    <Icon ref={ref} name='location' svg={svg} {...props} />
  )
);

Location.displayName = 'LocationIcon';

export default Location;
