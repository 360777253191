/** @jsxImportSource react */

import useIsomorphicLayoutEffect from '@react/shared/helpers/useIsomorphicLayoutEffect';
import debounce from 'lodash/debounce';
import { node } from 'proptypes';
import { createContext, useContext, useMemo, useState } from 'react';

export const MOBILE = 'mobile';
export const TABLET = 'tablet';
export const LAPTOP = 'laptop';

export const PORTRAIT = 'portrait';
export const LANDSCAPE = 'landscape';

const breakpoints = {
  mobile: 416,
  tablet: 728,
  laptop: 1080,
  desktop: 1280,
};

export class Breakpoint {
  constructor(currentWidth, currentHeight) {
    this.currentWidth = currentWidth;
    this.currentHeight = currentHeight;
  }

  atLeast(breakpoint) {
    if (!breakpoints[breakpoint] && breakpoint !== MOBILE) {
      throw new Error(`no breakpoint ${breakpoint}`);
    }

    const check = breakpoint === MOBILE ? 0 : breakpoints[breakpoint];
    return this.currentWidth >= check;
  }

  atLeastTablet = () => this.atLeast(TABLET);
  atLeastLaptop = () => this.atLeast(LAPTOP);

  upTo(breakpoint) {
    if (!breakpoints[breakpoint] && breakpoint !== MOBILE) {
      throw new Error(`no breakpoint ${breakpoint}`);
    }

    if (breakpoint === MOBILE) {
      return false;
    }

    return this.currentWidth < breakpoints[breakpoint];
  }

  upToTablet = () => this.upTo(TABLET);
  upToLaptop = () => this.upTo(LAPTOP);

  is(breakpoint, orientation = PORTRAIT) {
    if (!breakpoints[breakpoint] && breakpoint !== MOBILE) {
      throw new Error(`no breakpoint ${breakpoint}`);
    }
    if (breakpoint === LAPTOP && orientation !== PORTRAIT) {
      throw new Error('Laptop can only be PORTRAIT');
    }

    let test = this.currentWidth;
    if ([MOBILE, TABLET].includes(breakpoint)) {
      if (orientation === PORTRAIT) {
        if (this.currentWidth > this.currentHeight) {
          return false;
        }
      } else {
        if (this.currentHeight > this.currentWidth) {
          return false;
        }
        test = this.currentHeight;
      }
    }

    switch (breakpoint) {
      case MOBILE:
        return test < breakpoints[TABLET];
      case TABLET:
        return test >= breakpoints[TABLET] && test < breakpoints[LAPTOP];
      case LAPTOP:
        return test >= breakpoints[LAPTOP];
      default:
        return false;
    }
  }

  width = () => this.currentWidth;
  height = () => this.currentHeight;

  isMobile = (orientation) => this.is(MOBILE, orientation);
  isTablet = (orientation) => this.is(TABLET, orientation);
  isLaptop = () => this.is(LAPTOP, LANDSCAPE); // LANDSCAPE only
}

const BreakpointContext = createContext(null);

export const BreakpointProvider = ({ children }) => {
  const [breakpoint, setBreakpoint] = useState();

  const debouncedNewBreakpoint = useMemo(
    () =>
      debounce(() => {
        setBreakpoint(new Breakpoint(window.innerWidth, window.innerHeight));
      }),
    []
  );

  const newBreakpoint = useMemo(
    () => () => window.requestAnimationFrame(debouncedNewBreakpoint, 150),
    []
  );

  useIsomorphicLayoutEffect(() => {
    newBreakpoint();
    window.addEventListener('resize', newBreakpoint);
    return () => {
      window.removeEventListener('resize', newBreakpoint);
    };
  }, []);

  return (
    <BreakpointContext.Provider value={breakpoint}>
      {children}
    </BreakpointContext.Provider>
  );
};

BreakpointProvider.propTypes = {
  children: node,
};

export const useBreakpoint = () => {
  return useContext(BreakpointContext);
};
