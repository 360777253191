/** @jsxImportSource react */
import clsxm from '@common/lib/clsxm';
import React, { useState } from 'react';

interface ReadMoreProps {
  children: string;
  className?: string;
  onClick?: (displaySecondary: boolean) => void;
  buttonClassName?: string;
  clampClassName?: string;
}

const ReadMore = ({
  children,
  className,
  onClick,
  buttonClassName,
  clampClassName,
  ...props
}: ReadMoreProps) => {
  const [displaySecondary, setDisplaySecondary] = useState(false);

  return (
    <div
      className={clsxm(
        'font-b-s relative overflow-hidden text-ellipsis text-slate-lighter-40',
        displaySecondary
          ? 'line-clamp-none'
          : clampClassName || 'line-clamp-3 md:line-clamp-2',
        className
      )}
      {...props}
      data-tp-id='gen-div-c9b04c3f-57e5-4603-80e1-9619b5ad76bb'
    >
      {children}
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setDisplaySecondary(!displaySecondary);
          if (onClick) {
            onClick(displaySecondary);
          }
        }}
        className={clsxm(
          'absolute right-0 bottom-0 bg-white',
          children.length >= 250 ? 'span' : 'hidden',
          children.length >= 200 ? 'md:inline' : 'md:hidden',
          children.length >= 150 ? 'lg:inline' : 'lg:hidden',
          displaySecondary ? 'pl-xxs' : 'pl-0'
        )}
        data-tp-id='gen-div-ef9ac584-cb22-4a2e-b103-a20efb83d054'
      >
        {displaySecondary ? (
          <span
            className={clsxm(
              'hover:cursor-pointer hover:underline',
              buttonClassName || 'text-viridian-base'
            )}
            data-tp-id='gen-span-23798379-03ad-43f6-96fc-4824cf8fa199'
          >
            Read less
          </span>
        ) : (
          <>
            <span
              className='text-slate-lighter-40'
              data-tp-id='gen-span-5a73876c-db2d-463d-a5eb-157e49efb3e7'
            >
              ...
            </span>
            <span
              className={clsxm(
                'hover:cursor-pointer hover:underline',
                buttonClassName || 'text-viridian-base'
              )}
              data-tp-id='gen-span-7e0be4f8-1d20-4488-a4c3-eb69dc67e762'
            >
              Read more
            </span>
          </>
        )}
      </div>
    </div>
  );
};

export default ReadMore;
