import React, { forwardRef, Ref } from 'react';
import { SimpleIconProps } from '../icon.types';
import Icon from './Icon';

const svg = `<svg viewBox="0 0 24 24" fill="currentColor">
  <path d="M10.8625 8.15379L6.19563 12.8206C5.96773 13.0485 5.70052 13.1593 5.394 13.153C5.08749 13.1466 4.82028 13.0295 4.59238 12.8016C4.38115 12.5737 4.27235 12.3065 4.266 12C4.25967 11.6935 4.37045 11.4263 4.59835 11.1984L11.1984 4.59837C11.3103 4.4864 11.4346 4.4046 11.5712 4.35297C11.7078 4.30135 11.8507 4.27554 12 4.27554C12.1493 4.27554 12.2922 4.30135 12.4288 4.35297C12.5654 4.4046 12.6897 4.4864 12.8016 4.59837L19.4076 11.2043C19.6228 11.4196 19.7304 11.6826 19.7304 11.9935C19.7304 12.3043 19.6228 12.5737 19.4076 12.8016C19.1797 13.0295 18.9093 13.1435 18.5965 13.1435C18.2836 13.1435 18.0132 13.0295 17.7853 12.8016L13.1375 8.15379V19.0657C13.1375 19.385 13.0277 19.6544 12.8081 19.8739C12.5886 20.0935 12.3192 20.2033 12 20.2033C11.6808 20.2033 11.4114 20.0935 11.1918 19.8739C10.9723 19.6544 10.8625 19.385 10.8625 19.0657V8.15379Z"/>
</svg>`;

const Arrow = forwardRef(
  (props: SimpleIconProps, ref: Ref<SVGSVGElement> | undefined) => (
    <Icon ref={ref} name='arrow' svg={svg} {...props} />
  )
);

Arrow.displayName = 'ArrowIcon';

export default Arrow;
