import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import { timeZoneShortCode } from '../constants/date';
import { Community, TourDataInfo } from '../types';

dayjs.extend(utc);
dayjs.extend(tz);

// Return the date in the format YYYY-MM-DD of the first day and last day of the given month
export const getFirstAndLastDayOfMonth = (month: number, year: number) => {
  const firstDay = new Date(year, month, 1);
  const lastDay = new Date(year, month + 1, 1);
  return {
    firstDay: formatDate(firstDay),
    lastDay: formatDate(lastDay)
  };
};

// Get first and last day of year to date
export const getYearToDate = () => {
  const today = new Date();
  const firstDay = new Date(today.getFullYear(), 0, 1);
  const lastDay = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );
  return {
    firstDay: formatDate(firstDay),
    lastDay: formatDate(lastDay),
    firstDayLastYear: formatDate(new Date(today.getFullYear() - 1, 0, 1)),
    lastDayLastYear: formatDate(new Date(today.getFullYear() - 1, 11, 31))
  };
};

export const formatDate = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  const monthString = month < 10 ? `0${month}` : month;
  const dayString = day < 10 ? `0${day}` : day;

  return `${year}-${monthString}-${dayString}`;
};

export const formatDateWithSlashes = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  const monthString = month < 10 ? `0${month}` : month;
  const dayString = day < 10 ? `0${day}` : day;

  return `${monthString}/${dayString}/${year}`;
};

export function getTotalDaysInMonth(date: Date) {
  // Create a new Date object for the provided date
  const currentDate = new Date(date);

  // Get the year and month from the provided date
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1; // Months are 0-based, so add 1

  // Calculate the last day of the next month and subtract 1 to get the last day of the current month
  const lastDayOfMonth = new Date(year, month, 0).getDate();

  return lastDayOfMonth;
}

// Return exactly 1 year ago from the provided date
export function getYearsAgo(date: Date, years: number) {
  const currentDate = new Date(date);
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();
  const day = currentDate.getDate();

  return formatDate(new Date(year - years, month, day));
}

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
] as const;

export type MonthType = (typeof months)[number];

export const monthNameToAbbreviations: Record<MonthType, string> = {
  January: 'Jan',
  February: 'Feb',
  March: 'Mar',
  April: 'Apr',
  May: 'May',
  June: 'Jun',
  July: 'Jul',
  August: 'Aug',
  September: 'Sep',
  October: 'Oct',
  November: 'Nov',
  December: 'Dec'
};

export const formatDateV2 = (dateString: number | string | Date) => {
  const data = new Date(dateString);
  return new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    timeZone: 'UTC'
  }).format(data);
};

export const calcYearsOfExperience = (startedDate: string | number | Date) => {
  return new Date().getFullYear() - new Date(startedDate).getFullYear();
};

export const usDate = (value: number | string | Date) => {
  return dayjs(value).format('MM/DD/YYYY');
};

export const dayAfterTommorrow = dayjs().add(2, 'day').toDate();
export const afterTwoMonths = dayjs().add(2, 'month').toDate();

export const today = dayjs().toDate();

export const getTimeOptions = (startHour: number, endHour: number) => {
  const result = [];
  for (let i = startHour; i <= endHour; i++) {
    result.push({
      label: getAmPmTextByHour(i),
      value: i
    });
  }
  return result;
};

export const tourTimeOptions = [
  {
    label: 'Morning (between 8AM and 12PM)',
    value: 'Morning (between 8AM and 12PM)'
  },
  {
    label: 'Afternoon (between 12PM and 3PM)',
    value: 'Afternoon (between 12PM and 3PM)'
  },
  {
    label: 'Late afternoon (between 3PM and 5PM)',
    value: 'Late afternoon (between 3PM and 5PM)'
  }
];

export const getAmPmTextByHour = (hour: number) => {
  const displayHour = Number(hour) % 12;
  const AmPm = Number(hour) >= 12 ? 'PM' : 'AM';
  return `${displayHour > 0 ? hour % 12 : '12'}:00 ${AmPm}`;
};

const DAY_OF_WEEK = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const federalHolidays = [
  '12/25/2023',
  '01/01/2024',
  '01/15/2024',
  '02/19/2024',
  '05/27/2024',
  '06/19/2024',
  '07/04/2024',
  '09/02/2024',
  '10/14/2024',
  '11/11/2024',
  '12/25/2024',
  '12/28/2024'
];

// Given date will be converted to : Wednesday, May 10
export const getReadableDate = (date: any) => {
  return `${getDayFromDate(date)}, ${
    monthNames[dayjs(date).get('month')]
  } ${dayjs(date).get('date')}`;
};

export const getDayFromDate = (date: any) => {
  return DAY_OF_WEEK[dayjs(date).get('day')];
};

export const getHourFromDate = (date: any) => {
  return dayjs(date).get('hour');
};

// caution : It just creates a date in particular timezone and it will ignore time.
export const convertDateToTimezone = (date: any, timezone: string) => {
  return dayjs(dayjs(date).format('YYYY-MM-DD')).tz(timezone, true);
};

export const mergeDateAndTimeWithTimezone = (
  date: any,
  hour: number,
  timeZone: string
) => {
  if (date) {
    const newDate = convertDateToTimezone(date, timeZone).set('hour', hour);
    return newDate.format('YYYY/MM/DDTHH:mm:ssZ');
  }
};

export const getTourDateForApi = (
  date: any,
  hour: number,
  timezone: string
) => {
  if (date && hour) {
    return `${mergeDateAndTimeWithTimezone(date, hour, timezone)} ${
      timeZoneShortCode[timezone]
    }`;
  }
  return '';
};

export const getTourDataFromTourDataInfo = (tourDataInfo: TourDataInfo) => {
  const internalDate = tourDataInfo.tourDate;
  const tzdate = dayjs(internalDate, 'MM-DD-YYYY').toDate();
  return {
    tourDate: tzdate,
    tourTimeText: tourDataInfo.tourTimeText
  };
};

export const getTimeZoneFromCommunity = (community: Community) => {
  if (!community) return 'America/Los_Angeles';
  const { address, timeZone } = community;
  return address
    ? address.timeZone || 'America/Los_Angeles'
    : timeZone || 'America/Los_Angeles';
};

export const getTimeZoneFromUser = (user: any) => {
  return user?.uuidAux?.uuidInfo?.locationInfo?.timeZone;
};

export const getUsersTimeZone = (community: Community, user: any) => {
  let timeZone = '';
  if (community) {
    timeZone = getTimeZoneFromCommunity(community);
  } else if (user) {
    timeZone = getTimeZoneFromUser(user);
  }
  return timeZone || dayjs.tz.guess();
};

export const getTimeZoneForGwizzy = (
  timeZone: string | null | undefined,
  user: any
) => {
  if (timeZone) {
    return timeZone;
  } else if (user) {
    return getTimeZoneFromUser(user);
  }
  return dayjs.tz.guess();
};

export const isWeekDay = (day: string) => {
  const dayNum = dayjs(day).get('day');
  return dayNum !== 0 && dayNum !== 6;
};

export const tourDateFilter = (day: string) => {
  return isHoliday(day) ? false : isValidDayForTour(day);
};

const isHoliday = (day: string) => {
  return !isWeekDay(day) || isFedralHoliday(day);
};

const isFedralHoliday = (day: string) => {
  return federalHolidays.some(holiday => {
    return dayjs(day).isSame(dayjs(holiday, 'mm/dd/yyyy'), 'day');
  });
};

// There should be a gap of 1 working day between today and the requested tour date.
// We have a day gap but people should not be able to request tours for Mondays on a Friday.
// We should also disable all future dates (2 months +)
const isValidDayForTour = (day: string) => {
  const today = dayjs();
  let nextWorkingDay = null;
  let count = 0;
  while (!nextWorkingDay) {
    count++;
    if (!isHoliday(today.add(count, 'day').toISOString())) {
      nextWorkingDay = today.add(count, 'day');
    }
  }
  return (
    dayjs(day).isAfter(nextWorkingDay) && dayjs(day).isBefore(afterTwoMonths)
  );
};

// Get the next 9 avaiable days using the tourDateFilter and return an array
export const getTourTileDates = () => {
  const today = dayjs();
  const dates = [];
  let count = 0;
  while (dates.length < 9) {
    count++;
    const day = today.add(count, 'day').toISOString();
    if (tourDateFilter(day)) {
      dates.push(day);
    }
  }
  return dates;
};

export const isAfter = (date: string) => {
  return dayjs(date).isAfter(dayjs());
};

export const isBefore = (date: string) => {
  return dayjs(date).isBefore(dayjs());
};
