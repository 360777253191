import { addToLocalStorage } from '@common/helpers/localStorage';
import { eventNames } from '@react/services/events';
import { EXIT_INTENT_ASK_QUESTIONS } from '@sly/core/constants/actionTypes';
import { DEFAULT_EMAIL_VALUE } from '@sly/core/constants/retentionPopup';

const getRefactoredExitIntentInfo = (stepsData, values) => {
  stepsData.forEach((step) => {
    if (step.type === 'multipleChoice' && values[step.dbField]) {
      values[step.dbField] = {
        question: step.question,
        answers: values[step.dbField],
      };
    }
  });
  return { ...values };
};
export const submitGwizzyExitIntentModal =
  ({ events, stepsData, createAction, pathname, variant }) =>
  ({ dispatch }) =>
  async ({ values = {} }) => {
    if (values) {
      dispatch({ type: 'SET_VALUES', payload: values });
    }

    // Get Email value
    const emailField = stepsData?.filter((step) => step.type === 'email')?.[0];
    if (emailField) {
      const emailValue = values?.[emailField?.dbField];
      if (emailValue) {
        addToLocalStorage(DEFAULT_EMAIL_VALUE, emailValue);
      }
    }

    events.track(eventNames.WizardSubmitted, {
      location: 'exit-intent-modal',
      pathname,
      ...values,
    });

    dispatch({ type: 'SET_STATUS', payload: 'submitting' });

    const actionInfo = getRefactoredExitIntentInfo(stepsData, values);

    try {
      await createAction({
        type: 'UUIDAction',
        attributes: {
          actionType: EXIT_INTENT_ASK_QUESTIONS,
          actionInfo: {
            ...actionInfo,
            variant,
          },
          actionPage: pathname,
        },
      });
    } catch (e) {
      dispatch({ type: 'SET_STATUS', payload: 'error' });
      console.error(e);
    }
    dispatch({ type: 'SET_STATUS', payload: 'done' });
  };

export const setActivePageExitIntentModal =
  ({ events, pathname }) =>
  ({ dispatch, getState }) =>
  async ({ page, values = {}, step, action }) => {
    const { stepsToIndexMap } = getState();

    const eventName =
      action === 'onNext'
        ? eventNames.WizardStepCompleted
        : eventNames.WizardStepPrevious;

    events.track(eventName, {
      location: 'exit-intent-modal',
      step,
      pathname,
      ...values,
    });

    if (values) {
      dispatch({ type: 'SET_VALUES', payload: values });
    }
    dispatch({ type: 'SET_PAGE', payload: stepsToIndexMap?.[page] });
  };

export const exitIntentAsyncHandler = (props) => {
  return {
    SUBMIT_GWIZZY: submitGwizzyExitIntentModal(props),
    SET_ACTIVE_PAGE: setActivePageExitIntentModal(props),
  };
};

export const getResidentFirstAndLastNameByFullName = (fullName) => {
  if (!fullName) return null;
  return {
    firstName: fullName.split(' ')[0],
    lastName: fullName.split(' ').slice(1)?.join?.(' ') || '',
  };
};

export function getFullName(firstName, lastName) {
  return [firstName, lastName].filter(Boolean).join(' ');
}
