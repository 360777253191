/** @jsxImportSource react */
import MatchedAgent from '@react/cta/components/MatchedAgent';
import PostConversionGreeting from '@react/cta/components/PostConversionGreeting';
import Modal, {
  HeaderWithClose,
  ModalBody,
} from '@react/shared/components/NewModal';
import { Checkmark } from '@sly/icons/react';
import { bool, func, object } from 'proptypes';
import React from 'react';

const PostConversionModal = ({
  modalOpened,
  toggleModal,
  agent,
  community,
}) => {
  return (
    <>
      <Modal isOpen={modalOpened} onClose={toggleModal}>
        <HeaderWithClose Icon={Checkmark} onClose={toggleModal}>
          Success!
        </HeaderWithClose>
        <ModalBody>
          {agent && (
            <MatchedAgent
              agent={agent}
              heading={`Request sent! Your Seniorly Local Advisor, ${agent.name} will get back to you with pricing information on this community.`}
              onReturn={toggleModal}
            />
          )}
          {!agent && community && (
            <PostConversionGreeting
              community={community}
              toggleModal={toggleModal}
            />
          )}
          {!agent && !community && (
            <div className='flex justify-center'>
              <h3
                className='font-t-s-azo mb-m text-center'
                data-tp-id='gen-h3-a543de08-ef59-4a4f-8406-131e912054b0'
              >
                Request sent! Your Seniorly Local Advisor, will get back to you
                with recommendations.
              </h3>
            </div>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

PostConversionModal.propTypes = {
  modalOpened: bool,
  toggleModal: func.isRequired,
  agent: object,
  community: object,
};

export default PostConversionModal;
