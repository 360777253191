import { Variant } from '@common/types';

export const STRAPI_COMPONENTS = {
  articleImage: 'article.image',
  articleEditor: 'article.editor',
  audienceNudgesCTA: 'audience-nudges.cta',
  audienceNudgesToast: 'audience-nudges.toast',
  audienceNudgesModal: 'audience-nudges.modal',
  audienceNudgesBot: 'audience-nudges.bot',
  text: 'common.text',
  link: 'common.link',
  cta: 'common.cta'
};

export const STRAPI_CTA_TYPES = {
  assessmentWizard: 'assessment_wizard',
  getExpertHelp: 'get_expert_help',
  bot: 'bot',
  typeform_wizard: 'typeform_wizard',
  path: 'path',
  gwizzy: 'gwizzy',
  scheduleTourSpecific: 'schedule_tour_(tour_specific_cta)',
  scheduleTourHybrid: 'schedule_tour_(gwizzy_hybrid_cta)'
};

export const STRAPI_DATA_TYPES = {
  PricingCta: 'PricingCta'
};

export const DEFAULT_CTA_OBJECTS = {
  pricingAssessmentWizard: {
    type: STRAPI_CTA_TYPES.assessmentWizard,
    text: 'Get Pricing',
    palette: 'viridian',
    variant: 'primary'
  },
  pricingTypeformWizard: {
    type: STRAPI_CTA_TYPES.typeform_wizard,
    text: 'Get Pricing',
    palette: 'viridian',
    variant: 'primary'
  },
  pricingBoxTypeformWizard: {
    type: STRAPI_CTA_TYPES.typeform_wizard,
    text: 'Get Pricing',
    variant: 'neutral'
  },
  searchAssessmentWizard: {
    type: STRAPI_CTA_TYPES.assessmentWizard,
    text: 'Get access',
    variant: 'neutral'
  },
  askAgentQuestion: {
    type: STRAPI_CTA_TYPES.getExpertHelp,
    text: 'Talk to our agent about your options',
    palette: 'viridian',
    variant: 'primary'
  },
  plusSidebarPrimary: {
    type: STRAPI_CTA_TYPES.typeform_wizard,
    text: 'Get pricing and availability',
    palette: 'viridian',
    variant: 'primary'
  },
  plusAskAgentQuestion: {
    type: STRAPI_CTA_TYPES.getExpertHelp,
    text: 'Get help from [AGENT]',
    variant: 'secondary'
  },
  plusSecondaryCta: {
    type: STRAPI_CTA_TYPES.path,
    text: 'Schedule appt or tour',
    variant: 'primary',
    palette: 'harvest'
  },
  newProfilePricingTypeformWizard: {
    type: STRAPI_CTA_TYPES.typeform_wizard,
    text: 'Get personalized pricing',
    palette: 'viridian',
    variant: 'primary'
  },
  gwizzy: {
    type: STRAPI_CTA_TYPES.gwizzy,
    text: 'Get personalized pricing',
    palette: 'viridian',
    variant: 'primary'
  }
};

export const STRAPI_SOCIAL_PROOF_DATA_TYPES = {
  PeopleVisitedMonth: 'peopleVisitedMonth',
  NoOfInquiries: 'noOfInquiries'
};

export const getAssessmentBoxModes = {
  pricingTable: { cta: 'pricing', entry: 'pricingTable' },
  profileSection: {
    cta: 'generalOptions',
    entry: 'profileSection'
  },
  communityFooter: {
    cta: 'pricing',
    entry: 'communityFooter'
  },
  communitySidebar: {
    cta: 'pricing',
    entry: 'communitySidebar'
  },
  howItWorksSection: {
    cta: 'pricing',
    entry: 'howItWorks'
  },
  compare: {
    cta: 'pricing',
    entry: 'compare'
  }
};

const defaultProfileCta = {
  type: STRAPI_CTA_TYPES.typeform_wizard,
  text: 'Get pricing',
  palette: 'viridian',
  variant: 'primary' as Variant
};

export interface CtaProps extends Omit<typeof defaultProfileCta, 'variant'> {
  variant: Variant;
}

export type CtaTypes =
  | 'pricingCta'
  | 'sidebarCta'
  | 'stickyFooterCta'
  | 'boxCta'
  | 'agentCta'
  | 'compareCta'
  | 'secondaryCta';

export type CtaMode = typeof getAssessmentBoxModes.communityFooter;

export const strapiProfileCtaSection: {
  [key in CtaTypes]: {
    name: CtaTypes;
    default: CtaProps;
    mode: typeof getAssessmentBoxModes.communityFooter;
  };
} = {
  pricingCta: {
    name: 'pricingCta',
    default: {
      ...defaultProfileCta,
      text: 'Get personalized pricing'
    },
    mode: getAssessmentBoxModes.pricingTable
  },
  sidebarCta: {
    name: 'sidebarCta',
    default: {
      ...defaultProfileCta
    },
    mode: getAssessmentBoxModes.communitySidebar
  },
  stickyFooterCta: {
    name: 'stickyFooterCta',
    default: {
      ...defaultProfileCta
    },
    mode: getAssessmentBoxModes.communityFooter
  },
  boxCta: {
    name: 'boxCta',
    default: {
      ...defaultProfileCta
    },
    mode: getAssessmentBoxModes.howItWorksSection
  },
  agentCta: {
    name: 'agentCta',
    default: {
      ...defaultProfileCta,
      text: 'Talk to our agent about your options',
      type: STRAPI_CTA_TYPES.getExpertHelp
    },
    mode: getAssessmentBoxModes.pricingTable
  },
  compareCta: {
    name: 'compareCta',
    default: {
      ...defaultProfileCta,
      text: 'Get Pricing',
      type: STRAPI_CTA_TYPES.gwizzy
    },
    mode: getAssessmentBoxModes.compare
  },
  secondaryCta: {
    name: 'secondaryCta',
    default: {
      ...defaultProfileCta,
      text: 'Request Tour',
      type: STRAPI_CTA_TYPES.scheduleTourHybrid
    },
    mode: getAssessmentBoxModes.communitySidebar
  }
};
