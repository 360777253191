/** @jsxImportSource react */
import clsxm from '@common/lib/clsxm';
import FieldAdapter from '@react/form/components/FieldAdapter';
import GwizzyActions from '@react/gwizzy/components/GwizzyActions';
import GwizzyField from '@react/gwizzy/components/GwizzyField';
import { FormObject, TooltipSchema } from '@react/gwizzy/types';
import { Button } from '@react/system';
import ChatIcon from '@react/virtual-advisor/components/ChatIcon';
import { useWizarForm } from '@react/wizards/context/wizardContext';
import { Arrow, Chevron } from '@sly/icons/react';
import { useMemo } from 'react';
import { useForm, useFormState } from 'react-final-form';

export default function GwizzyForm({
  form: {
    getActions,
    fields,
    getQuestion,
    fieldWrapperClassName,
    formLabel,
    subHeading,
    footer,
    HeaderComponent,
  },
  tooltip,
  step,
  title,
  formWrapperClassName,
  isChatMode,
  hasExperimentalOnBack,
}: {
  form: FormObject;
  tooltip: TooltipSchema;
  step: string;
  title: string;
  formWrapperClassName: string;
  isChatMode: boolean;
  hasExperimentalOnBack: boolean;
}) {
  const {
    dispatch,
    state: { progressPath },
    onBack,
  } = useWizarForm();

  const hasPreviousStep = progressPath?.length > 1;

  const {
    heading,
    ToolTipComponent,
    className: tooltipClassName,
  } = tooltip || {};

  const useForm_ = useForm();
  const formState = useFormState();
  const { values } = formState;

  const question = getQuestion ? getQuestion({ values }) : '';
  const fieldsInCurrentPage = fields?.map(({ name }) => name);
  const actions = useMemo(() => getActions({ values }), [getActions, values]);
  const actionValidations = useMemo(() => {
    const validations = Object.entries(actions).reduce(
      (acc: any[], [actionName, action]: any) => {
        if (actionName === 'onNext' || actionName === 'onSubmit') {
          const { validations } = action;
          if (validations) {
            validations?.forEach(({ name }: { name: string }) => {
              acc.push(name);
            });
          }
        }
        return acc;
      },
      []
    );
    return validations;
  }, [actions]);

  const { FormFooter, footerClassName } = useMemo(() => footer || {}, [footer]);

  return (
    <>
      <div
        data-test-id='gwizzy-form'
        className={clsxm(
          isChatMode
            ? 'flex flex-1 flex-col justify-between space-y-4'
            : 'h-fit max-w-full bg-white px-6 pb-6 pt-4',
          formWrapperClassName
        )}
      >
        <div className='flex flex-col gap-2'>
          {HeaderComponent && <HeaderComponent values={values} />}
          {subHeading && (
            <h4
              className={clsxm(
                isChatMode
                  ? 'font-b-s ml-8 self-start rounded-xl bg-slate-lighter-95 px-4 py-2 leading-5 text-black'
                  : 'font-b-m pb-4'
              )}
            >
              {subHeading}
            </h4>
          )}
          {title && !isChatMode && <div className='mb-1'>{title}</div>}
          {question && (
            <div className='flex gap-2'>
              {isChatMode && <ChatIcon className='self-end' />}
              <h2
                className={clsxm(
                  isChatMode
                    ? 'font-b-s self-start rounded-xl bg-slate-lighter-95 px-4 py-2 leading-5 text-black'
                    : 'font-t-m pb-4'
                )}
              >
                {question}
              </h2>
            </div>
          )}
          {formLabel && <h5 className='font-b-m pb-4'>{formLabel}</h5>}
        </div>

        <div>
          <fieldset
            className={clsxm(
              'animate-in fade-in duration-500 ease-in-out',
              fieldWrapperClassName
            )}
          >
            {fields?.map(
              ({
                name,
                onChangeHandler,
                getOptions,
                validate: _,
                hidden,
                ...props
              }) => {
                if (hidden && hidden({ values })) {
                  return null;
                }
                const fieldProps: any = {};
                if (getOptions) {
                  fieldProps.options = getOptions({ formState });
                }
                if (onChangeHandler) {
                  fieldProps.onChangeHandler = () =>
                    onChangeHandler({ useForm: useForm_, formState });
                } else if (actionValidations.length) {
                  fieldProps.onChangeHandler = () => {
                    const { mutators } = useForm_;
                    actionValidations.forEach((name) => {
                      mutators.setAsyncError(name);
                    });
                  };
                }

                return (
                  <GwizzyField
                    key={name}
                    name={name}
                    {...props}
                    {...fieldProps}
                    component={FieldAdapter}
                    isChatField={isChatMode}
                  />
                );
              }
            )}
          </fieldset>
          {hasPreviousStep && hasExperimentalOnBack && (
            <Button
              variant='custom'
              className={clsxm(
                'absolute top-0 left-0 pt-6',
                isChatMode ? '!pl-3' : ''
              )}
              onClick={() => onBack()}
              type='button'
              tabIndex={-1}
            >
              <div className='flex justify-center gap-2'>
                {isChatMode ? (
                  <Arrow size='m' className='-rotate-90 hover:cursor-pointer' />
                ) : (
                  <Chevron className='min-w-[24px] -rotate-90' size='m' />
                )}

                {!isChatMode && <div>Back</div>}
              </div>
            </Button>
          )}
          <div className='mt-2 flex justify-end gap-4'>
            {Object.entries(actions).map(
              ([
                action,
                {
                  type,
                  goTo,
                  getType,
                  getText,
                  validations,
                  asyncHandlerProps = {},
                  ...buttonProps
                },
              ]: any) => {
                const actionProps: any = {};
                if (action !== 'onSubmit' && goTo) {
                  actionProps.page = goTo(values);
                }
                const updatedType = getType ? getType(values) : type;

                if (getText) {
                  buttonProps.text = getText(values);
                }
                return (
                  <GwizzyActions
                    key={action}
                    onClick={(values: any) => {
                      dispatch({
                        type: updatedType,
                        values,
                        step,
                        action,
                        form: useForm_,
                        validations,
                        asyncHandlerProps,
                        ...actionProps,
                      });
                    }}
                    action={action}
                    fields={fieldsInCurrentPage}
                    noOfActions={Object.values(actions)?.length}
                    isChatMode={isChatMode}
                    {...buttonProps}
                  />
                );
              }
            )}
          </div>
          {FormFooter && (
            <div className={clsxm(footerClassName, 'mt-6')}>
              <FormFooter values={values} />
            </div>
          )}
        </div>
      </div>
      {tooltip && !isChatMode && (
        <div className={clsxm('max-w-full p-6', tooltipClassName)}>
          {heading && (
            <h4 className='font-l-m mb-4 text-slate-base'>{heading}</h4>
          )}
          <ToolTipComponent />
        </div>
      )}
    </>
  );
}
