import { useReducerAsync } from '@react/lib/useReducerAsync';
import React, { useCallback, useMemo } from 'react';

const actionTypes = {
  SET_PAGE: 'SET_PAGE',
  SET_VALUES: 'SET_VALUES',
  SET_STATUS: 'SET_STATUS',
  RESET: 'RESET',
};

export const initialState = {
  page: 0,
  values: {},
  stepsToIndexMap: {},
  status: 'started',
  progressPath: [0],
};

export const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_PAGE: {
      const targetPage = action.payload;
      let newProgressPath = [...state.progressPath];
      if (!state.progressPath.includes(targetPage)) {
        // Upon moving forward, add targetPage to progressPath
        newProgressPath.push(targetPage);
      } else {
        // Upon moving backward, truncate progressPath up until targetPage (make progressPath reflect only the visited steps sequentially)
        const targetPageIndex = state.progressPath.indexOf(targetPage);
        newProgressPath = state.progressPath.slice(0, targetPageIndex + 1);
      }
      return {
        ...state,
        page: targetPage,
        progressPath: newProgressPath,
      };
    }
    case 'SET_VALUES': {
      return { ...state, values: action.payload };
    }
    case 'SET_STATUS': {
      return { ...state, status: action.payload };
    }
    case 'RESET': {
      return { ...initialState };
    }

    default:
      throw new Error('no such action type');
  }
};

export default function useFinalFormWizard({
  initialValues,
  children,
  onSubmit,
  asyncActionHandlers,
  localStorageKey,
  initialStep,
}) {
  const stepsToIndexMap = useMemo(
    () =>
      React.Children.toArray(children).reduce((acc, currPage, index) => {
        if (currPage?.props?.name) {
          acc[currPage.props.name] = index;
        }
        return acc;
      }, {}),
    [children]
  );

  const [state, dispatch] = useReducerAsync(
    localStorageKey,
    reducer,
    {
      ...initialState,
      values: initialValues,
    },
    ({ values, page, progressPath, ...initial } = initialState) => {
      return {
        ...initial,
        values: { ...initialValues, ...values },
        page: initialStep || page,
        stepsToIndexMap,
        progressPath: initialStep ? [initialStep] : progressPath,
      };
    },
    asyncActionHandlers ? asyncActionHandlers : {}
  );

  const { page, values, status } = state;

  const totalSteps = useMemo(
    () => React.Children.toArray(children).filter(Boolean)?.length,
    [children]
  );

  const activePage = useMemo(
    () => React.Children.toArray(children)[page],
    [page, children]
  );

  const isLastPage = useMemo(() => page === totalSteps - 1, [page, totalSteps]);

  const goToStep = useCallback(
    (targetPageName, values) => {
      const targetPage = stepsToIndexMap[targetPageName];
      dispatch({
        type: actionTypes.SET_PAGE,
        payload: targetPage,
      });
      if (values) {
        dispatch({ type: actionTypes.SET_VALUES, payload: values });
      }
    },
    [stepsToIndexMap, dispatch]
  );

  const onNext = useCallback(
    (values) => {
      // Find next page index
      const nextPageIndex = Math.min(page + 1, totalSteps - 1);
      dispatch({
        type: actionTypes.SET_VALUES,
        payload: values,
      });
      dispatch({
        type: actionTypes.SET_PAGE,
        payload: nextPageIndex,
      });
    },
    [dispatch, page, totalSteps]
  );

  const onBack = useCallback(() => {
    // Find previous step that exists in the progressPath
    const progressPath = state.progressPath || [];
    const previousPageIndex =
      progressPath?.length > 1 ? progressPath[progressPath.length - 2] : 0;
    dispatch({
      type: actionTypes.SET_PAGE,
      payload: previousPageIndex,
    });
  }, [state.progressPath, dispatch]);

  const validate = useCallback(
    (values) =>
      activePage.props.validate ? activePage.props.validate(values) : {},
    [activePage.props]
  );

  const handleSubmit = useCallback(
    (values) => (isLastPage && onSubmit ? onSubmit(values) : onNext(values)),
    [isLastPage, onNext, onSubmit]
  );

  return {
    page,
    values,
    totalSteps,
    activePage,
    isLastPage,
    stepsToIndexMap,
    goToStep,
    onNext,
    onBack,
    validate,
    handleSubmit,
    dispatch,
    state,
  };
}
