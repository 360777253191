import { Cookies } from 'react-cookie';
import { domain } from 'sly/config';
import { randomHexNumber } from './utils';
import { parse, v4 } from 'uuid';
import { UUID, SID } from '@sly/core/constants/cookie';

const cookie = new Cookies();

const byteArrayToLong = (/* byte[] */ byteArray: any) => {
  let value = 0;
  for (let i = byteArray.length - 1; i >= 0; i--) {
    value = value * 256 + byteArray[i];
  }

  return value;
};

export const isUUID = (uuid: string) => {
  const s = `${uuid}`;

  const match = s.match(
    '^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$'
  );
  if (match === null) {
    return false;
  }
  return true;
};

export const setCookie = (name: string, value: any, maxAge = 27000000) =>
  cookie.set(name, value, { domain, path: '/', maxAge });

export const getCookie = (name: string) => cookie.get(name);

export const setUUID = (slyUuid = v4()) => {
  setCookie(UUID, slyUuid);
  return slyUuid;
};

export const getUuidValue = () => {
  const bytes = parse(getCookie(UUID));
  return byteArrayToLong(bytes);
};

export const getUUID = () => {
  return getCookie(UUID) || setUUID();
};

export const setSID = (sid = randomHexNumber()) => {
  setCookie(SID, sid, 3600);
  return sid;
};

export const getSID = () => {
  return getCookie(SID) || setSID();
};
