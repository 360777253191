export function isCarousel(message) {
  return (
    Object.keys(message).includes('attachment') &&
    Object.keys(message.attachment).includes('type') &&
    message.attachment.type === 'template' &&
    Object.keys(message.attachment).includes('payload') &&
    Object.keys(message.attachment.payload).indexOf('template_type') >= 0 &&
    message.attachment.payload.template_type === 'generic' &&
    Object.keys(message.attachment.payload).indexOf('elements') >= 0 &&
    message.attachment.payload.elements.length > 0
  );
}

export function isVideo(message) {
  return (
    Object.keys(message).includes('attachment') &&
    Object.keys(message.attachment).includes('type') &&
    message.attachment.type === 'video'
  );
}

export function isImage(message) {
  return (
    Object.keys(message).includes('attachment') &&
    Object.keys(message.attachment).includes('type') &&
    message.attachment.type === 'image'
  );
}

export function isText(message) {
  return (
    Object.keys(message).length === 1 && Object.keys(message).includes('text')
  );
}

export function isButtons(message) {
  return (
    Object.keys(message).length >= 2 &&
    Object.keys(message).includes('text') &&
    (Object.keys(message).includes('quick_replies') ||
      Object.keys(message).includes('buttons'))
  );
}

export function isSingleSelect(message) {
  return (
    Object.keys(message).includes('attachment') &&
    Object.keys(message.attachment).includes('type') &&
    message.attachment.type === 'single_select' &&
    Object.keys(message.attachment).includes('payload') &&
    Object.keys(message.attachment.payload).indexOf('type') >= 0 &&
    message.attachment.payload.type === 'single' &&
    Object.keys(message.attachment.payload).indexOf('options') >= 0 &&
    message.attachment.payload.options.length > 0
  );
}

export function isMultiSelect(message) {
  return (
    Object.keys(message).includes('attachment') &&
    Object.keys(message.attachment).includes('type') &&
    message.attachment.type === 'multi_select' &&
    Object.keys(message.attachment).includes('payload') &&
    Object.keys(message.attachment.payload).indexOf('type') >= 0 &&
    message.attachment.payload.type === 'multi' &&
    Object.keys(message.attachment.payload).indexOf('options') >= 0 &&
    message.attachment.payload.options.length > 0
  );
}

export function isTextInput(message) {
  return (
    Object.keys(message).includes('attachment') &&
    Object.keys(message.attachment).includes('type') &&
    message.attachment.type === 'text_input'
  );
}

export function isDatePicker(message) {
  return (
    Object.keys(message).includes('attachment') &&
    Object.keys(message.attachment).includes('type') &&
    message.attachment.type === 'date_picker'
  );
}
