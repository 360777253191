/** @jsxImportSource react */
import { Checkmark, Dropdown } from '@sly/icons/react';
import cx from 'classnames';
import {
  arrayOf,
  bool,
  func,
  node,
  object,
  oneOfType,
  string,
} from 'proptypes';
import React from 'react';
import SyncSelect, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import Creatable from 'react-select/creatable';

const {
  Option,
  Group,
  SingleValue,
  DropdownIndicator: NewDropdownIndicator,
} = components;

const pallateToColor = (pallate) => {
  if (pallate === 'primary') {
    return 'viridian';
  }
  return 'viridian';
};

const IconOption = ({ selectProps, ...props }) => {
  const IconProps = {
    className: `mt-0 mr-s mb-0 ml-0 justify-center align-baseline w-${
      selectProps.textSize
    } h-${selectProps.textSize} ${
      `text-${
        props.isSelected ? 'white' : pallateToColor(props.data.palette)
      }-base` || 'text-viridian-base'
    }`,
  };
  const Icon = props.icon || Checkmark;
  const pp = props.data.palette || 'primary';
  const showIcon = props.data.icon;
  return (
    <Option
      class='sly-select-option'
      showIcon={showIcon}
      palette={pp}
      {...props}
    >
      {showIcon && (
        <Icon
          className='sly-select-icon mr-s mt-0 mb-0 ml-0 justify-center align-baseline text-white'
          {...IconProps}
          size='m'
          data-tp-id='gen-Icon-01ae362c-0369-4bcb-ae33-6c0988bb4037'
        />
      )}
      <span data-tp-id='gen-span-a61cdc53-e514-4f4c-abdd-76e0f6799608'>
        {props.data.label}
      </span>
    </Option>
  );
};

IconOption.propTypes = {
  selectProps: object,
  data: object,
  isSelected: bool,
};

const IconSingleValue = ({ selectProps, ...props }) => {
  const IconProps = {
    className: `mt-0 mr-s mb-0 ml-0 justify-center align-baseline w-${
      selectProps.textSize
    } h-${selectProps.textSize} ${
      `text-${
        props.isSelected ? 'white' : pallateToColor(props.data.palette)
      }-base` || 'text-viridian-base'
    }`,
  };
  const Icon = props.icon || Checkmark;
  const pp = props.data.palette || 'primary';
  const showIcon = !!props.data.icon;
  return (
    <SingleValue
      className='sly-select-singleValue'
      showIcon={showIcon}
      palette={pp}
      {...props}
    >
      {showIcon && (
        <Icon
          className='sly-select-icon mr-s mt-0 mb-0 ml-0 justify-center align-baseline'
          {...IconProps}
          data-tp-id='gen-Icon-b2b13044-387f-4196-a196-5d81f5e514b5'
        />
      )}
      <span data-tp-id='gen-span-d8851df1-1c60-4345-bc1e-21028b225251'>
        {props.data.label}
      </span>
    </SingleValue>
  );
};

IconSingleValue.propTypes = {
  selectProps: object,
  data: object,
  isSelected: bool,
};
const GroupSection = (props) => {
  const lastGroupLabel = props.selectProps.options.map((v) => v.label).pop();
  return (
    <Group {...props}>
      {props.children}
      {props.label !== lastGroupLabel && (
        <hr
          className='mt-2'
          data-tp-id='gen-Hr-21308160-bd31-44c6-b15c-601562a4309e'
        />
      )}
    </Group>
  );
};

GroupSection.propTypes = {
  children: node,
  selectProps: object,
  label: string,
};

const DropdownIndicator = (props) => {
  return (
    <NewDropdownIndicator {...props}>
      <Dropdown className='rotate-180' />
    </NewDropdownIndicator>
  );
};

const Select = ({
  fontSize,
  lineHeight,
  value,
  components,
  options,
  async,
  createable,
  loadOptions,
  disabled,
  className,
  customStyles,
  borderless,
  isSearchable,
  ...props
}) => {
  let SelectComponent;
  if (async) {
    SelectComponent = AsyncSelect;
    if (createable) {
      SelectComponent = AsyncCreatableSelect;
    }
  } else if (createable) {
    SelectComponent = Creatable;
  } else {
    SelectComponent = SyncSelect;
  }

  const flattenedOptions = options.reduce((acc, opt) => {
    if (opt.options) {
      acc.push(...opt.options);
    } else {
      acc.push(opt);
    }
    return acc;
  }, []);

  // the only occasion that we build the values from outside is when we are using
  // autocomplete, which doesn't have a given array of options
  if (flattenedOptions.length) {
    if (props.isMulti) {
      value = flattenedOptions.filter((o) => (value || []).includes(o.value));
    } else {
      if (value?.value) {
        value = flattenedOptions.find((o) => value.value === o.value);
      } else {
        value = flattenedOptions.find((o) => value === o.value);
      }
    }
  }

  const customOption = customStyles.option ? customStyles.option : {};
  const customControl = customStyles.control ? customStyles.control : {};

  const customStylesObj = {
    option: (provided, state) => ({
      ...provided,
      fontSize: fontSize ? fontSize : 'unset',
      lineHeight: lineHeight ? lineHeight : 'unset',
      color: state.isSelected ? '#1A7473' : '#253348',
      background: state.isSelected ? '#E8F1F1' : 'transparent',
      '&:hover': {
        background: '#F2F3F4',
      },
      ...customOption,
    }),
    control: (provided) => ({
      ...provided,
      border: borderless ? '0px' : '1px solid #253348',
      fontSize: fontSize ? fontSize : 'unset',
      ...customControl,
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
  };

  return (
    <div
      className={cx('selectWrapper', className)}
      data-tp-id='gen-div-0324301f-ebaa-4b8b-bbce-ae7b3e0b6caa'
    >
      <SelectComponent
        className='react-select-container'
        classNamePrefix='react-select'
        options={options}
        loadOptions={loadOptions}
        value={value}
        textSize={fontSize}
        components={{
          Option: IconOption,
          Group: GroupSection,
          SingleValue: IconSingleValue,
          DropdownIndicator,
          ...components,
        }}
        blurInputOnSelect
        isDisabled={disabled}
        styles={customStylesObj}
        {...props}
        isSearchable={isSearchable}
      />
    </div>
  );
};

Select.propTypes = {
  fontSize: string, // Eg. 1rem
  lineHeight: string,
  className: string,
  async: bool,
  createable: bool,
  value: oneOfType([string, arrayOf(string)]),
  options: arrayOf(object).isRequired,
  components: object,
  loadOptions: func,
  isSearchable: bool,
  isMulti: bool,
  disabled: bool,
  customStyles: object,
  borderless: bool,
};

Select.defaultProps = {
  fontSize: '14px',
  async: false,
  createable: false,
  options: [],
  components: {},
  isSearchable: false,
  borderless: false,
  customStyles: {},
};

export default Select;
