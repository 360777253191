import { assetsUrl } from 'sly/config';

export const getFormat = ({ width, height, aspectRatio, crop = true }) => {
  if (!(width || height)) {
    throw new Error('Image Handler needs at least one dimension');
  }

  if (!height && aspectRatio) {
    height = Math.ceil(width * aspectRatio);
  }

  const cropString = !crop && width && height ? 'a' : '';

  return `${cropString}${width || ''}x${height || ''}`;
};

export const getImagePath = (path, format) => {
  if (format) {
    return `${assetsUrl}/images/${getFormat(format)}/${path}`;
  }

  // original
  return `${assetsUrl}/uploads/${path}`;
};

const getSrcsetForPath = (imagePath, { sources, aspectRatio, crop }) =>
  sources
    .map(source => {
      let width;
      let height;
      if (Array.isArray(source)) {
        [width, height] = source;
      } else {
        width = source;
      }

      const format = { width, height, aspectRatio, crop };
      return `${getImagePath(imagePath, format)} ${width}w`;
    })
    .join(', ');

export const getSrcset = (imagePath, config) => ({
  src: getImagePath(imagePath, {
    width: 768,
    aspectRatio: config.aspectRatio,
    crop: config.crop
  }),
  srcSet: getSrcsetForPath(imagePath, config)
});

export const ratios = {
  '16:9': '56.25%',
  golden: '61.80%',
  '3:2': '66.66%',
  '4:3': '75%',
  '5:4': '80%',
  '6:5': '83%',
  '7:6': '86%',
  '1:1': '100%'
};

export const defaultImageSources = [
  320,
  375,
  416, // our mobile
  768, // our tablet
  1080, // our tablet
  1200 // our max,
];

export const breakpoint = {
  mobile: '416px',
  tablet: '768px',
  laptop: '1080px',
  desktop: '1280px'
};

export const getAlt = src => {
  if (!src) {
    return 'Seniorly Image';
  }
  const srcParts = src.split('/')[1].split('.')[0];
  return decodeURIComponent(srcParts);
};

export const sizeNames = ['mobile', 'tablet', 'laptop', 'desktop'];
export const makeSizes = sizes => {
  if (!Array.isArray(sizes)) {
    return sizes;
  }

  return sizes
    .reduce((acc, size, i) => {
      if (!size) {
        return acc;
      }

      const sizepx = typeof size === 'number' ? `${size}px` : size;
      if (i === 0) {
        acc.push(sizepx);
      } else {
        acc.splice(
          acc.length - 1,
          0,
          `(min-width: ${breakpoint?.[sizeNames[i]]}) ${sizepx}`
        );
      }

      return acc;
    }, [])
    .join(', ');
};

export const getImageProps = ({
  aspectRatio,
  sources,
  height,
  path,
  crop,
  alt,
  sizes,
  src
}) => {
  const isS3Path = !!path;
  const aspectRatioString = ratios?.[aspectRatio];
  const aspectRatioValue = (parseFloat(aspectRatioString) / 100).toFixed(4);

  let imageProps = {
    src,
    srcSet: ''
  };

  if (isS3Path) {
    let sourcesAry;
    if (!sources) {
      sourcesAry = defaultImageSources;
    } else {
      sourcesAry = sources;
    }
    if (height) {
      sourcesAry = sourcesAry.map(source => {
        if (Array.isArray(source)) {
          source[1] = height;
        } else {
          source = [source, height];
        }
        return source;
      });
    }

    // aspect ratio is a number in getSrcset
    const { srcSet, src } = getSrcset(encodeURI(path), {
      aspectRatio: aspectRatioValue,
      sources: sourcesAry,
      crop
    });

    // override imageProps src, as it's undefined
    imageProps['src'] = src;

    imageProps['srcSet'] = srcSet;
  }

  const imageSizes = makeSizes(sizes);

  return {
    imageProps,
    aspectRatioString,
    imageAlt: alt || getAlt(path),
    imageSizes
  };
};
