export const UUID = 'sly_uuid';
export const SID = 'sly_sid';
export const SESSION = 'sly-session';
export const UTM_COOKIE = 'sly_utm_cookie';
export const UTM_CAMPAIGN_PARAM = 'utm_campaign';
export const UTM_SOURCE_PARAM = 'utm_source';
export const UTM_MEDIUM_PARAM = 'utm_medium';
export const UTM_CONTENT_PARAM = 'utm_content';
export const UTM_TERM_PARAM = 'utm_term';
export const UTM_UPDATED = 'sly_utm_updated';
export const JWT = 'sly_jwt';
export const REFERRER = 'referrer';
