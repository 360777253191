import React, { forwardRef, Ref } from 'react';
import { SimpleIconProps } from '../icon.types';
import Icon from './Icon';

const svg = `<svg viewBox="0 0 24 24" fill="currentColor">
  <path style="transform: rotate(var(--rotation)); transform-origin: 12px 12px" d="M9.43098 14.1375C8.9212 14.1375 8.56685 13.9055 8.36793 13.4413C8.16902 12.9772 8.24982 12.5649 8.61033 12.2044L11.2103 9.60437C11.3223 9.4924 11.4456 9.40844 11.5802 9.35247C11.7148 9.29649 11.8567 9.26849 12.006 9.26849C12.1553 9.26849 12.2972 9.29649 12.4318 9.35247C12.5664 9.40844 12.6897 9.4924 12.8016 9.60437L15.4016 12.2044C15.7622 12.5649 15.8429 12.9772 15.644 13.4413C15.4451 13.9055 15.0908 14.1375 14.581 14.1375H9.43098Z"/>
</svg>`;

const Dropdown = forwardRef(
  (props: SimpleIconProps, ref: Ref<SVGSVGElement> | undefined) => (
    <Icon ref={ref} name='dropdown' svg={svg} {...props} />
  )
);

Dropdown.displayName = 'DropdownIcon';

export default Dropdown;
