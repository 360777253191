import React, { forwardRef, Ref } from 'react';
import { SimpleIconProps } from '../icon.types';
import Icon from './Icon';

const svg = `<svg viewBox="0 0 24 24" fill="currentColor">
  <path d="M4.07175 20.2033C3.44204 20.2033 2.90544 19.9816 2.46195 19.5381C2.01849 19.0946 1.79675 18.558 1.79675 17.9283V6.07178C1.79675 5.44206 2.0185 4.90546 2.462 4.46198C2.90547 4.01849 3.44206 3.79675 4.07178 3.79675H19.9283C20.558 3.79675 21.0946 4.01849 21.5381 4.46198C21.9816 4.90546 22.2033 5.44206 22.2033 6.07178V17.9283C22.2033 18.558 21.9816 19.0946 21.5381 19.5381C21.0946 19.9816 20.558 20.2033 19.9283 20.2033H4.07175ZM19.9283 8.2571L12.6027 12.8783C12.5082 12.9363 12.4089 12.9797 12.3049 13.0087C12.2009 13.0377 12.0993 13.0522 12 13.0522C11.9007 13.0522 11.7991 13.0377 11.6951 13.0087C11.5911 12.9797 11.4918 12.9363 11.3973 12.8783L4.07175 8.2571V17.9283H19.9283V8.2571ZM12 11.0718L19.9283 6.07178H4.07175L12 11.0718ZM4.07175 8.531V6.93265V6.96043V6.07178V6.96145V6.93998V8.531Z"/>
</svg>`;

const Mail = forwardRef(
  (props: SimpleIconProps, ref: Ref<SVGSVGElement> | undefined) => (
    <Icon ref={ref} name='mail' svg={svg} {...props} />
  )
);

Mail.displayName = 'MailIcon';

export default Mail;
