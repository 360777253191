/** @jsxImportSource react */
import React from 'react';

function OklahomaDisclosure() {
  return (
    <div>
      We are in the business of referring residents to assisted living
      facilities and assisted living homes. We will be paid by the facility or
      home if you move into one of the referred facilities or homes. The fee we
      receive from the facility or home into which you move typically ranges
      from 20 to 100 percent of your first month&apos;s rent and care charges.
      We do have a current business relationship but we do not have a common
      ownership or control in, or any other financial, business, management or
      familial relationship with, any of the homes and facilities to which we
      are referring you.{' '}
      <div>
        By providing us with a written or electronic notice, you have the right
        to terminate our services to you at any time, including our use of your
        personal information. If you terminate our services, we will not be
        entitled to any fee for any move-in you make after the date of the
        termination notice unless either:{' '}
      </div>
      <div>
        1) The facility or home you choose within the next twelve months is one
        that we specifically identify and refer to you after we evaluate your
        profile and requests but before we receive your notice of termination.
      </div>{' '}
      <div>
        2) You communicate with us before you move into the facility or home.
      </div>
    </div>
  );
}

export default OklahomaDisclosure;
