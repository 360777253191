import { hasSession, useApi } from '@react/services/api';
import AuthContext from '@react/services/auth/AuthContext';
import { useEvents } from '@react/services/events';
import get from 'lodash/get';
import pick from 'lodash/pick';
import set from 'lodash/set';
import { stringify } from 'query-string';
import { useCallback, useContext, useMemo } from 'react';
import { isServer } from 'sly/config';

import useUser from './useUser';

// returns null on server and when there is no response from the user/me resource yet
// false when there is no session or user is not authorised and true when logged in
const isLoggedIn = (userInfo) => {
  if (isServer) {
    return null;
  }

  if (!hasSession()) {
    return false;
  }

  if (!userInfo.hasFinished) {
    return null;
  }

  return userInfo.status === 200;
};

export const useAuth = () => {
  const {
    apiClient: { store, api },
  } = useApi();
  const {
    user,
    info: { user: userInfo },
    fetchUser,
    invalidateUser,
  } = useUser();

  const authContext = useContext(AuthContext);

  const { events } = useEvents();

  const identify = (response) => {
    const { id, attributes } = response.body.data;
    events.identify(id, attributes);
    return response;
  };

  const userApiMethods = useMemo(
    () =>
      [
        'registerUser',
        'updateUser',
        'loginUser',
        'logoutUser',
        'recoverPassword',
        'resetPassword',
        'setPassword',
        'updatePassword',
        'thirdPartyLogin',
        'resendOtpCode',
        'otpLoginUser',
        'sendOtpCode',
        'magicLink',
        'invoicedMagicLink',
        'authStart',
      ].reduce((acc, method) => {
        acc[method] = (...args) =>
          store.dispatch(api[method].asAction(...args));
        return acc;
      }, {}),
    [api, store]
  );

  const registerUser = useCallback((options = {}) => {
    const { ignoreExisting, ...data } = options;

    return userApiMethods
      .registerUser(data)
      .catch((e) => {
        const alreadyExists = e.status && e.status === 409;
        if (ignoreExisting && alreadyExists) {
          return Promise.resolve();
        }
        return Promise.reject(e);
      })
      .then(fetchUser)
      .then(identify);
  }, []);

  const createOrUpdateUser = useCallback(
    (data, { ignoreAlreadyRegistered } = {}) => {
      const { name, phone, email } = data;

      if (!phone && !email) return Promise.resolve();

      if (!user) {
        return registerUser({
          name,
          email,
          phone_number: phone,
        }).catch((e) => {
          const alreadyExists = e.status && e.status === 409;
          if (ignoreAlreadyRegistered && alreadyExists) {
            return Promise.resolve({ alreadyExists });
          }
          return Promise.reject(e);
        });
      }

      const userData = pick(userInfo.result, [
        'id',
        'type',
        'attributes.name',
        'attributes.phone',
        'attributes.email',
      ]);

      const willUpdate = Object.entries({
        'attributes.name': name,
        'attributes.phoneNumber': phone,
        'attributes.email': email,
      }).reduce((willUpdate, [path, newValue]) => {
        if (newValue && newValue !== get(userData, path)) {
          set(userData, path, newValue);
          return true;
        }
        return willUpdate;
      }, false);

      if (willUpdate) {
        return userApiMethods.updateUser({ id: userData.id }, userData);
      }

      return Promise.resolve();
    },
    [user, userInfo]
  );

  const loginUser = useCallback((data) => {
    return userApiMethods.loginUser(data).then(fetchUser).then(identify);
  }, []);

  const logoutUser = useCallback((data) => {
    return userApiMethods.logoutUser(data).then(fetchUser);
  }, []);

  const recoverPassword = useCallback((data) => {
    return userApiMethods.recoverPassword(data);
  }, []);

  const resetPassword = useCallback((data) => {
    return userApiMethods.resetPassword(data);
  }, []);

  const setPassword = useCallback((data) => {
    return userApiMethods.setPassword(data).then(fetchUser);
  }, []);

  const updatePassword = useCallback((data) => {
    return userApiMethods.updatePassword(data).then(fetchUser);
  }, []);

  const thirdPartyLogin = useCallback((data) => {
    return userApiMethods.thirdPartyLogin(data).then(fetchUser).then(identify);
  }, []);

  const resendOtpCode = useCallback((data) => {
    return userApiMethods.resendOtpCode(data);
  }, []);

  const otpLoginUser = useCallback((data) => {
    return userApiMethods.otpLoginUser(data).then(fetchUser).then(identify);
  }, []);

  const sendOtpCode = useCallback((data) => {
    return userApiMethods.sendOtpCode(data);
  }, []);

  const magicLink = useCallback((data) => {
    return userApiMethods.magicLink(data);
  }, []);

  const authStart = useCallback((data) => {
    return userApiMethods.authStart(data);
  }, []);

  const invoicedMagicLink = useCallback((data) => {
    return userApiMethods.invoicedMagicLink(data);
  }, []);

  const redirectAuth = useCallback((query) => {
    const search = stringify({
      loginRedirect: window.location.toString(),
      ...query,
    });
    window.location = `/?${search}`;
  }, []);

  const redirectLogIn = useCallback(
    (context = {}) =>
      redirectAuth({
        ...context,
      }),
    []
  );

  const redirectSignUp = useCallback(
    (context = {}) =>
      redirectAuth({
        register: true,
        ...context,
      }),
    []
  );

  return {
    isLoggedIn: isLoggedIn(userInfo),
    redirectLogIn,
    redirectSignUp,
    user,
    userInfo,
    fetchUser,
    invalidateUser,
    createOrUpdateUser,
    loginUser,
    logoutUser,
    registerUser,
    setPassword,
    updatePassword,
    recoverPassword,
    resetPassword,
    thirdPartyLogin,
    resendOtpCode,
    otpLoginUser,
    sendOtpCode,
    magicLink,
    invoicedMagicLink,
    authStart,
    ...authContext,
  };
};

export default useAuth;
