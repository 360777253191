import React, { forwardRef, Ref } from 'react';
import { SimpleIconProps } from '../icon.types';
import Icon from './Icon';

const svg = `<svg viewBox="0 0 24 24" fill="currentColor">
  <path d="M10.95 12.6342L9.54184 11.2451C9.34656 11.0498 9.10444 10.9521 8.81549 10.9521C8.52654 10.9521 8.27807 11.0561 8.07009 11.2641C7.87481 11.4594 7.77717 11.7047 7.77717 12C7.77717 12.2953 7.87481 12.5405 8.07009 12.7358L10.1484 14.8141C10.3763 15.042 10.6435 15.1559 10.95 15.1559C11.2565 15.1559 11.5237 15.042 11.7516 14.8141L15.9299 10.6358C16.1379 10.4279 16.2387 10.1826 16.2323 9.89997C16.226 9.61735 16.1252 9.37206 15.9299 9.16409C15.7219 8.95613 15.4734 8.84897 15.1845 8.84262C14.8955 8.83629 14.6471 8.9371 14.4391 9.14507L10.95 12.6342ZM8.00652 21.8994L6.57444 19.4733L3.85434 18.8793C3.57246 18.8214 3.34555 18.6743 3.17362 18.438C3.0017 18.2018 2.93241 17.9407 2.96574 17.6549L3.24074 14.8657L1.38967 12.7396C1.19908 12.5284 1.10379 12.2819 1.10379 12C1.10379 11.7181 1.19908 11.4715 1.38967 11.2603L3.24074 9.13422L2.96574 6.34507C2.93241 6.0592 3.0017 5.79815 3.17362 5.56192C3.34555 5.32569 3.57246 5.17859 3.85434 5.12062L6.57444 4.52659L8.00652 2.10052C8.15578 1.85595 8.36402 1.69019 8.63124 1.60322C8.89846 1.51627 9.16467 1.52877 9.42989 1.64072L12 2.72877L14.5701 1.64072C14.8353 1.52877 15.1015 1.51627 15.3687 1.60322C15.636 1.69019 15.8442 1.85595 15.9935 2.10052L17.4255 4.52659L20.1456 5.12062C20.4275 5.17859 20.6544 5.32569 20.8264 5.56192C20.9983 5.79815 21.0676 6.0592 21.0342 6.34507L20.7592 9.13422L22.6103 11.2603C22.8009 11.4715 22.8962 11.7181 22.8962 12C22.8962 12.2819 22.8009 12.5284 22.6103 12.7396L20.7592 14.8657L21.0342 17.6549C21.0676 17.9407 20.9983 18.2018 20.8264 18.438C20.6544 18.6743 20.4275 18.8214 20.1456 18.8793L17.4255 19.4733L15.9935 21.8994C15.8442 22.144 15.636 22.3098 15.3687 22.3967C15.1015 22.4837 14.8353 22.4712 14.5701 22.3592L12 21.2712L9.42989 22.3592C9.16467 22.4712 8.89846 22.4837 8.63124 22.3967C8.36402 22.3098 8.15578 22.144 8.00652 21.8994Z"/>
</svg>`;

const Verified = forwardRef(
  (props: SimpleIconProps, ref: Ref<SVGSVGElement> | undefined) => (
    <Icon ref={ref} name='verified' svg={svg} {...props} />
  )
);

Verified.displayName = 'VerifiedIcon';

export default Verified;
