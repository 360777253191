import {
  isDatePicker,
  isMultiSelect,
  isSingleSelect,
  isTextInput,
} from '@react/chat/src/components/Widget/msgProcessor';
import { formatMoney } from '@sly/core/helpers/numbers';

export const getLabelFromValue = (options, value) => {
  const option = options.find((option) => option.value === value);
  return option ? option.label : '';
};

export const getLabelsFromValues = (options, values) => {
  const labels = values.map((value) => {
    const option = options.find((option) => option.value === value);
    return option ? option.label : '';
  });
  return labels;
};

export const getFormattedMessageText = (
  incomingInputNode,
  incomingNodeValue
) => {
  let messageText = '';

  const payload = incomingInputNode.attachment.payload;

  if (isTextInput(incomingInputNode)) {
    messageText = incomingNodeValue;
  } else if (isSingleSelect(incomingInputNode)) {
    messageText = getLabelFromValue(payload.options, incomingNodeValue);
  } else if (isMultiSelect(incomingInputNode)) {
    messageText = getLabelsFromValues(payload.options, incomingNodeValue).join(
      ', '
    );
  } else if (isDatePicker(incomingInputNode)) {
    messageText = incomingNodeValue?.join(', ');
  }

  return messageText;
};

export const getPricingStringFromCommunityData = (community) => {
  if (!community) return '';
  if (community?.startingRate <= 0) return '';
  const {
    estimatedPricingOverride,
    rates,
    startingRate,
    maxRate,
    hidePricing,
    canShowPricingByCare,
  } = community || {};

  if (!canShowPricingByCare) {
    return '';
  }

  const estimated = rates !== 'Provided';
  const showEstimated = estimated || estimatedPricingOverride;

  const startPrice = hidePricing ? '$X,XXX' : formatMoney(startingRate);
  const maxPrice =
    !!maxRate &&
    (!showEstimated && hidePricing ? '- $X,XXX' : ` - ${formatMoney(maxRate)}`);
  let title = '';

  if (!maxRate) {
    title = ` The ${
      showEstimated ? 'estimated' : ''
    } price for this community starts at`;
  }

  if (maxRate) {
    title = ` The ${
      showEstimated ? 'estimated' : ''
    } price range for this community starts at`;
  }

  if (!title) {
    return '';
  }

  const pricingString = `${title} ${startPrice} ${maxPrice ? maxPrice : ''}`;

  return pricingString;
};

export const getUtterancesDataFromCommunityData = (community) => {
  const pricingString = getPricingStringFromCommunityData(community);
  return {
    estimatedPrices: pricingString,
  };
};

export const getAgentName = (partnerAgent) => {
  if (!partnerAgent) {
    return '';
  }

  const { info, name } = partnerAgent;
  const { displayName } = info || {};

  const agentName = displayName || name;

  return agentName || '';
};

export const getAgentNameWithFallback = (
  partnerAgent,
  fallback = 'a Seniorly advisor'
) => {
  const agentName = getAgentName(partnerAgent);
  return agentName ? agentName : fallback;
};

export const getPricingRequestConfirmationMessage = (community) => {
  const { partnerAgent } = community || {};
  const agentName = getAgentNameWithFallback(partnerAgent);
  return `I’ve let ${agentName} know about your ${
    community ? 'pricing' : 'consultation'
  } request and they will reach out shortly.`;
};

export const getTourRequestConfirmationMessage = (community) => {
  const { partnerAgent } = community || {};
  const agentName = getAgentNameWithFallback(partnerAgent);
  return `I’ve let ${agentName} know about your tour request and they will reach out shortly.`;
};

export const getFallbackConfirmation = (community) => {
  const { partnerAgent } = community || {};
  const agentName = getAgentNameWithFallback(partnerAgent);
  return `I’ve let ${agentName} know about your question and they will reach out shortly.`;
};

export const allReplies = [
  {
    content_type: 'text',
    title: 'Nearby Amenities',
    payload: 'What are some nearby amenities?',
  },
  {
    content_type: 'text',
    title: 'Request a tour',
    payload: '/request_tour',
  },
  {
    content_type: 'text',
    title: 'Pricing',
    payload: '/inquire_community_pricing',
  },
  {
    content_type: 'text',
    title: 'Summary of the community',
    payload: 'Can you summarize everything about this community?',
  },
  {
    content_type: 'text',
    title: 'Location',
    payload:
      'Could you provide information about the neighboring area of this community?',
  },
  {
    content_type: 'text',
    title: 'Care',
    payload: 'What type of care does this community provide?',
  },
  {
    content_type: 'text',
    title: 'Reviews',
    payload: 'Can you give me a summary of review of this community?',
  },
  {
    content_type: 'text',
    title: 'Community Amenities',
    payload: 'What are the amenities for this community?',
  },
  {
    content_type: 'text',
    title: 'Neighborhood',
    payload: 'What are some features of the neighborhood?',
  },
];
