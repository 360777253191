import React, { forwardRef, Ref } from 'react';
import { SimpleIconProps } from '../icon.types';
import Icon from './Icon';

const svg = `<svg viewBox="0 0 24 24" fill="currentColor">
  <path d="M12 13.5913L7.19565 18.3957C6.98441 18.6069 6.7192 18.7125 6.4 18.7125C6.0808 18.7125 5.81558 18.6069 5.60435 18.3957C5.39312 18.1844 5.2875 17.9192 5.2875 17.6C5.2875 17.2808 5.39312 17.0156 5.60435 16.8044L10.4087 12L5.60435 7.19566C5.39312 6.98442 5.2875 6.71921 5.2875 6.40001C5.2875 6.08081 5.39312 5.81559 5.60435 5.60436C5.81558 5.39312 6.0808 5.28751 6.4 5.28751C6.7192 5.28751 6.98441 5.39312 7.19565 5.60436L12 10.4087L16.8043 5.60436C17.0156 5.39312 17.2808 5.28751 17.6 5.28751C17.9192 5.28751 18.1844 5.39312 18.3956 5.60436C18.6069 5.81559 18.7125 6.08081 18.7125 6.40001C18.7125 6.71921 18.6069 6.98442 18.3956 7.19566L13.5913 12L18.3956 16.8044C18.6069 17.0156 18.7125 17.2808 18.7125 17.6C18.7125 17.9192 18.6069 18.1844 18.3956 18.3957C18.1844 18.6069 17.9192 18.7125 17.6 18.7125C17.2808 18.7125 17.0156 18.6069 16.8043 18.3957L12 13.5913Z"/>
</svg>`;

const Close = forwardRef(
  (props: SimpleIconProps, ref: Ref<SVGSVGElement> | undefined) => (
    <Icon ref={ref} name='close' svg={svg} {...props} />
  )
);

Close.displayName = 'CloseIcon';

export default Close;
