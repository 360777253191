/** @jsxImportSource react */

import { normJsonApi } from '@common/helpers/jsonApi';
import { removeQueryParamNext } from '@common/helpers/url';
import {
  AGENTSIGNUP,
  INFOPROMPT,
  LOGINSIGNUP,
  MAGICLINKEXPIRED,
  noAutoLoginStatuses,
  PROIVDERSIGNUP,
  RESOURCE_EXPIRED_STATUS,
} from '@sly/core/constants/auth';
import {
  AGENT,
  CUSTOMER,
  LOGGINGIN,
  PROVIDER,
  REGISTER,
} from '@sly/core/constants/auth';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

import AuthContext from './AuthContext';
import useUser from './hooks/useUser';
import { useQuery } from '../api';

type Role = typeof AGENT | typeof CUSTOMER | typeof PROVIDER;
type Action = typeof REGISTER | typeof LOGGINGIN;

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [authenticated, setIsAuthenticating] = useState<
    | {
        action: Action;
        role: Role;
        data: {
          prop: string;
          community: {
            value: string;
            label: string;
          };
        };
        callback: () => void;
      }
    | Record<string, any>
  >({});
  const [redirectStatus, setRedirectStatus] = useState<string>('');
  const [redirectAfterLogin, setRedirectAfterLogin] = useState<string>('');

  const { user } = useUser();
  const router = useRouter();
  const getCommunity = useQuery('getCommunity');
  const { query } = router;

  const loggingIn = authenticated?.action === LOGGINGIN;
  const agent = authenticated?.role === AGENT;
  const provider = authenticated?.role === PROVIDER;
  const customer = authenticated?.role === CUSTOMER;

  const ensureAuthenticated = (
    callback: () => void,
    role: Role = CUSTOMER,
    action: Action = REGISTER,
    data: Record<string, string> = {}
  ) => {
    if (user && callback) {
      return callback();
    }
    setIsAuthenticating({
      role,
      action,
      data: { ...authenticated?.data, ...data },
      callback,
    });
  };

  const authenticatedCancel = () => {
    setIsAuthenticating({});
  };

  // If redirect is set then redirect to the url
  const authenticatedSuccess = () => {
    if (
      redirectAfterLogin &&
      redirectStatus &&
      !(authenticated?.action === LOGGINGIN)
    ) {
      location.href = redirectAfterLogin;
      router.push(redirectAfterLogin);
    }
    setRedirectStatus('');
    setRedirectAfterLogin('');

    authenticated?.callback?.();
    setIsAuthenticating({});
  };

  useEffect(() => {
    if (query?.loginRedirect) {
      setRedirectAfterLogin(
        (!Array.isArray(query?.loginRedirect) && query?.loginRedirect) || ''
      );
      setRedirectStatus((!Array.isArray(query?.status) && query?.status) || '');
      removeQueryParamNext(router, 'status', 'loginRedirect');
    }

    if (query?.prop && !Array.isArray(query?.prop)) {
      getCommunity({ id: query?.prop })
        .then(normJsonApi)
        .then((community: any) => {
          setIsAuthenticating({
            ...authenticated,
            data: {
              prop: query?.prop,
              community: {
                label: `${community?.name}: ${community?.address?.city}, ${community?.address?.state}`,
                value: community?.id,
              },
            },
          });
        });

      removeQueryParamNext(router, 'prop');
    }

    if (query?.provider === 'true') {
      setIsAuthenticating({
        ...authenticated,
        role: PROVIDER,
      });
      removeQueryParamNext(router, 'provider');
    }
  }, [query?.loginRedirect, query?.status, query?.prop, query?.provider]);

  useEffect(() => {
    if (
      !loggingIn &&
      redirectAfterLogin &&
      !noAutoLoginStatuses.includes(redirectStatus)
    ) {
      ensureAuthenticated(
        () => {
          router.push(redirectAfterLogin);
        },
        authenticated.role,
        authenticated.action,
        authenticated.data
      );
    }
  }, [loggingIn, redirectAfterLogin, redirectStatus]);

  const getInitialStep = (initialStep = LOGINSIGNUP) => {
    if (agent) {
      initialStep = AGENTSIGNUP;
    } else if (provider) {
      initialStep = PROIVDERSIGNUP;
    } else if (
      redirectAfterLogin &&
      (redirectStatus === 'expired' ||
        redirectStatus === 'error' ||
        redirectStatus === RESOURCE_EXPIRED_STATUS)
    ) {
      initialStep = MAGICLINKEXPIRED;
    } else if (redirectAfterLogin && redirectStatus === 'invite') {
      initialStep = INFOPROMPT;
    }

    return initialStep;
  };

  const context = {
    ensureAuthenticated,
    authenticatedCancel,
    authenticatedSuccess,
    provider,
    customer,
    agent,
    register: authenticated?.action === REGISTER,
    // Moved up because it's used in the useEffect
    loggingIn,
    shouldAuthenticate: !!authenticated?.action,
    authenticating: !!authenticated?.action,
    data: authenticated?.data,
    redirectAfterLogin,
    redirectStatus,
    getInitialStep,
  };

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
