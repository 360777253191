/** @jsxImportSource react */
import { GwizzyCommunity } from '@common/helpers/transformGwizzyCommunity';
import clsxm from '@common/lib/clsxm';
import { Community } from '@common/types';
import { Button } from '@react/system';
import { Agent } from '@sly/core/src/types';
import { Checkmark } from '@sly/icons/react';

interface ListItem {
  title: string;
  description: string;
}

// convert to typescript
export default function D2CConfirmation({
  handleConfirmation,
  referredCommunities,
  isChatMode = false,
  referredAgent,
  convertedCommunity,
  eventProps = {},
}: {
  handleConfirmation?: () => void;
  referredCommunities: Community[];
  isChatMode?: boolean;
  referredAgent?: Agent;
  convertedCommunity?:
    | { name: string; isAutoReferable: boolean }
    | GwizzyCommunity
    | null;
  eventProps?: Record<string, any>;
}) {
  const listItems: ListItem[] = [];

  if (
    referredAgent &&
    convertedCommunity?.name &&
    !convertedCommunity?.isAutoReferable
  ) {
    listItems.push({
      title: convertedCommunity?.name,
      description: `Your advisor ${referredAgent?.name} will help with this
      community`,
    });
  }

  referredCommunities?.forEach((community) => {
    const isConvertedCommunityIncluded =
      convertedCommunity?.name === community.name;

    listItems.push({
      title: community.name,
      description:
        isConvertedCommunityIncluded && referredAgent
          ? `Your advisor ${referredAgent?.name} will help with this community`
          : '',
    });
  });

  return (
    <>
      <div
        className={clsxm(
          'bg-white py-6 ',
          isChatMode ? 'px-2' : 'px-4 md:px-8'
        )}
      >
        <div className='flex h-full min-h-[300px] max-w-full flex-col justify-center space-y-8'>
          {!!listItems?.length && (
            <div className='space-y-4'>
              <Checkmark
                size='xl'
                className='rounded-full  bg-green-lighter-90  p-[6px] text-green-base'
              />

              <div className='space-y-6'>
                <h3 className='font-t-m'>
                  Requests sent. You’ll receive calls from the following{' '}
                  {listItems.length > 1 ? 'communities:' : 'community:'}
                </h3>

                <ul className='space-y-2'>
                  {listItems?.map((item) => (
                    <li className='flex gap-4' key={item.title}>
                      <Checkmark className='text-green-base' />
                      <div>
                        <div>{item.title}</div>
                        {!!item?.description && (
                          <div className='font-b-s mt-1 text-slate-lighter-40'>
                            {item.description}
                          </div>
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}

          {handleConfirmation && (
            <Button
              onClick={handleConfirmation}
              className='w-full '
              eventProps={eventProps}
            >
              Continue
            </Button>
          )}
        </div>
      </div>
    </>
  );
}
