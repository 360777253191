import { useContext } from 'react';

import { ToastContext, ToastContextType } from './ToastProvider';

export const useToast = (): ToastContextType => {
  const toastContext = useContext(ToastContext);
  if (!toastContext) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return toastContext;
};
