import { propInfo, webViewInfo } from '@react/propTypes/community';
import { array, number, shape, string } from 'proptypes';

export const propRatings = shape({
  numReviews: number,
  reviewsValue: number,
});

export const entity = shape({
  id: string,
  name: string.isRequired,
  url: string.isRequired,
  floorPlanString: string,
  numRevies: number,
  reviewsValue: number,
  addressString: string,
  firstLine: string,
  latitude: number,
  longitude: number,
  maxRate: number,
  resourceType: string,
  secondLine: string,
  startingRate: number,
  tags: array,
  thirdLine: string,
  propInfo,
  webViewInfo,
  propRatings,
  mainImage: string,
});

export default entity;
