/** @jsxImportSource react */
import { tagVariants } from '@common/cva/tagVariants';
import { cn } from '@common/lib/clsxm';
import { type VariantProps } from 'class-variance-authority';
import * as React from 'react';

export interface TagProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof tagVariants> {}

function Tag({ className, variant, ...props }: TagProps) {
  return <div className={cn(tagVariants({ variant }), className)} {...props} />;
}

export { Tag, tagVariants };
