import cx, { Argument } from 'classnames';
import { ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

/** Merge classes with tailwind-merge with clsx full feature */
export default function clsxm(...classes: Argument[]) {
  return cx(...classes);
}

export { cx };

const breakpoints = ['', 'md', 'lg'];

export const sx = (...classes: string[]) =>
  cx(
    ...classes.reduce((acc: string[], clx: string, i: number) => {
      const chunks = clx
        .split(/\s+/g)
        .map(x => `${i ? `${breakpoints[i]}:` : ''}${x}`);
      acc.push(...chunks);
      return acc;
    }, [])
  );

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
