/** @jsxImportSource react */
import Checkbox from '@react/shared/components/Checkbox';
import { arrayOf, bool, func, object, oneOfType, string } from 'proptypes';
import React from 'react';

const ToggleButton = ({
  value = [],
  onChange,
  type,
  readOnly,
  eventProps,
  name,
  label,
  ...props
}) => {
  const handleCheckboxItemOnClick = () => {
    if (readOnly) {
      return null;
    }
    return onChange(!value);
  };

  const selectedClasses = value
    ? 'bg-viridian-base text-white'
    : 'bg-white text-slate-lighter-40';

  return (
    <div
      onClick={() => handleCheckboxItemOnClick()}
      {...props}
      className='flex-1'
      data-tp-id='gen-div-3fcdebca-24f3-42ec-8afc-cab8f93bafdf'
    >
      <Checkbox
        readOnly={readOnly}
        checked={!!value}
        className='absolute hidden'
      />

      <label
        className={`rounded border-[1px] border-[#E7E8EA] py-2 px-3 uppercase hover:border-viridian-base ${selectedClasses}`}
      >
        {label}
      </label>
    </div>
  );
};

ToggleButton.propTypes = {
  value: oneOfType([bool, arrayOf(string)]),
  onChange: func,
  readOnly: bool,
  type: string,
  label: string,
  eventProps: object,
  name: string,
};

export default ToggleButton;
