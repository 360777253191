/** @jsxImportSource react */
import cx from 'classnames';
import {
  createContext,
  CSSProperties,
  forwardRef,
  useContext,
  useEffect,
} from 'react';
import config from 'sly/config';

const { isServer } = config;

interface IconContext {
  [index: string]: {
    svg: string;
    instances: number;
  };
}

export const IconContext = createContext<IconContext>({});

/**
 * Used in Html.js to render the icons ssr
 *
 * @param {Array<object>} icons
 */
export const iconToComponent = (iconContext: IconContext) => {
  if (!iconContext) return null;
  const content = Object.entries(iconContext).map(([name, { svg }]) =>
    svg.replace('<svg ', `<svg id="sly-svg-${name}" `)
  );
  return (
    <svg data-sly-svgs='true'>
      <defs dangerouslySetInnerHTML={{ __html: content.join('\n') }} />
    </svg>
  );
};

const updateIcons = (iconContext: IconContext) => {
  // this is how react-helmet does it

  const defs = document.querySelector('svg[data-sly-svgs] defs');

  if (!defs) {
    return;
  }

  const svgs = defs.querySelectorAll(':scope > svg');
  const oldSvgs = Array.prototype.slice.call(svgs);
  let indexToKeep = 0;

  Object.entries(iconContext).forEach(([name, { svg }]) => {
    const id = `sly-svg-${name}`;
    // Remove a duplicate tag from domTagstoRemove, so it isn't cleared.
    if (
      oldSvgs.some((existingTag, index) => {
        indexToKeep = index;
        return existingTag.id === id;
      })
    ) {
      oldSvgs.splice(indexToKeep, 1);
    } else {
      const newSvg = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'svg'
      );
      newSvg.id = id;
      newSvg.innerHTML = svg;
      defs.appendChild(newSvg);
    }
  });
  oldSvgs.forEach((svg) => svg.parentNode.removeChild(svg));
};

let animationFrameId: number | null = null;
const handleClientRender = (iconContext: IconContext) => {
  if (animationFrameId) {
    cancelAnimationFrame(animationFrameId);
  }

  animationFrameId = requestAnimationFrame(() => {
    updateIcons(iconContext);
    animationFrameId = null;
  });
};

const useIconEffect = (name: string, svg: string) => {
  const iconContext = useContext(IconContext) || {};
  // browser
  useEffect(() => {
    iconContext[name] = iconContext[name] || {
      svg,
      instances: 0,
    };
    iconContext[name].instances++;
    handleClientRender(iconContext);

    return () => {
      iconContext[name].instances--;
      if (iconContext[name].instances === 0) {
        delete iconContext[name];
      }
      handleClientRender(iconContext);
    };
  }, [iconContext, name, svg]);

  if (isServer) {
    iconContext[name] = iconContext[name] || {
      svg,
      instances: 0,
    };
    iconContext[name].instances++;
  }
};

interface IconClasses {
  size?: string;
  color?: string;
  hoverColor?: string;
  active?: boolean;
}

const getIconClasses = ({
  size = 'm',
  color,
  hoverColor,
  active,
  ...rest
}: IconClasses) => {
  const classes = ['align-top'];

  if (color) {
    classes.push(color);
  }

  if (hoverColor) {
    !active ? classes.push(hoverColor) : `hover:text-transparent`;
  }

  switch (size) {
    case 'xs':
      classes.push('min-w-[16px] min-h-[16px] w-[16px] h-[16px]');
      break;
    case 's':
      classes.push('min-w-[20px] min-h-[20px] w-[20px] h-[20px]');
      break;
    case 'm':
      classes.push('min-w-[24px] min-h-[24px] w-[24px] h-[24px]');
      break;
    case 'l':
      classes.push('min-w-[32px] min-h-[32px] w-[32px] h-[32px]');
      break;
    case 'xl':
      classes.push('min-w-[40px] min-h-[40px] w-[40px] h-[40px]');
      break;
    case 'xxl':
      classes.push('min-w-[48px] min-h-[48px] w-[48px] h-[48px]');
      break;
  }

  return [classes, rest];
};

export interface SimpleIconProps extends IconClasses {
  className?: string;
  hoverColor?: string;
  active?: boolean;
  rotation?: string | number;
  style?: CSSProperties;
}

interface IconProps extends SimpleIconProps {
  svg: string;
  name: string;
}

interface svgStates extends CSSProperties {
  '--rotation'?: string;
  '--active-color': string;
  '--hover-color': string;
}

const Icon = forwardRef<SVGSVGElement, IconProps>(
  (
    { svg, name, className, active, rotation, hoverColor, ...props }: IconProps,
    ref
  ) => {
    useIconEffect(name, svg);

    const svgStates: svgStates = {
      '--active-color': '',
      '--hover-color': 'transparent',
    };
    if (rotation) {
      svgStates['--rotation'] = `${rotation}deg`;
    }

    svgStates['--active-color'] = active ? 'currentColor' : 'transparent';

    if (hoverColor) {
      svgStates['--hover-color'] = 'transparent';
      // to-do: can't use :hover in inline style try creating utility with tailwind
      // svgStates[':hover'] = {
      //   '--hover-color': active ? 'transparent' : hoverColor,
      // };
    }

    const [iconClasses, rest] = getIconClasses({ active, ...props });

    return (
      <svg
        ref={ref}
        className={cx(className, iconClasses)}
        style={svgStates}
        {...rest}
      >
        <use href={`#sly-svg-${name}`} />
      </svg>
    );
  }
);

Icon.displayName = 'Icon';

Icon.defaultProps = {
  size: 'm',
};

export default Icon;
