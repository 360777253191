/** @jsxImportSource react */
import { BaseImage } from '@react/system';
import cx from 'classnames';
import { string } from 'proptypes';
import React from 'react';

const getSize = (size) => {
  switch (size) {
    case 'm':
      return 'h-10 w-10 !text-lg';
    case 'l':
      return 'h-12 w-12 !text-lg';
    default:
      return 'h-20 w-20';
  }
};

const Avatar = ({ path, name, size = '', ...props }) =>
  path ? (
    <BaseImage
      aspectRatio='1:1'
      layout='responsive'
      objectFit='cover'
      className={cx(getSize(size), 'rounded-full')}
      path={path}
      alt={name}
      {...props}
      data-tp-id='gen-Image-02aa8716-1179-4f77-a8eb-a84612697ff1'
    />
  ) : (
    <div
      className={cx(
        getSize(size),
        'text-l flex items-center justify-center rounded-full bg-slate-lighter-60 font-medium uppercase text-slate-darker-20'
      )}
      data-title={name}
      data-tp-id='gen-div-efa5c615-0b8f-4629-b70b-e62c147d2884'
    >
      {name
        .split(' ')
        .map((n) => n[0])
        .join('')
        .slice(0, 2)}
    </div>
  );

Avatar.propTypes = {
  path: string,
  name: string.isRequired,
};

export default Avatar;
