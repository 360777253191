import { ACTIONS as actions } from '@react/gwizzy/constants';
import { validateFormFields } from '@react/gwizzy/helpers/validateFormFields';
import { eventNames } from '@react/services/events';
import { delay } from '@react/shared/helpers';
import {
  CONSULTATION_REQUESTED,
  PRICING_REQUEST,
  PROFILE_CONTACTED,
} from '@sly/core/constants/actionTypes';
import { parseConversionUUIDActionData } from '@sly/frontend/react/gwizzy/helpers/parseConversionUUIDActionData';
import get from 'lodash/get';
import { isBrowser } from 'sly/config';

const getSubmitValues = ({
  values,
  community,
  eventProps,
  mode,
  communitySlugs,
}) => {
  const { firstName, lastName, phone, email } = values;

  let name = '';
  if (firstName) {
    name += `${firstName} `;
  }
  if (lastName) {
    name += lastName;
  }

  const payload = parseConversionUUIDActionData(
    values,
    community?.startingRate,
    community?.pricesByCareType
  );

  const actionType = community ? PROFILE_CONTACTED : CONSULTATION_REQUESTED;

  const modeInfo = {};

  if (eventProps?.entry && actionType === PROFILE_CONTACTED) {
    modeInfo.entry = eventProps.entry;
  } else if (mode && mode?.entry) {
    modeInfo.entry = mode.entry;
  }
  if (eventProps?.text) {
    modeInfo.cta = eventProps.text || eventProps.cta;
  }

  if (mode?.leadSource) {
    modeInfo.leadSource = mode.leadSource;
  }

  if (mode?.channel) {
    modeInfo.channel = mode.channel;
  }

  if (mode?.medium) {
    modeInfo.medium = mode.medium;
  }
  if (mode?.pricingCareType) {
    modeInfo.pricingCareType = mode.pricingCareType;
  }
  if (mode?.pricingRoomType) {
    modeInfo.pricingRoomType = mode.pricingRoomType;
  }

  let communityActionInfo = {};
  if (community) {
    communityActionInfo = {
      contactType: PRICING_REQUEST,
    };

    if (values?.nextStep === 'none' && values?.similarCommunities?.length) {
      communityActionInfo.slugs = values.similarCommunities;
    } else {
      communityActionInfo.slug = community.id;
    }
  }
  if (communitySlugs?.length) {
    communityActionInfo['slugs'] = communitySlugs;
  }

  const uuidAction = {
    type: 'UUIDAction',
    attributes: {
      actionType,
      actionPage: isBrowser ? location.pathname : '',
      actionInfo: {
        data: {
          ...payload,
          ...modeInfo,
        },
        name,
        phone,
        email,
        ...communityActionInfo,
      },
    },
  };

  return {
    uuidAction,
    payload,
    name,
    phone,
    email,
    firstName,
    lastName,
  };
};

const setActivePage =
  ({
    events,
    applyFormValidation,
    eventProps,
    community,
    mode,
    createAction,
    auth,
  }) =>
  ({ dispatch, getState }) =>
  async ({
    page,
    values = {},
    step,
    action,
    asyncHandlerProps: { shouldSendUUIDAction, shouldCreateUser },
    validations,
    form,
  }) => {
    const {
      mutators: { setAsyncError, setValidating },
    } = form;
    const { stepsToIndexMap } = getState();
    const eventName =
      action === 'onNext'
        ? eventNames.WizardStepCompleted
        : eventNames.WizardStepPrevious;

    events.track(eventName, {
      location: 'gwizzy',
      step,
      ...eventProps,
      values,
    });

    if (validations) {
      const { isValid } = await validateFormFields({
        validateFn: applyFormValidation,
        validations,
        values,
        setAsyncError,
        setValidating,
      });

      if (!isValid) {
        return;
      }
    }

    if (values) {
      dispatch({ type: 'SET_VALUES', payload: values });
    }

    if (shouldSendUUIDAction) {
      dispatch({ type: 'SET_STATUS', payload: 'pending' });
      const { uuidAction, payload } = getSubmitValues({
        values,
        community,
        eventProps,
        mode,
      });

      events.track(eventNames.WizardSubmitted, {
        location: 'gwizzy',
        ...eventProps,
        ...payload,
      });

      try {
        await createAction(uuidAction);
        dispatch({ type: 'SET_STATUS', payload: 'started' });
      } catch (e) {
        dispatch({ type: 'SET_STATUS', payload: 'started' });
        console.error(e);
      }
    }

    if (shouldCreateUser) {
      const { createOrUpdateUser, user } = auth;
      if (!user) {
        dispatch({ type: 'SET_STATUS', payload: 'pending' });
        const { name, email, phone } = getSubmitValues({
          values,
          community,
          eventProps,
          mode,
        });
        try {
          await createOrUpdateUser({
            name,
            phone,
            email,
          });
          dispatch({ type: 'SET_STATUS', payload: 'started' });
        } catch (e) {
          console.error(e);
          if (e?.status !== 409) {
            dispatch({ type: 'SET_STATUS', payload: 'started' });
            return;
          }
        }
      }
    }
    dispatch({ type: 'SET_PAGE', payload: stepsToIndexMap?.[page] });
  };

const submitGwizzy =
  ({
    auth,
    events,
    createAction,
    community,
    ctaActions,
    applyFormValidation,
    eventProps,
    mode,
    enabledDisclosureState,
    communitySlugs,
  }) =>
  ({ dispatch }) =>
  async ({ values = {}, validations, form, step }) => {
    const { createOrUpdateUser, user } = auth;
    const {
      mutators: { setAsyncError, setValidating },
    } = form;

    if (validations) {
      const { isValid, data: validationData } = await validateFormFields({
        validateFn: applyFormValidation,
        validations,
        values,
        setAsyncError,
        setValidating,
      });

      if (!isValid) {
        return;
      }

      const phoneType = get(validationData, 'phone.value.carrier.type');
      const isMobile = phoneType === 'mobile';

      ctaActions?.setIsMobile?.(isMobile);
    }

    if (values) {
      dispatch({ type: 'SET_VALUES', payload: values });
    }

    dispatch({ type: 'SET_STATUS', payload: 'submitting' });

    const { uuidAction, payload, name, email, phone } = getSubmitValues({
      values,
      community,
      eventProps,
      mode,
      communitySlugs,
    });

    events.track(eventNames.WizardStepCompleted, {
      location: 'gwizzy',
      step,
      ...eventProps,
      ...payload,
    });

    events.track(eventNames.WizardSubmitted, {
      location: 'gwizzy',
      step,
      ...eventProps,
      ...payload,
    });

    let isSignUpSuccessful = true;

    if (!user) {
      try {
        await createOrUpdateUser({
          name,
          phone,
          email,
        });
      } catch (e) {
        console.error(e);
        isSignUpSuccessful = false;
        if (e?.status !== 409) {
          dispatch({ type: 'SET_STATUS', payload: 'error' });
          return;
        }
      }
    }

    try {
      await createAction(uuidAction);
      await delay(2000);
    } catch (e) {
      dispatch({ type: 'SET_STATUS', payload: 'error' });
      console.error(e);
    }

    if (!enabledDisclosureState) {
      dispatch({ type: 'SET_STATUS', payload: 'done' });
    }

    await delay(1000);
    await ctaActions.conversionDone(values, isSignUpSuccessful);
  };

export const gwizzyAsyncHandler = (props) => {
  return {
    [actions.setActivePage]: setActivePage(props),
    [actions.submit]: submitGwizzy(props),
  };
};
