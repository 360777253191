/** @jsxImportSource react */
import { useUser } from '@react/services/auth';
import hoistNonReactStatics from 'hoist-non-react-statics';
import get from 'lodash/get';
import { object } from 'proptypes';
import React, { useCallback, useMemo } from 'react';

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default function withUser({ force = false } = {}) {
  return (InnerComponent) => {
    const Wrapper = ({ status = {}, ...props }) => {
      const {
        user,
        uuidAux,
        fetchUser,
        invalidateUser,
        fetchUuidAux,
        invalidateUuidAux,
        info,
        getCurrentUser,
      } = useUser(force);

      const userHas = useCallback(
        (fields) => {
          if (!user) return false;
          return !fields.some((field) => !get(user, field, false));
        },
        [user]
      );

      const innerProps = useMemo(
        () => ({
          status: {
            ...status,
            uuidAux: {
              ...info.uuidAux,
              refetch: fetchUuidAux,
              invalidate: invalidateUuidAux,
            },
            user: {
              ...info.user,
              refetch: fetchUser,
              getCurrentUser,
              invalidate: invalidateUser,
            },
          },
          user,
          uuidAux,
          userHas,
        }),
        [user, uuidAux, userHas, ...Object.values(status)]
      );

      return <InnerComponent {...props} {...innerProps} />;
    };

    Wrapper.propTypes = {
      status: object,
    };

    Wrapper.displayName = `withUser(${getDisplayName(InnerComponent)})`;
    Wrapper.WrappedComponent =
      InnerComponent.WrappedComponent || InnerComponent;
    hoistNonReactStatics(Wrapper, InnerComponent);

    return Wrapper;
  };
}
