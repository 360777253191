/** @jsxImportSource react */
import Favorite from '@sly/icons/react/Favorite';
import { bool, func, number, string } from 'proptypes';
import React, { useEffect, useState } from 'react';

const RatingInput = ({
  value: formValue,
  defaultValue = 0,
  onChange,
  palette = 'text-yellow-base',
  disabled: isDisabled = false,
  tabIndex = 1,
  name = '',
  size = 'xl',
}) => {
  const getDefaultValue = () => {
    if (typeof formValue === 'number') {
      return formValue;
    }
    return defaultValue;
  };

  const [selectedValue, setSelectedValue] = useState(getDefaultValue());

  const [selectingValue, setSelecting] = useState(getDefaultValue());

  useEffect(() => {
    setSelectedValue(getDefaultValue());
    setSelecting(getDefaultValue());
  }, [formValue]);

  const onClick = () => {
    if (isDisabled) return;
    onChange(selectingValue);
    setSelectedValue(selectingValue);
  };

  const onMouseLeave = () => {
    if (isDisabled) return;
    setSelecting(selectedValue);
  };

  const onMouseMove = (index) => {
    if (isDisabled) return;
    setSelecting(index);
  };

  const stars = Array.from(Array(5), (_, i) => {
    const sindex = i + 1;

    const isSelected = sindex <= selectingValue;

    const render = isSelected ? (
      <Favorite size={size} className={palette} active />
    ) : (
      <Favorite size={size} className='text-slate-lighter-80' active={false} />
    );

    return { render, id: i };
  });

  function getStarProps(index, elementProps) {
    const i = index + 1;
    return {
      ...elementProps,
      onFocus: () => onMouseMove(i),
      onKeyPress: () => onClick(),
      onClick: () => onClick(),
      onMouseMove: () => onMouseMove(i),
    };
  }

  function getStarWrapperProps(elementProps) {
    return {
      ...elementProps,
      onMouseLeave: () => onMouseLeave(),
      onBlur: () => onMouseLeave(),
    };
  }

  return (
    <>
      <span
        data-testid='rating-wrapper'
        name={name}
        {...getStarWrapperProps()}
        className='cursor-pointer'
        data-tp-id='gen-span-f36ae765-9a86-4051-8604-219c2e79eb9b'
      >
        {stars?.map(({ render, id }, i) => (
          <span
            data-testid='rating-stars'
            key={id}
            tabIndex={selectedValue * tabIndex}
            value={selectedValue}
            {...getStarProps(i)}
            className='cursor-pointer'
            data-tp-id='gen-span-3f565bd2-d8f6-4409-a786-c05d5588c2f9'
          >
            {render}
          </span>
        ))}
      </span>
    </>
  );
};

RatingInput.propTypes = {
  id: string,
  value: number,
  defaultValue: number,
  disabled: bool,
  onChange: func,
  palette: string,
  tabIndex: number,
  name: string,
  size: string,
};

export default RatingInput;
