/** @jsxImportSource react */
import {
  setAsyncError,
  setSubmitting,
  setValidating,
} from '@react/form/mutators';
import ProgressBar from '@react/shared/components/ProgressBar';
import Spinner from '@react/shared/components/Spinner';
import { Button } from '@react/system';
import { WizardFormContext } from '@react/wizards/context/wizardContext';
import useFinalFormWizard from '@react/wizards/hooks/useFinalFormWizard';
import { CheckmarkCircle, Warning } from '@sly/icons/react';
import React from 'react';
import { Form } from 'react-final-form';

const FinalFormWizard = ({
  initialValues = {},
  children,
  onSubmit,
  initialStep = 0,
  asyncActionHandlers,
  localStorageKey,
  successMessage = 'Thank you! We will get back to you shortly.',
  isDefaultSubmitDisabled = false,
  isProgressBarVisible = true,
  className = '',
}) => {
  const wizard = useFinalFormWizard({
    initialValues,
    children,
    onSubmit,
    initialStep,
    asyncActionHandlers,
    localStorageKey,
  });
  const {
    state: { status },
    dispatch,
    page,
    totalSteps,
  } = wizard;

  const { values, validate, handleSubmit, activePage } = wizard;

  return (
    <>
      {(status === 'started' || status === 'pending') && (
        <Form
          initialValues={values}
          validate={validate}
          onSubmit={handleSubmit}
          mutators={{ setAsyncError, setSubmitting, setValidating }}
        >
          {(formProps) => {
            const { handleSubmit } = formProps;
            return (
              <>
                {isProgressBarVisible && (
                  <div className='sticky top-0 z-50'>
                    <ProgressBar maxValue={totalSteps - 1} value={page} />
                  </div>
                )}
                <WizardFormContext.Provider value={wizard}>
                  <form
                    className={className}
                    onSubmit={(e) => {
                      if (isDefaultSubmitDisabled) {
                        e.preventDefault();
                        return;
                      }
                      handleSubmit(e);
                    }}
                  >
                    {React.isValidElement(activePage)
                      ? React.cloneElement(activePage, { ...formProps })
                      : null}
                  </form>
                </WizardFormContext.Provider>
              </>
            );
          }}
        </Form>
      )}
      {status === 'error' && (
        <div className='flex h-full min-h-[300px] max-w-full flex-col items-center justify-center gap-3 rounded-md bg-white px-6 pb-6'>
          <Warning className='text-red-base' size='l' />
          <div>Oops! Something went wrong.</div>
          <Button
            onClick={() => dispatch({ type: 'SET_STATUS', payload: 'started' })}
            variant='secondary'
          >
            Try again
          </Button>
        </div>
      )}
      {status === 'submitting' && (
        <div className='flex h-full min-h-[300px] max-w-full flex-col items-center justify-center gap-3 rounded-md bg-white px-6 pb-6'>
          <Spinner />
          <div>Please wait while we submit your request</div>
        </div>
      )}
      {status === 'done' && (
        <div className='flex h-full min-h-[300px] max-w-full flex-col items-center justify-center gap-3 rounded-md bg-white px-6 pb-6'>
          <CheckmarkCircle className='text-green-base' size='xl' />
          <div>{successMessage}</div>
        </div>
      )}
    </>
  );
};

export default FinalFormWizard;
