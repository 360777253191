/** @jsxImportSource react */

import clsx from '@common/lib/clsxm';
import { Cancel, CheckmarkCircle, Close, Warning } from '@sly/icons/react';
import React, { useEffect } from 'react';

import { Toast, Truncate } from './ToastProvider';

const VARIANTS = {
  Info: {
    base: 'bg-slate-base text-white border-blue-500',
    iconstyle: 'text-blue-500 ',
    // Add exclamation icon here
    Icon: CheckmarkCircle,
    name: 'Info',
  },

  Error: {
    base: 'bg-slate-base text-white border-red-500 ',
    iconstyle: 'text-red-500 ',
    Icon: Cancel,
    name: 'Error',
  },

  Warning: {
    base: 'bg-slate-base text-white border-yellow-500',
    iconstyle: 'text-yellow-500 ',
    Icon: Warning,
    name: 'Warning',
  },

  Success: {
    base: 'bg-slate-base text-white border-green-500',
    iconstyle: 'text-green-500 ',
    Icon: CheckmarkCircle,
    name: 'Success',
  },
};

export type ToastMessage = {
  id: string;
  lifetime?: number;
  variant?: keyof typeof VARIANTS | undefined;
  onRemove?: (id: string) => void;
  truncate?: Truncate;
} & Toast;

export default function ToastMessage({
  id,
  header,
  message,
  lifetime,
  onRemove,
  truncate = 'line-clamp-2',
  Icon,
  type,
}: ToastMessage) {
  const Var = type
    ? VARIANTS[type]
    : {
        base: 'bg-white border-gray-600 ',
        iconstyle: '',
        Icon: Icon,
        name: header,
      };

  useEffect(() => {
    if (lifetime && onRemove) {
      setTimeout(() => {
        onRemove(id);
      }, lifetime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lifetime]);

  return (
    <div
      className={clsx(
        'visible flex w-full flex-row shadow-lg',
        'cursor-pointer rounded-md border-l-4 duration-100',
        'transform transition-all hover:scale-102',
        Var.base,
        type && 'max-h-40'
      )}
    >
      <div
        className='flex-no-wrap flex w-full flex-row p-2'
        data-tp-id='gen-div-54f0d2f4-566f-4d07-ba78-9f8033b2e168'
      >
        {Var.Icon && (
          <div
            className={clsx(
              'flex h-12 w-12 items-center',
              'mx-auto select-none text-xl'
            )}
            data-tp-id='gen-div-22409ede-e08d-4079-8722-cc5504b31be9'
          >
            <Var.Icon className={clsx('mx-auto', Var.iconstyle)} size='m' />
          </div>
        )}

        <div
          className='flex-no-wrap flex w-full flex-col px-1'
          data-tp-id='gen-div-75b6a24c-1651-4010-a051-ef28d4c823fe'
        >
          <div
            className='my-auto flex select-none font-bold'
            data-tp-id='gen-div-82e7c224-268a-40e4-af8f-c22fdb64592a'
          >
            {Var.name}
          </div>
          <p
            className={clsx(
              'break-word my-auto -mt-0.5 flex',
              'text-sm',
              typeof message === 'string' && truncate
            )}
          >
            {message}
          </p>
        </div>
        <div
          onClick={() => onRemove && onRemove(id)}
          className={clsx(
            'mx-auto mr-2 h-12 w-10 items-center',
            'text-center text-lg leading-none'
          )}
          data-tp-id='gen-div-15a3dccc-aa3d-42ac-9fa7-70270c65c201'
        >
          <Close
            className={clsx(
              'mx-auto my-auto h-full text-center text-white',
              'transform cursor-pointer hover:scale-105 '
            )}
            size='s'
          />
        </div>
      </div>
    </div>
  );
}
