import get from 'lodash/get';
import set from 'lodash/set';

export const submissionErrorMessages = {
  phone: 'Please enter a valid phone number',
  email: 'Please enter a valid email',
};

export const validateFormFields = async ({
  validateFn,
  validations,
  values,
  setAsyncError,
  setValidating,
}) => {
  setValidating(true);
  const payload = validations.reduce((acc, { name }) => {
    const fieldValue = get(values, name);
    if (fieldValue) {
      acc[name] = get(values, name);
    }

    return acc;
  }, {});
  let valid = true;
  const data = {};
  try {
    const { body } = await validateFn(payload);

    if (body) {
      Object.entries(body).forEach(([fieldName, val]) => {
        set(data, fieldName, val);
        const { isValid, reason } = val;
        const errorMessage =
          submissionErrorMessages[fieldName] || reason || 'Invalid';
        if (!isValid) {
          setAsyncError(fieldName, errorMessage);
          valid = false;
        }
      });
    }
  } catch (e) {
    console.error(e);
  }

  setValidating(false);

  return { data, isValid: valid };
};
