import buildApi from '@react/services/api/buildApi';
import createMiddleware from '@react/services/api/middleware';
import apiReducer from '@react/services/api/reducer';
import mitt from 'mitt';
import { apiUrl } from 'sly/config';

import { getRequestResult } from './selectors';

const createStore = (initialState = {}, promises = {}) => {
  let state = initialState;
  const emitter = mitt();
  const cache = new WeakMap();

  const getRequestInfo = (apiCall, argsKey) => {
    const request = state[apiCall]?.[argsKey];

    if (!request) {
      return request;
    }

    const previousRequestInfo = cache.get(request);

    if (previousRequestInfo) {
      return previousRequestInfo;
    }

    const requestInfo = { ...request };

    if (!request.body) {
      cache.set(request, requestInfo);
      return requestInfo;
    }

    if (request.isJsonApi) {
      const { data, meta, included } = request.body;

      if (data) {
        const entities = Array.isArray(data) ? [...data] : [data];
        if (included) {
          entities.push(...included);
        }

        requestInfo.entities = entities.reduce((acc, item) => {
          (acc[item.type] = acc[item.type] || {})[item.id] = item;
          return acc;
        }, {});
      }

      requestInfo.response = data;
      requestInfo.result = data; // alias for backwards comp
      requestInfo.meta = meta;
      requestInfo.normalized = getRequestResult(requestInfo, true);
    } else {
      requestInfo.response = request.body;
      requestInfo.result = request.body; // alias for backwards comp
    }
    cache.set(request, requestInfo);
    return requestInfo;
  };

  const setState = (newState) => {
    Object.entries(newState).forEach(([call, requests]) => {
      if (!requests || typeof requests !== 'object') {
        return;
      }
      Object.entries(requests).forEach(([key, request]) => {
        if (request !== state[call]?.[key]) {
          if (!state?.[call]) {
            state[call] = {};
          }
          state[call][key] = request;
          emitter.emit(`${call}#${key}`, getRequestInfo(call, key));
          cache.delete(state[call]?.[key]);
        }
      });
    });
  };

  const next = (action) => setState(apiReducer(state, action));
  const dispatch = createMiddleware(promises, next);

  return {
    dispatch,
    setState,
    promises,
    getRequestInfo,

    getState() {
      return state;
    },

    on(call, key, cb) {
      emitter.on(`${call}#${key}`, cb);
    },

    off(call, key, cb) {
      emitter.off(`${call}#${key}`, cb);
    },
  };
};

export default function createApiClient(apiEndpoints, options = {}) {
  const api = buildApi(apiEndpoints, {
    baseUrl: apiUrl,
    configureOptions: (options) => ({
      redirect: 'manual',
      credentials: 'include',
      ...options,
    }),
  });

  const store = createStore(options.initialState, options.promises);

  return {
    api,
    store,
  };
}
