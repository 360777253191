import { cva } from 'class-variance-authority';

export const tagVariants = cva(
  'inline-flex items-center rounded-md border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-slate-lighter-90 bg-white',
  {
    variants: {
      variant: {
        default: 'text-viridian-base',
        highlight: 'text-green-base',
        alert: 'text-red-base'
      }
    },
    defaultVariants: {
      variant: 'default'
    }
  }
);
