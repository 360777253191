import { usePrefetch } from '@react/services/api';

import RetentionPopup from '..';

function RetentionPopupWrapper() {
  const {
    requestInfo: { result: exitIntentSchema, hasFinished },
  } = usePrefetch('getExitIntentSchema', { populate: 'exitSurvey' });

  if (!hasFinished) {
    return null;
  } else if (!exitIntentSchema?.data?.enableExitModal) {
    return null;
  } else if (!exitIntentSchema?.data?.exitSurvey?.length) {
    return null;
  }

  return <RetentionPopup exitIntentSchema={exitIntentSchema} />;
}

export default RetentionPopupWrapper;
