/** @jsxImportSource react */
import clsxm from '@common/lib/clsxm';
import GwizzyForm from '@react/gwizzy/components/steps/GwizzyForm';
import { validateFields } from '@react/gwizzy/helpers/utils';
import WizardPage from '@react/wizards/components/WizardPage';
import FinalFormWizard from '@react/wizards/v2';

export default function GwizzyWizard({
  initialValues,
  asyncActionHandlers,
  localStorageKey,
  formSchema,
  successMessage,
  title,
  formClassName,
  formWrapperClassName,
  isDefaultSubmitDisabled,
  hasExperimentalOnBack,
  isChatMode,
  initialStep = 0,
}) {
  return (
    <FinalFormWizard
      initialValues={initialValues}
      asyncActionHandlers={asyncActionHandlers}
      localStorageKey={localStorageKey}
      successMessage={successMessage}
      isDefaultSubmitDisabled={isDefaultSubmitDisabled}
      className={clsxm(isChatMode && 'flex flex-1 flex-col', formClassName)}
      isProgressBarVisible={!isChatMode}
      initialStep={initialStep}
    >
      {Object.entries(formSchema).map(([step, { form, tooltip, skip }]) => {
        const { fields } = form;
        if (skip) {
          return null;
        }
        return (
          <WizardPage key={step} name={step} validate={validateFields(fields)}>
            <GwizzyForm
              step={step}
              tooltip={tooltip}
              form={form}
              title={title}
              formWrapperClassName={formWrapperClassName}
              isChatMode={isChatMode}
              hasExperimentalOnBack={hasExperimentalOnBack}
            />
          </WizardPage>
        );
      })}
    </FinalFormWizard>
  );
}
