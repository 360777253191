import {
  ASSESSMENT_WIZARD_COMPLETED,
  ASSESSMENT_WIZARD_MATCHED_AGENT,
} from '@sly/core/constants/assessment';
import { stateAbbr } from '@sly/core/constants/geo';
import { isBrowser } from 'sly/config';

export const getIsAssessmentComplete = () =>
  isBrowser && !!localStorage.getItem(ASSESSMENT_WIZARD_COMPLETED);

export const completedAssessment =
  isBrowser && !!localStorage.getItem(ASSESSMENT_WIZARD_COMPLETED);
export const agentId = isBrowser
  ? localStorage.getItem(ASSESSMENT_WIZARD_MATCHED_AGENT) || ''
  : '';

const contactInfoFields = ['firstName', 'lastName', 'email', 'phone'];

export const getHasContactInfo = (initialValues) => {
  return Object.entries(initialValues).reduce((_, [key, value]) => {
    return contactInfoFields.includes(key) && !!value;
  }, false);
};

export const hasUserAcknowledgedDisclosureByState = (user, state) => {
  if (!user) {
    return false;
  }
  let disclosureDataField = null;
  switch (state) {
    case stateAbbr.Arizona:
      disclosureDataField = 'arizonaDisclosureData';
      break;
    case stateAbbr.Colorado:
      disclosureDataField = 'coloradoDisclosureData';
      break;
    default:
      break;
  }

  if (disclosureDataField) {
    return !!user?.uuidAux?.uuidInfo?.disclosureData?.[disclosureDataField];
  }
};
