import React, { forwardRef, Ref } from 'react';
import { SimpleIconProps } from '../icon.types';
import Icon from './Icon';

const svg = `<svg viewBox="0 0 24 24" fill="currentColor">
  <path d="M11.9941 20.5043C11.7249 20.5043 11.4545 20.4557 11.1829 20.3584C10.9114 20.2611 10.67 20.1089 10.4587 19.9016L8.72175 18.3147C6.96307 16.7099 5.36924 15.1048 3.94025 13.4992C2.51125 11.8935 1.79675 10.1105 1.79675 8.14998C1.79675 6.52372 2.33969 5.16561 3.42558 4.07566C4.51144 2.98571 5.8676 2.44073 7.49405 2.44073C8.36942 2.44073 9.19677 2.61927 9.9761 2.97634C10.7555 3.3334 11.4281 3.82896 11.9941 4.46301C12.56 3.82896 13.2326 3.3334 14.012 2.97634C14.7913 2.61927 15.6187 2.44073 16.4941 2.44073C18.122 2.44073 19.4815 2.98571 20.5726 4.07566C21.6637 5.16561 22.2093 6.52372 22.2093 8.14998C22.2093 10.1105 21.496 11.8977 20.0693 13.5117C18.6427 15.1256 17.0334 16.7369 15.2413 18.3456L13.5354 19.9076C13.3241 20.1148 13.0817 20.2661 12.8082 20.3614C12.5346 20.4567 12.2633 20.5043 11.9941 20.5043ZM11.0022 6.77988C10.5428 6.12045 10.0351 5.61157 9.4791 5.25323C8.92312 4.8949 8.26198 4.71573 7.49568 4.71573C6.51741 4.71573 5.70219 5.0421 5.05003 5.69483C4.39786 6.34755 4.07178 7.16594 4.07178 8.14998C4.07178 9.009 4.37711 9.9217 4.98778 10.8881C5.59846 11.8545 6.32879 12.7919 7.17878 13.7005C8.02878 14.6091 8.90354 15.4598 9.80305 16.2528C10.7026 17.0457 11.4329 17.6982 11.9941 18.2103C12.5567 17.6936 13.2887 17.039 14.1898 16.2465C15.091 15.4539 15.9673 14.6035 16.8189 13.6954C17.6704 12.7872 18.4026 11.8516 19.0153 10.8886C19.6279 9.92553 19.9343 9.01267 19.9343 8.14998C19.9343 7.16594 19.6069 6.34755 18.9522 5.69483C18.2974 5.0421 17.479 4.71573 16.4969 4.71573C15.7276 4.71573 15.066 4.8949 14.512 5.25323C13.958 5.61157 13.4513 6.12045 12.9919 6.77988C12.87 6.9585 12.7221 7.09247 12.5481 7.18178C12.374 7.27108 12.1893 7.31573 11.9941 7.31573C11.7988 7.31573 11.6148 7.27108 11.4423 7.18178C11.2697 7.09247 11.123 6.9585 11.0022 6.77988Z" style="fill: var(--outline-color)"/>
  <path d="M11.9941 20.5043C11.7249 20.5043 11.4545 20.4557 11.1829 20.3584C10.9114 20.2611 10.67 20.1089 10.4587 19.9016L8.72175 18.3147C6.96307 16.7099 5.36924 15.1048 3.94025 13.4992C2.51125 11.8935 1.79675 10.1105 1.79675 8.14998C1.79675 6.52353 2.33969 5.16538 3.42558 4.07553C4.51144 2.98567 5.8676 2.44073 7.49405 2.44073C8.36942 2.44073 9.19677 2.61927 9.9761 2.97634C10.7555 3.3334 11.4281 3.82896 11.9941 4.46301C12.56 3.82896 13.2326 3.3334 14.012 2.97634C14.7913 2.61927 15.6187 2.44073 16.4941 2.44073C18.1205 2.44073 19.4797 2.98567 20.5715 4.07553C21.6634 5.16538 22.2093 6.52353 22.2093 8.14998C22.2093 10.1105 21.496 11.8977 20.0693 13.5117C18.6427 15.1256 17.0334 16.7369 15.2413 18.3456L13.5354 19.9076C13.3241 20.1148 13.0817 20.2661 12.8082 20.3614C12.5346 20.4567 12.2633 20.5043 11.9941 20.5043Z" style="fill: var(--active-color)"/>
</svg>`;

const Favorite = forwardRef(
  (props: SimpleIconProps, ref: Ref<SVGSVGElement> | undefined) => (
    <Icon ref={ref} name='favorite' svg={svg} {...props} />
  )
);

Favorite.displayName = 'FavoriteIcon';

export default Favorite;
