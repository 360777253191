import isEmpty from 'lodash/isEmpty';
import {
  Dispatch,
  Reducer,
  ReducerAction,
  ReducerState,
  useCallback,
  useEffect,
  useLayoutEffect,
  useReducer,
  useRef,
} from 'react';

const SET_FULL_STATE = 'SET_FULL_STATE';

const middleware = (reducer: Reducer<any, any>) => {
  const modifiedReducer = (state: any, { type, payload }: Record<any, any>) => {
    if (type === SET_FULL_STATE) {
      return payload;
    }
    return reducer(state, { type, payload });
  };

  return modifiedReducer;
};

const isClient =
  typeof window !== 'undefined' &&
  !/ServerSideRendering/.test(window.navigator && window.navigator.userAgent);

const wrappedInitializer = (initializer: any, key: string) => (init: any) => {
  const localState = key && isClient ? localStorage.getItem(key) : '';
  const valueFromLocalStorage = localState ? JSON.parse(localState) : {};
  let initializerValue = {};
  if (!isEmpty(valueFromLocalStorage)) {
    initializerValue = initializer(valueFromLocalStorage);
  } else {
    initializerValue = initializer(init);
  }
  return {
    ...valueFromLocalStorage,
    ...initializerValue,
  };
};

const useIsomorphicLayoutEffect = isClient ? useLayoutEffect : useEffect;

export type AsyncActionHandlers<
  R extends Reducer<any, any>,
  AsyncAction extends { type: string }
> = {
  [T in AsyncAction['type']]: AsyncAction extends infer A
    ? A extends {
        type: T;
      }
      ? (s: {
          dispatch: Dispatch<AsyncAction | ReducerAction<R>>;
          getState: () => ReducerState<R>;
        }) => (a: A) => Promise<void>
      : never
    : never;
};

export function useReducerAsync<
  R extends Reducer<any, any>,
  I,
  AsyncAction extends { type: string },
  ExportAction extends AsyncAction | ReducerAction<R> =
    | AsyncAction
    | ReducerAction<R>
>(
  key: string,
  reducer: R,
  initializerArg: I,
  initializer: (arg: I) => ReducerState<R>,
  asyncActionHandlers: AsyncActionHandlers<R, AsyncAction>
): [ReducerState<R>, Dispatch<ExportAction>];

export function useReducerAsync<
  R extends Reducer<any, any>,
  AsyncAction extends { type: string },
  ExportAction extends AsyncAction | ReducerAction<R> =
    | AsyncAction
    | ReducerAction<R>
>(
  key: string,
  reducer: R,
  initialState: ReducerState<R>,
  asyncActionHandlers: AsyncActionHandlers<R, AsyncAction>
): [ReducerState<R>, Dispatch<ExportAction>];

export function useReducerAsync<
  R extends Reducer<any, any>,
  AsyncAction extends { type: string },
  ExportAction extends AsyncAction | ReducerAction<R> =
    | AsyncAction
    | ReducerAction<R>
>(
  key: string,
  reducer: R,
  initialState: ReducerState<R>,
  asyncActionHandlers: AsyncActionHandlers<R, AsyncAction>
): [ReducerState<R>, Dispatch<ExportAction>];

export function useReducerAsync<
  R extends Reducer<any, any>,
  I,
  AsyncAction extends { type: string },
  ExportAction extends AsyncAction | ReducerAction<R> =
    | AsyncAction
    | ReducerAction<R>
>(
  key: string,
  reducer: R,
  initializerArg: I | ReducerState<R>,
  initializer: unknown,
  asyncActionHandlers?: AsyncActionHandlers<R, AsyncAction>
): [ReducerState<R>, Dispatch<ExportAction>] {
  const aaHandlers = (asyncActionHandlers ||
    initializer) as AsyncActionHandlers<R, AsyncAction>;
  const [state, dispatch] = useReducer(
    middleware(reducer),
    initializerArg as any,
    asyncActionHandlers && (wrappedInitializer(initializer, key) as any)
  );
  const lastState = useRef(state);
  useIsomorphicLayoutEffect(() => {
    lastState.current = state;
  }, [state]);
  const getState = useCallback(() => lastState.current, []);
  const wrappedDispatch = useCallback(
    (action: AsyncAction | ReducerAction<R>) => {
      const { type } = (action || {}) as { type?: AsyncAction['type'] };
      const aaHandler = ((type && aaHandlers[type]) ||
        null) as typeof action extends AsyncAction
        ? (s: {
            dispatch: Dispatch<AsyncAction | ReducerAction<R>>;
            getState: () => ReducerState<R>;
          }) => (a: typeof action) => Promise<void>
        : null;
      if (aaHandler) {
        aaHandler({ dispatch: wrappedDispatch, getState })(
          action as AsyncAction
        );
      } else {
        dispatch(action as ReducerAction<R>);
      }
    },
    [aaHandlers, getState]
  );

  const handleStorageChange = useCallback(
    ({ key: evKey, newValue }: StorageEvent) => {
      if (evKey === key) {
        if (newValue) {
          dispatch({ type: SET_FULL_STATE, payload: JSON.parse(newValue) });
        }
      }
    },
    [key]
  );

  useEffect(() => {
    if (key) {
      window.addEventListener('storage', handleStorageChange);
    }
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [handleStorageChange, key]);

  useEffect(() => {
    lastState.current = state;
    if (key) {
      localStorage.setItem(key, JSON.stringify(state));
    }
  }, [key, state]);

  return [state, wrappedDispatch];
}
