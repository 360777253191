/** @jsxImportSource react */
import { useAuth } from '@react/services/auth';
import hoistNonReactStatic from 'hoist-non-react-statics';
import { object } from 'proptypes';

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default function withAuth(InnerComponent) {
  const WithAuth = ({ status = {}, ...props }) => {
    const { userInfo, fetchUser, invalidateUser, ...auth } = useAuth();
    return (
      <InnerComponent
        status={{
          ...status,
          user: {
            ...userInfo,
            refetch: fetchUser,
            invalidate: invalidateUser,
          },
        }}
        {...props}
        {...auth}
      />
    );
  };

  WithAuth.propTypes = {
    status: object,
  };

  WithAuth.displayName = `withAuth(${getDisplayName(InnerComponent)})`;
  WithAuth.WrappedComponent = InnerComponent;
  hoistNonReactStatic(WithAuth, InnerComponent);

  return WithAuth;
}
