import { isBrowser } from 'sly/config';

export const randomHexNumber = () => {
  return Math.floor(Math.random() * 16777215).toString(16);
};

export const copyToClipboard = text => {
  if (isBrowser) {
    if (navigator && navigator.clipboard) {
      navigator.clipboard
        .writeText(text)
        .then(() => alert(`${text} copied to clipboard!`))
        .catch(() => alert(`Could not copy ${text} to clipboard!`));
    } else {
      alert(`Copy ${text} to clipboard unsupported!`);
    }
  }
};

export const capitalize = s => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const objectMap = (obj, fn) =>
  Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]));

export const objectFilter = (obj, allowed = []) =>
  Object.keys(obj)
    .filter(key =>
      Array.isArray(allowed) ? allowed.includes(key) : allowed === key
    )
    .reduce((acc, key) => {
      acc[key] = obj[key];
      return acc;
    }, {});

export const isString = str => typeof str === 'string' || str instanceof String;

export const isBoolean = val => typeof val === 'boolean';

// Arguments path = "/[toc]/[state]" , params = {toc:'assisted-living', state:'california'}
// output -> /assisted-living/california
export const constructPathFromParams = (path, params) => {
  Object.entries(params).forEach(([key, value]) => {
    path = path.replace('[' + key + ']', value);
  });
  return path;
};

export const getHashInRange = (slug, maxValue) => {
  const p = 31;
  const m = 1e9 + 9;
  let hash_value = 0;
  let p_pow = 1;
  for (let i = 0; i < slug.split('').length; i++) {
    hash_value = (hash_value + (slug[i].charCodeAt(0) - 97 + 1) * p_pow) % m;
    p_pow = (p_pow * p) % m;
  }
  return Math.abs(hash_value % maxValue);
};
