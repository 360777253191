import { Agent } from '../types';

export function getAgentStats(agent: Agent) {
  const { aggregateRating, info } = agent || {};
  const { recentFamiliesHelped, serviceArea, inBusinessSince, awards } =
    info || {};

  return {
    ratingValue: aggregateRating?.ratingValue,
    numRatings: aggregateRating?.numRatings,
    recentFamiliesHelped,
    operatingIn: serviceArea?.operatingIn,
    inBusinessSince,
    awards
  };
}
