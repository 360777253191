import { useUser } from '@react/services/auth';
import { usePopups } from '@react/services/popupsContext';
import Head from 'next/head';
import { node } from 'proptypes';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  /* olarkSiteId, */ hideChatbox,
  isBrowser,
  isProd,
  rokoApiKey,
} from 'sly/config';

import ChatBoxGlobalStyle from './ChatBoxGlobalStyle';

export const ChatBoxContext = React.createContext({
  triggerChatBot: (_eventName, _timeout = 10, _force) => {},
  setDisableChatBot: (disable) => {},
  isChatBotActive: () => {},
});

const loadJsScript = () => {
  return new Promise((resolve, reject) => {
    if (typeof window !== 'undefined') {
      let script = document.getElementById('instabot');
      if (script && !!window?.RokoInstabot) {
        resolve(window.RokoInstabot);
      }
      if (!script) {
        script = document.createElement('script');
        script.src = 'https://widget.instabot.io/jsapi/rokoInstabot.js';
        script.id = 'instabot';
        script.type = 'text/javascript';
        script.text = `apiKey:"${rokoApiKey}"`;
        script.async = true;
        script.crossOrigin = '';
        document.body.appendChild(script);
      }
      script.onload = () => {
        resolve(window.RokoInstabot);
      };
      script.onerror = (err) => {
        reject(err);
      };
    } else {
      reject(new Error("Roko Instabot can't be loaded in the server"));
    }
  });
};

// NOT USED CURRENTLY BUT LEAVING FOR FUTURE USE
// const getTimeoutForEvent = () => {
// if (eventName === 'Bot reintro') {
//   return 30000;
// } else if (eventName === 'ccrc-profile') {
//   return 30000;
// }  else if (eventName === 'user-intent') {
//   return 30000;
// }  else if (eventName === 'search-bot') {
//   return 30000;
// }  else if (eventName === 'home-bot') {
//   return 30000;
// }  else if (eventName === 'direct-market-2') {
//   return 30000;
// } else if (eventName === 'direct-market-1') {
//   return 30000;
// } else if (eventName === 'e2e-chat-bot') {
//   return 10000;
// }
// return 10000; // default timeout
// };

const isChatBotActive = () => {
  if (isBrowser) {
    const chatbot = document.getElementsByClassName('roko-instabot-widget');
    if (chatbot && chatbot.length) {
      const display = window.getComputedStyle(chatbot[0]).display;
      if (display !== 'none') {
        return true;
      }
    }
  }
  return false;
};

export const ChatBoxProvider = (props) => {
  const [isChatboxLoaded, setChatboxLoaded] = useState(false);
  const [currentTrigger, setCurrentTrigger] = useState(null);

  const currentTimer = useRef(0);
  const disableChatBot = useRef(false);
  const { isPopupActive } = usePopups();
  const { user } = useUser();

  const isDisabledInLocalStorage = useCallback(
    () => localStorage.getItem('rokoInstabotDisabled'),
    []
  );

  useEffect(() => {
    if (user) {
      disableChatBot.current = true;
    }
  }, [user]);

  const clearCurrentTimeOut = useCallback(() => {
    if (currentTimer.current) {
      clearTimeout(currentTimer.current);
      currentTimer.current = null;
    }
  }, [currentTimer.current]);

  const setDisableChatBot = useCallback(
    (value) => {
      const prevValue = disableChatBot.current;
      if (user) {
        disableChatBot.current = true;
      } else {
        disableChatBot.current = value;
      }
      if (prevValue != value && !value && currentTrigger?.eventName && !user) {
        triggerEvent(
          currentTrigger.eventName,
          currentTrigger.timeout,
          currentTrigger.force
        );
      }
    },
    [currentTrigger, user]
  );

  const triggerChatBot = useCallback((eventName, timeout = 10, force) => {
    setCurrentTrigger({ eventName, timeout, force });
    triggerEvent(eventName, timeout, force);
  }, []);

  const triggerEvent = useCallback(
    (eventName, timeout = 10, force) => {
      console.log('eventName', eventName, 'timeout', timeout);

      if (!isProd) {
        eventName = 'e2e-chat-bot';
      }

      if (
        hideChatbox ||
        (disableChatBot.current && !force) ||
        isPopupActive() ||
        isDisabledInLocalStorage()
      ) {
        return;
      }

      clearCurrentTimeOut();

      currentTimer.current = setTimeout(() => {
        if (location.pathname !== window.location.pathname) {
          return;
        }
        loadJsScript()
          .then((instance) => {
            if (
              hideChatbox ||
              (disableChatBot.current && !force) ||
              isPopupActive() ||
              isDisabledInLocalStorage()
            ) {
              clearCurrentTimeOut();
              return;
            }
            console.log('Triggering Chat event name : ', eventName);
            instance.trigger(eventName);
            setCurrentTrigger(null);
            clearCurrentTimeOut();
            if (!isChatboxLoaded) {
              setChatboxLoaded(true);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }, timeout);
    },
    [currentTimer.current, disableChatBot.current, isPopupActive]
  );

  const contextValue = useMemo(
    () => ({
      triggerChatBot: triggerChatBot,
      setDisableChatBot,
      isChatBotActive,
    }),
    [triggerChatBot, setDisableChatBot]
  );

  return (
    <>
      <ChatBoxContext.Provider value={contextValue}>
        {isChatboxLoaded && (
          <Head>
            <style type='text/css'>{ChatBoxGlobalStyle}</style>
          </Head>
        )}
        {props.children}
      </ChatBoxContext.Provider>
    </>
  );
};

export const useChatbox = () => {
  return useContext(ChatBoxContext);
};

ChatBoxProvider.propTypes = {
  children: node,
};

export default ChatBoxProvider;
