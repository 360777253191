// filter types
export const STATE = 'state';
export const CITY = 'city';
export const TOC = 'toc';
export const SIZE = 'size';
export const GUIDE = 'own-guide';
export const STARTING_RATE = 'starting-rate';
export const RADIUS = 'radius';
export const GEO = 'geo';
export const GEOMETRY = 'geometry';
export const EXACT_COUNTY = 'exactCounty';
export const SORT = 'sort';
export const HEALTH_CARE_SERVICES = 'healthCareService';
export const ROOM_AMENITIES = 'room-amenities';
export const COMMUNITY_AMENITIES = 'community-amenities';
export const DINING_SERVICES = 'diningService';
export const COMMUNITY_SERVICES = 'communityService';

export const PAGE_NUMBER = 'page-number';
export const PAGE_SIZE = 'page-size';
export const ID = 'filter[id]';
export const DEFAULT_PAGE_SIZE = 20;

export const INCLUDE = 'include';
export const COMMUNITY = 'community';
export const LISTING = 'listing';

export const CTA_EVENTS = {
  [TOC]: { cta: 'Filter by community type' },
  [SIZE]: { cta: 'Filter by community size' },
  [STARTING_RATE]: { cta: 'Filter by community pricing' }
};

export const getCtaEvent = (
  section: 'toc' | 'size' | 'starting-rate'
): string => {
  if (CTA_EVENTS[section]) {
    return CTA_EVENTS[section].cta;
  }
  return 'Filter by additional attributes';
};

export const MORE_FILTERS = [
  HEALTH_CARE_SERVICES,
  ROOM_AMENITIES,
  COMMUNITY_AMENITIES,
  DINING_SERVICES,
  COMMUNITY_SERVICES
];

export const ALL_FILTERS = [TOC, SIZE, STARTING_RATE, ...MORE_FILTERS];

export const PAGINATION_FILTERS = [PAGE_NUMBER, PAGE_SIZE];

export const CLEARABLE_FILTERS = [...PAGINATION_FILTERS, ...ALL_FILTERS];

// TOC
export const SL = 'senior-living';
export const AL = 'assisted-living';
export const IL = 'independent-living';
export const MC = 'memory-care';
export const BNC = 'board-and-care-home';
export const CCRC = 'continuing-care-retirement-community';
export const AA = 'active-adult';
export const SNF = 'skilled-nursing-facility';
export const CA = 'care-home';

export const TOC_OPTIONS = [
  { value: SL, label: 'Senior Living' },
  { value: AL, label: 'Assisted Living' },
  { value: IL, label: 'Independent Living' },
  { value: MC, label: 'Memory Care' },
  { value: BNC, label: 'Board and Care Home' },
  { value: CCRC, label: 'Continuing Care Retirement Communities' },
  { value: AA, label: 'Active Adult Communities' },
  { value: SNF, label: 'Skilled Nursing Facilities' },
  { value: CA, label: 'Care Home' }
];

export type TocApiLabelEnum =
  | 'Assisted Living'
  | 'Memory Care'
  | 'Independent Living'
  | 'Board and Care Home'
  | 'Continuing Care Retirement Community(CCRC)'
  | 'Active Adult Communities (55+)'
  | 'Skilled Nursing Facility'
  | 'Senior Living'
  | 'Care Home';

export interface TocDescriptionObject {
  label: string;
  value: string;
  description: string;
  seoLabel: string;
  apiLabel: TocApiLabelEnum;
}

export type TocType =
  | typeof AL
  | typeof MC
  | typeof IL
  | typeof BNC
  | typeof CCRC
  | typeof AA
  | typeof SNF;

export type TocDescriptionType = Record<TocType, TocDescriptionObject>;

export const TOCS: TocDescriptionType = {
  [AL]: {
    label: 'Assisted Living',
    value: AL,
    description:
      'For seniors who need some help with daily activities and want a supportive community that’s active, social, and engaging.',
    seoLabel: 'Assisted Living Facilities',
    apiLabel: 'Assisted Living'
  },
  [MC]: {
    label: 'Memory Care',
    value: MC,
    description:
      'A supervised and secured community designed to support engagement and quality of life for residents living with dementia.',
    seoLabel: 'Memory Care Facilities',
    apiLabel: 'Memory Care'
  },
  [IL]: {
    label: 'Independent Living',
    value: IL,
    description:
      'For active older adults who want to downsize to a home in a retirement community but don’t need help to live independently.',
    seoLabel: 'Independent Living Communities',
    apiLabel: 'Independent Living'
  },
  [BNC]: {
    label: 'Board and Care Home',
    value: BNC,
    description:
      'Homes in residential neighborhoods that are equipped and staffed to provide daily care for a small number of residents.',
    seoLabel: 'Board and Care Homes',
    apiLabel: 'Board and Care Home'
  },
  [CCRC]: {
    label: 'Continuing Care Retirement Communities',
    value: CCRC,
    description:
      'For residents who want to age in place as their care needs change—from Independent Living to nursing care. Requires a buy-in fee.',
    seoLabel: 'Continuing Care Retirement Communities',
    apiLabel: 'Continuing Care Retirement Community(CCRC)'
  },
  [AA]: {
    label: 'Active Adult Communities (55+)',
    value: AA,
    description:
      'Communities of houses and apartments for residents 55 and older who live independently, enjoying an active, social lifestyle.',
    seoLabel: 'Active Adult Communities(55+)',
    apiLabel: 'Active Adult Communities (55+)'
  },
  [SNF]: {
    label: 'Nursing Homes',
    value: SNF,
    description:
      'For seniors with more serious medical needs who require skilled care following a hospitalization, illness, or surgery. Nursing Homes are also known as skilled nursing facilities.',
    seoLabel: 'Skilled Nursing Facilities',
    apiLabel: 'Skilled Nursing Facility'
  }
};

export const tocsMap: Record<TocApiLabelEnum, { label: string; href: string }> =
  {
    'Senior Living': {
      label: 'Senior Living',
      href: '/senior-living'
    },
    'Assisted Living': {
      label: 'Assisted Living',
      href: '/assisted-living'
    },
    'Independent Living': {
      label: 'Independent Living',
      href: '/independent-living'
    },
    'Board and Care Home': {
      label: 'Board and Care Home',
      href: '/board-and-care-home'
    },
    'Memory Care': {
      label: 'Memory Care',
      href: '/memory-care'
    },
    'Continuing Care Retirement Community(CCRC)': {
      label: 'Continuing Care Retirement Communities',
      href: '/continuing-care-retirement-community'
    },
    'Active Adult Communities (55+)': {
      label: 'Active Adult Communities (55+)',
      href: '/active-adult'
    },
    'Skilled Nursing Facility': {
      label: 'Skilled Nursing Facilities',
      href: '/skilled-nursing-facility'
    },
    'Care Home': {
      label: 'Care Home',
      href: '/care-home'
    }
  };

// SIZE

export const SMALL = 'small';
export const MEDIUM = 'medium';
export const LARGE = 'large';

export const SIZES = {
  [SMALL]: {
    label: 'Small',
    value: SMALL,
    description:
      'Up to 20 residents. A home-like environment with shared common areas and family-style dining, equipped and staffed to support daily care.'
  },
  [MEDIUM]: {
    label: 'Medium',
    value: MEDIUM,
    description:
      'Between 20-50 residents. A boutique-like environment with communal areas and dining, services, activities, and amenities.'
  },
  [LARGE]: {
    label: 'Large',
    value: LARGE,
    description:
      '50+ residents. A hotel-like environment with a dining room, concierge services, and wide range of activities and amenities.'
  }
};

// BUDGET

export const STARTING_RATES = {
  'le:2500': {
    label: 'Up to $2500',
    value: 'le:2500'
  },
  'le:3000': {
    label: 'Up to $3000',
    value: 'le:3000'
  },
  'le:3500': {
    label: 'Up to $3500',
    value: 'le:3500'
  },
  'le:4000': {
    label: 'Up to $4000',
    value: 'le:4000'
  },
  'le:4500': {
    label: 'Up to $4500',
    value: 'le:4500'
  },
  'le:5000': {
    label: 'Up to $5000',
    value: 'le:5000'
  },
  'le:5500': {
    label: 'Up to $5500',
    value: 'le:5500'
  },
  'le:6000': {
    label: 'Up to $6000',
    value: 'le:6000'
  },
  'ge:6000': {
    label: 'More than $6000',
    value: 'ge:6000'
  }
};
