/** @jsxImportSource react */
import { eventNames, useEvents } from '@react/services/events';
import cx from 'classnames';
import React, { forwardRef, useCallback, useMemo } from 'react';

import { LinkEventProps, LinkProps } from './link.types';

const Link = forwardRef(
  (
    {
      to,
      href: hrefProps,
      event: rawEvent,
      eventProps,
      onClick,
      className,
      underlined,
      children,
      ...props
    }: LinkProps,
    ref
  ) => {
    const { events } = useEvents();

    let newEvent = rawEvent;
    if (rawEvent || eventProps) {
      if (typeof rawEvent !== 'object') {
        newEvent = [
          typeof rawEvent === 'string' ? rawEvent : eventNames.LinkClicked,
          {
            text: typeof children === 'string' ? children : null,
            href: to || hrefProps,
            ...eventProps,
          },
        ] as LinkEventProps[];
      }
    }

    const jsonEvent = newEvent && JSON.stringify(newEvent);
    const event = useMemo(
      () => jsonEvent && JSON.parse(jsonEvent),
      [jsonEvent]
    );
    if (event && !Array.isArray(event)) {
      throw new Error(`Event type not supported ${JSON.stringify(event)}`);
    }

    const onClickHandler = useCallback(
      (...args: any) => {
        if (event) {
          events.track(...event);
        }
        if (onClick) {
          return onClick(...args);
        }
        return null;
      },
      [onClick, event]
    );

    const { LinkComponent, ...linkProps } = useMemo(() => {
      const href = to || hrefProps;

      // to-do: fix me
      return {
        LinkComponent: 'a',
        // ...target,
        href,
      } as { LinkComponent: React.ElementType; href: string };
    }, [to, hrefProps]);

    const classes = cx([
      'text-viridian-base',
      'hover:cursor-pointer',
      'hover:underline',
      className,
      underlined && 'underline font-medium',
    ]);

    return (
      <LinkComponent
        ref={ref}
        onClick={onClickHandler}
        {...linkProps}
        {...props}
        className={classes}
      >
        {children}
      </LinkComponent>
    );
  }
);

Link.displayName = 'Link';

export default Link;
