import { ACTIONS as actions } from '@react/gwizzy/constants';
import { validateFormFields } from '@react/gwizzy/helpers/validateFormFields';
import { eventNames } from '@react/services/events';
import { delay } from '@react/shared/helpers';
import { WIZARD_STEP_COMPLETED } from '@sly/core/constants/actionTypes';
import { isBrowser } from 'sly/config';

import { getResidentFirstAndLastNameByFullName } from '../helpers';
import { isSelf } from '../helpers/utils';

const getEventProps = (community) => {
  const communityEventProps = {};
  const uuidActionProps = {};
  if (community) {
    const { name, city, state, id } = community;
    uuidActionProps.slug = id;
    communityEventProps.slug = id;
    communityEventProps.name = name;
    communityEventProps.city = city;
    communityEventProps.state = state;
  }

  return { communityEventProps, uuidActionProps };
};

const parseUUIDActionData = (values, { timeZone, clientName }) => {
  if (!values) return values;

  let {
    residentName,
    tourDate,
    tourTimeText,
    moreInfoFromLeadChoice,
    moreInfoFromLead,
    adl,
    importantPreferences,
    importantPreferencesOther,
    ...rest
  } = values;

  let resident = null;

  if (residentName) {
    resident = getResidentFirstAndLastNameByFullName(residentName);
  } else if (isSelf({ lookingFor: rest?.lookingFor }) && clientName) {
    resident = getResidentFirstAndLastNameByFullName(clientName);
  }

  const importantPreferencesArray = importantPreferences?.filter?.(
    (pref) => pref !== 'other'
  );

  if (importantPreferencesOther) {
    importantPreferencesArray.push(importantPreferencesOther);
  }

  const tourPreferences =
    tourDate && tourTimeText && timeZone
      ? [
          {
            tourDate,
            tourTimeText: tourTimeText?.value,
            tourTimezone: timeZone,
          },
        ]
      : null;

  const conditionalFields = {};

  if (adl) {
    conditionalFields.adl = [adl];
  }

  if (!(moreInfoFromLeadChoice === 'Yes' && !moreInfoFromLead)) {
    conditionalFields.moreInfoFromLead =
      moreInfoFromLeadChoice === 'No'
        ? 'No, that is all. Thank you!'
        : moreInfoFromLead;
  }

  return {
    ...(resident && { resident }),
    ...(tourPreferences && { tourDataInfo: tourPreferences }),
    ...(!!importantPreferencesArray?.length && {
      importantPreferences: importantPreferencesArray,
    }),
    ...rest,
    ...conditionalFields,
  };
};

const setActivePageUUIDActions =
  ({
    events,
    applyFormValidation,
    createAction,
    community,
    eventProps,
    onQuestionChangeHandler,
    timeZone,
    clientName,
  }) =>
  ({ dispatch, getState }) =>
  async ({ page, values = {}, step, action, validations, form }) => {
    const {
      mutators: { setAsyncError, setValidating },
    } = form;
    const { stepsToIndexMap } = getState();
    const eventName =
      action === 'onNext'
        ? eventNames.WizardStepCompleted
        : eventNames.WizardStepPrevious;

    const { uuidActionProps } = getEventProps(community);

    events.track(eventName, {
      location: 'gwizzy',
      step,
      ...eventProps,
      values,
    });

    if (validations) {
      const { isValid } = await validateFormFields({
        validateFn: applyFormValidation,
        validations,
        values,
        setAsyncError,
        setValidating,
      });

      if (!isValid) {
        return;
      }
    }

    try {
      if (onQuestionChangeHandler) {
        onQuestionChangeHandler();
      }
      const updatedValues = parseUUIDActionData(values, {
        timeZone,
        clientName,
      });
      dispatch({ type: 'SET_STATUS', payload: 'pending' });

      await createAction({
        type: 'UUIDAction',
        attributes: {
          actionType: WIZARD_STEP_COMPLETED,
          actionPage: isBrowser ? location.pathname : '',
          actionInfo: {
            data: updatedValues,
            stepName: 'wizardPostConversionInfo',
            wizardName: 'gwizzy',
            ...uuidActionProps,
          },
        },
      });
      dispatch({ type: 'SET_STATUS', payload: 'started' });
    } catch (e) {
      console.error(e);
      dispatch({ type: 'SET_STATUS', payload: 'started' });
    }

    if (values) {
      dispatch({ type: 'SET_VALUES', payload: values });
    }
    dispatch({ type: 'SET_PAGE', payload: stepsToIndexMap?.[page] });
  };

const submitPostConversion =
  ({ events, createAction, community, onDone, timeZone, clientName }) =>
  ({ dispatch }) =>
  async ({ values }) => {
    if (values) {
      dispatch({ type: 'SET_VALUES', payload: values });
    }

    dispatch({ type: 'SET_STATUS', payload: 'submitting' });

    const { communityEventProps, uuidActionProps } = getEventProps(community);

    events.track(eventNames.WizardSubmitted, {
      location: 'post-conversion',
      values: values,
      ...communityEventProps,
    });

    const payloadData = parseUUIDActionData(values, { timeZone, clientName });

    try {
      await createAction({
        type: 'UUIDAction',
        attributes: {
          actionType: WIZARD_STEP_COMPLETED,
          actionPage: isBrowser ? location.pathname : '',
          actionInfo: {
            data: payloadData,
            stepName: 'wizardPostConversionInfo',
            wizardName: 'gwizzy',
            wizardPostConversionInfo: 'wizardPostConversionInfo',
            ...uuidActionProps,
          },
        },
      });
    } catch (e) {
      dispatch({ type: 'SET_STATUS', payload: 'error' });
      console.error(e);
    }

    dispatch({ type: 'SET_STATUS', payload: 'done' });
    await delay(2000);
    await onDone(values);
  };

const postConversionAsyncHandler = (props) => {
  return {
    [actions.setActivePage]: setActivePageUUIDActions(props),
    [actions.submit]: submitPostConversion(props),
  };
};

export default postConversionAsyncHandler;
