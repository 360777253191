import {
  AGENT,
  CUSTOMER,
  LOGGINGIN,
  PROVIDER,
  REGISTER,
} from '@sly/core/constants/auth';
import { createContext } from 'react';

type Role = typeof AGENT | typeof CUSTOMER | typeof PROVIDER;
type Action = typeof REGISTER | typeof LOGGINGIN;

export interface AuthcontextType {
  ensureAuthenticated: (
    callback: () => void,
    role: Role,
    action: Action,
    data?: {
      prop: string;
      community: {
        value: string;
        label: string;
      };
    }
  ) => void;
  authenticatedCancel: () => void;
  authenticatedSuccess: () => void;
  provider: boolean;
  customer: boolean;
  agent: boolean;
  register: boolean;
  loggingIn: boolean;
  shouldAuthenticate: boolean;
  authenticating: boolean;
  data: {
    prop: string;
    community: {
      value: string;
      label: string;
    };
  };
  redirectAfterLogin: string;
  redirectStatus: string;
  getInitialStep: (initialStep: string) => string;
}

const AuthContext = createContext<AuthcontextType | null>(null);

export default AuthContext;
