import { WizardContextType } from '@react/wizards/types';
import { createContext, useContext } from 'react';

export const WizardFormContext = createContext<WizardContextType>(
  {} as WizardContextType
);

export const useWizarForm = () => {
  const context = useContext(WizardFormContext);

  if (!context) {
    throw Error('Wrap your step with `WizardFormContext`');
  } else {
    return context;
  }
};
