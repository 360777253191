/* eslint-disable no-multi-assign */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */
import { isServer, isTest, segmentApiKey } from 'sly/config';
import { getUUID } from '@common/helpers/cookie';

function loadAnalytics() {
  // Create a queue, but don't obliterate an existing one!
  const analytics = (window.analytics = window.analytics || []);
  // If the real analytics.js is already on the page return.
  if (analytics.initialize) return;
  // If the snippet was invoked already show an error.
  if (analytics.invoked) {
    if (window.console && console.error) {
      console.error('Segment snippet included twice.');
    }
    return;
  }
  // Invoked flag, to make sure the snippet
  // is never invoked twice.
  analytics.invoked = true;
  // A list of the methods in Analytics.js to stub.
  analytics.methods = [
    'trackSubmit',
    'trackClick',
    'trackLink',
    'trackForm',
    'pageview',
    'identify',
    'reset',
    'group',
    'track',
    'ready',
    'alias',
    'debug',
    'page',
    'once',
    'off',
    'on',
    'addSourceMiddleware',
    'addIntegrationMiddleware',
    'setAnonymousId',
    'addDestinationMiddleware'
  ];
  // Define a factory to create stubs. These are placeholders
  // for methods in Analytics.js so that you never have to wait
  // for it to load to actually record data. The `method` is
  // stored as the first argument, so we can replay the data.
  analytics.factory = function (method) {
    return function () {
      const args = Array.prototype.slice.call(arguments);
      args.unshift(method);
      analytics.push(args);
      return analytics;
    };
  };
  // For each of our methods, generate a queueing stub.
  for (let i = 0; i < analytics.methods.length; i++) {
    const key = analytics.methods[i];
    analytics[key] = analytics.factory(key);
  }
  // Define a method to load Analytics.js from our CDN,
  // and that will be sure to only ever load it once.
  analytics.load = function (key, options) {
    // Create an async script element based on your key.
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = `https://segmentcdn.seniorly.com/analytics.js/v1/${key}/analytics.min.js`;
    // Insert our script next to the first script element.
    const first = document.getElementsByTagName('script')[0];
    first.parentNode.insertBefore(script, first);
    analytics._loadOptions = options;
  };
  analytics._writeKey = segmentApiKey;
  // Add a version to keep track of what's in the wild.
  analytics.SNIPPET_VERSION = '4.13.2';

  // Load Analytics.js with your key, which will automatically
  // load the tools you've enabled for your account. Boosh!
  analytics.load(segmentApiKey);
  // Make the first page call to load the integrations. If
  // you'd like to manually name or tag the page, edit or
  // move this call however you'd like.
  // analytics.page();
  analytics.setAnonymousId(getUUID());
}

function getSegment() {
  if (typeof window.analytics === 'object') {
    return window.analytics;
  }

  loadAnalytics();
  return window.analytics;
}

function makeSegment() {
  if (isServer || isTest) {
    return;
  }

  return {
    identify(userId, userData) {
      const segment = getSegment();
      segment.identify(userId, userData, {
        anonymousId: getUUID()
      });
    },
    track(...args) {
      const segment = getSegment();
      if (args.length === 1) {
        const [event] = args;
        segment.track(`${event.action} ${event.category}`, event);
      } else {
        segment.track(...args);
      }
    },
    page(name, properties) {
      const segment = getSegment();
      segment.page(name, properties);
    }
  };
}

export default makeSegment();
