/** @jsxImportSource react */
import Field from '@react/form/components/Field';
import { func, object, shape, string } from 'proptypes';
import React from 'react';
import { useFormState } from 'react-final-form';

const FieldAdapter = ({
  input,
  meta,
  onChangeHandler,
  errorMessage,
  ...props
}) => {
  const { values } = useFormState();
  const inputProps = {
    ...input,
    onChange: (e) => {
      input.onChange(e);
      if (onChangeHandler) {
        onChangeHandler(e);
      }
    },
  };

  if (inputProps?.value === null || inputProps?.value === undefined) {
    inputProps.value = values?.[input.name];
  }

  const hasSubmitError = meta.submitError && !meta.dirtySinceLastSubmit;

  return (
    <Field
      {...inputProps}
      {...props}
      invalid={(meta.error || hasSubmitError) && meta.touched}
      showIcon={false}
      message={
        errorMessage ||
        meta.error ||
        meta?.warning ||
        (hasSubmitError ? meta.submitError : '')
      }
    />
  );
};

FieldAdapter.propTypes = {
  input: shape({
    name: string.isRequired,
  }).isRequired,
  onChange: func,
  meta: object,
  errorMessage: string,
  onChangeHandler: func,
};

export default FieldAdapter;
