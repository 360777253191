/** @jsxImportSource react */

import { required } from '@react/form/helpers/validation';
import TipComponent from '@react/gwizzy/components/TipComponent';
import {
  ACTIONS as ac,
  CONVERSION_FIELDS as conversionFields,
  postConversionFields as fields,
  postConversionSteps as steps,
  searchReasonsMap,
} from '@react/gwizzy/constants';
import { getNameOrYou, isSelf } from '@react/gwizzy/helpers/utils';
import { ROOM_PREFERENCES_OPTIONS } from '@sly/core/constants/families';

export const getNewPostConversionSchema = ({ clientMeta }) => {
  const { skipSearchReasons = false } = clientMeta || {};

  return {
    [steps.lookingFor]: {
      form: {
        getQuestion: () => 'Who are you searching for?',
        getActions: () => {
          return {
            onNext: {
              type: ac.setActivePage,
              text: 'Continue',
              goTo: (values) =>
                isSelf(values) ? steps.age : steps.residentName,
              variant: 'primary',
            },
          };
        },
        fields: [
          {
            getOptions: () => conversionFields.lookingFor.options,
            name: conversionFields.lookingFor.name,
            type: 'boxChoice',
            hasRadio: true,
            validate: required,
          },
        ],
      },
    },
    [steps.residentName]: {
      form: {
        getQuestion: () =>
          'What is the name of the person you are searching for?',
        getActions: () => {
          return {
            onNext: {
              goTo: () => steps.age,
              type: ac.setActivePage,
              text: 'Continue',
              variant: 'primary',
            },
          };
        },
        fields: [
          {
            name: 'residentName',
            type: 'text',
            label: 'Resident Name',
            validate: required,
          },
        ],
      },
    },
    [steps.age]: {
      form: {
        getQuestion: ({ values }) =>
          `What is ${getNameOrYou(values, 'your')} age?`,
        getActions: () => {
          return {
            onNext: {
              type: ac.setActivePage,
              text: 'Continue',
              goTo: () => steps.searchStage,
              variant: 'primary',
            },
          };
        },
        fields: [
          {
            name: 'age',
            type: 'number',
            min: 1,
            max: 150,
            label: 'Age',
            validate: required,
          },
        ],
      },
      tooltip: {
        ToolTipComponent: () => (
          <TipComponent>
            In compliance with senior living community regulations, we can only
            accommodate residents aged 55 and older in our senior living
            communities.
          </TipComponent>
        ),
      },
    },
    [steps.searchStage]: {
      form: {
        getQuestion: ({ values }) =>
          `Where ${getNameOrYou(values, 'areYou')} in ${
            isSelf(values) ? 'your' : 'their'
          } senior living journey?`,
        getActions: () => {
          return {
            onNext: {
              type: ac.setActivePage,
              text: 'Continue',
              goTo: () =>
                skipSearchReasons ? steps.interests : steps.searchReasons,
              variant: 'primary',
            },
          };
        },
        fields: [
          {
            getOptions: () => conversionFields.searchStage.options,
            name: conversionFields.searchStage.name,
            type: 'boxChoice',
            hasRadio: true,
            validate: required,
          },
        ],
      },
    },
    [steps.searchReasons]: {
      form: {
        getQuestion: ({ values }) =>
          ` Why ${getNameOrYou(values, 'areYou')} considering this move?`,
        formLabel: 'Select all that apply.',
        getActions: () => ({
          onNext: {
            goTo: (_) => steps.interests,
            type: ac.setActivePage,
            text: 'Continue',
            variant: 'primary',
          },
        }),
        fields: [
          {
            getOptions: ({ formState }) => {
              const { values } = formState;
              return !values?.lookingFor || isSelf(values)
                ? conversionFields.searchReasons.options
                : conversionFields.searchReasonsForOthers.options;
            },
            name: conversionFields.searchReasons.name,
            type: 'boxChoice',
            multiChoice: true,
            validate: required,
            onChangeHandler: ({ useForm }) => {
              const { change } = useForm;
              change(conversionFields.searchReasonsOther.name, null);
            },
            parse: (value) => {
              const newValue = value?.[value?.length - 1];
              return newValue === searchReasonsMap.other.value
                ? [newValue]
                : value.filter((v) => v !== searchReasonsMap.other.value);
            },
          },
          {
            name: conversionFields.searchReasonsOther.name,
            type: 'text',
            label: 'What is the reason for your move?',
            validate: required,
            hidden: ({ values }) =>
              !(
                values?.searchReasons?.length &&
                values.searchReasons.includes(searchReasonsMap.other.value)
              ),
          },
        ],
      },
    },
    [steps.interests]: {
      form: {
        getQuestion: ({ values }) =>
          `What are ${getNameOrYou(values, 'your')} interests?`,
        formLabel: 'Select all that apply.',
        getActions: () => {
          return {
            onNext: {
              type: ac.setActivePage,
              text: 'Continue',
              goTo: () => steps.roomPreferences,
              variant: 'primary',
            },
          };
        },
        fields: [
          {
            getOptions: () => fields.interests.options,
            name: 'interests',
            type: 'boxChoice',
            multiChoice: true,
            validate: required,
          },
        ],
      },
    },

    [steps.roomPreferences]: {
      form: {
        getQuestion: ({ values }) =>
          `What type of room ${getNameOrYou(values, 'areYou')} looking for?`,
        getActions: () => {
          return {
            onNext: {
              type: ac.setActivePage,
              text: 'Continue',
              goTo: () => steps.assistances,
              variant: 'primary',
            },
          };
        },
        fields: [
          {
            getOptions: () =>
              ROOM_PREFERENCES_OPTIONS.filter((o) => o.value !== 'Other'),
            name: fields.roomPreferences.name,
            type: 'boxChoice',
            hasRadio: true,
            multiChoice: true,
            validate: required,
          },
        ],
      },
      tooltip: {
        ToolTipComponent: () => (
          <TipComponent>
            Various room types are offered at each community, catering to your
            budget and lifestyle preferences.
          </TipComponent>
        ),
      },
    },

    [steps.assistances]: {
      form: {
        getQuestion: () => 'Is anyone else helping with the search?',
        getActions: () => ({
          onNext: {
            type: ac.setActivePage,
            text: 'Continue',
            goTo: () => steps.moreInfoFromLead,
            variant: 'primary',
          },
        }),
        fields: [
          {
            getOptions: () => fields.assistances.options,
            name: fields.assistances.name,
            type: 'boxChoice',
            hasRadio: true,
            multiChoice: true,
            validate: required,
            parse: (value) => {
              const newValue = value?.[value?.length - 1];
              return newValue === 'self'
                ? [newValue]
                : value.filter((v) => v !== 'self');
            },
          },
        ],
      },
    },
    [steps.moreInfoFromLead]: {
      form: {
        getQuestion: () => 'Is there anything else you would like us to know?',
        getActions: () => {
          return {
            onNext: {
              type: ac.submit,
              text: 'Submit',
              variant: 'primary',
            },
          };
        },
        fields: [
          {
            getOptions: () => [
              {
                label: 'No, that is all. Thank you!',
                value: 'No',
              },
              {
                label: 'Yes',
                value: 'Yes',
              },
            ],
            name: 'moreInfoFromLeadChoice',
            type: 'boxChoice',
            hasRadio: true,
            multiChoice: false,
            validate: required,
          },
          {
            name: 'moreInfoFromLead',
            type: 'textarea',
            rows: '5',
            label: 'Please add your comment here',
            validate: required,
            hidden: ({ values }) =>
              !values?.moreInfoFromLeadChoice ||
              values?.moreInfoFromLeadChoice === 'No',
          },
        ],
      },
    },
  };
};
