import { formatAddress, getTagsDetailsReact } from '@common/helpers/community';
import getIsPricingHidden from '@common/helpers/getIsPricingHidden';
import { getDefaultCommunityImageUrl } from '@common/helpers/profile';
import { eventNames, useEvents } from '@react/services/events';
import { formatMoney } from '@sly/core/helpers/numbers';

const useCommunityCard = ({ entity }) => {
  const { events } = useEvents();

  if (!entity) {
    return {};
  }

  const {
    name,
    gallery,
    tags,
    addressString,
    id,
    description,
    propInfo = {},
    propRatings,
    startingRate,
    maxRate,
    defaultImageSrc,
    defaultImageAlt,
    secondLine,
    address,
    slyScore,
  } = entity;
  const { estimatedPricingOverride, communityDescription } = propInfo;
  const { reviewsValue, numReviews } = propRatings || entity;
  let images = [];
  if (gallery) {
    ({ images = [] } = gallery);
  }

  const isVerified = tags && tags?.includes('VERIFIED');
  const isNewConstruction = tags && tags?.includes('NEW CONSTRUCTION');
  const isAward = tags && tags?.includes('BEST OF');

  const tagsArray = getTagsDetailsReact({
    isVerified,
    isNewConstruction,
    isAward,
  });

  // images?.length === 0 &&
  //   searchResourceData?.communitySearchData?.hasStreetView &&
  //   images.push({
  //     id: 'street-view',
  //     name: 'Street View',
  //     description: 'Google street view',
  //     src: `https://maps.googleapis.com/maps/api/streetview?size=1279x680&location=${encodeURIComponent(
  //       addressString
  //     )}&fov=80&heading=70&pitch=0&key=${gMapsApiKey}&return_error_code=true`,
  //     path: '',
  //   });

  const galleryImages = images.map((img, i) => ({
    ...img,
    src: img.sd,
    alt: `${name} ${i + 1}`,
  }));
  const hasImages = !!galleryImages.length;
  const placeholder = getDefaultCommunityImageUrl(id, propInfo?.communitySize);
  let imagePath;
  let imageSrc;
  let imageAlt;

  // Use Default Image if available
  // Required because community image can be configurable by starpi
  if (defaultImageSrc) {
    imageSrc = defaultImageSrc;
    imageAlt = defaultImageAlt;
  } else {
    if (!hasImages) {
      imageSrc = placeholder;
    } else {
      imagePath = galleryImages[0].path;
      imageAlt = galleryImages[0].alt;
    }
  }

  const typeCare =
    entity.care ||
    entity.typeCare ||
    propInfo.typeCare ||
    (secondLine ? secondLine.split(' \u00B7 ') : []);

  const capacity = propInfo.capacity || entity.capacity;

  const formattedMoney = `${formatMoney(startingRate)}${
    maxRate && maxRate !== 0 ? ' - ' + formatMoney(maxRate) : ''
  }`;

  // const isPricingHidden = typeCare ? getIsPricingHidden(typeCare) : true;
  // eslint-disable-next-line no-nested-ternary
  const isPricingHidden = secondLine
    ? getIsPricingHidden(secondLine)
    : typeCare
    ? getIsPricingHidden(typeCare)
    : true;

  const handleReadMoreClick = (displaySecondary) => {
    events.track(eventNames.ButtonClicked, {
      text: displaySecondary ? 'Read Less' : 'Read More',
      location: 'Entity tile',
      communitySlug: id,
    });
  };

  let formattedAddress = addressString;
  if (!formattedAddress && address) {
    formattedAddress = formatAddress(address);
  }

  return {
    name,
    tags,
    addressString: formattedAddress,
    id,
    description: description || communityDescription,
    startingRate,
    maxRate,
    estimatedPricingOverride,
    reviewsValue,
    numReviews,
    imagePath,
    imageSrc,
    imageAlt,
    typeCare,
    capacity,
    handleReadMoreClick,
    formattedMoney,
    isPricingHidden,
    secondLine,
    isVerified,
    tagsArray,
    slyScore,
  };
};

export default useCommunityCard;
