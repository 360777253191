import { Logger } from 'cloudwatch-front-logger';
import { WithRouterProps } from 'next/dist/client/with-router';
import { withRouter } from 'next/router';
import React, { Component, ErrorInfo } from 'react';

let loggerPromise: Promise<{ logger: Logger }>;

async function getLogger(): Promise<Logger> {
  if (!loggerPromise) {
    loggerPromise = import('@react/services/logger');
  }

  return (await loggerPromise).logger;
}

// start preloading logger
if (typeof window !== 'undefined') {
  getLogger();
}

class ErrorLogBoundary extends Component<
  WithRouterProps & { children: React.ReactNode }
> {
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    getLogger().then((logger) =>
      logger.onError(error, {
        url: this.props.router.asPath,
        ...errorInfo,
      })
    );
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ErrorLogBoundary);
