/** @jsxImportSource react */
import clsxm from '@common/lib/clsxm';
import getInputComponent from '@react/form/components/Field/getInputComponent';
import InputMessage from '@react/form/components/InputMessage';
import { Checkmark, Close, Warning } from '@sly/icons/react';
import {
  array,
  arrayOf,
  bool,
  node,
  number,
  object,
  oneOf,
  oneOfType,
  string,
} from 'proptypes';
import React from 'react';

const textTypeInputs = ['email', 'iconInput'];
const getInputType = (type) => (textTypeInputs.includes(type) ? 'text' : type);

const Field = ({
  message,
  name,
  invalid,
  warning,
  success,
  label,
  type,
  placeholder,
  className,
  value,
  hideErrors,
  labelRight,
  wideWidth,
  hideValue,
  showCharacterCount,
  options,
  required,
  leftMargin,
  forceRow,
  classNameLabelWrapper,
  hideBorder,
  ...props
}) => {
  const inputProps = {
    id: name,
    name,
    value: hideValue ? null : value,
    type: getInputType(type),
    invalid: invalid && !hideErrors,
    warning,
    placeholder,
    'aria-describedby': `${name}Error`,
    options,
    label,
    hideBorder,
    ...props,
  };
  const InputComponent = getInputComponent(type);
  const renderInputFirst =
    (type === 'checkbox' && !options) ||
    type === 'radio' ||
    type === 'file' ||
    type === 'boxChoice' ||
    type === 'toggle';
  const valueLength = inputProps.value ? inputProps.value.length : 0;
  if (type === 'date') {
    inputProps.selected = inputProps.value;
    inputProps.placeholderText = inputProps.placeholder;
  }
  if (type === 'button' && inputProps.buttonType) {
    inputProps.type = inputProps.buttonType;
  }

  showCharacterCount = showCharacterCount && inputProps.maxLength;
  const labelSection = (
    <>
      {type !== 'toggleButton' &&
        type !== 'boolean' &&
        (label || labelRight) && (
          <div
            className={clsxm(
              'flex shrink-0 grow-0 justify-between align-middle',
              wideWidth ? 'w-full' : 'w-max',
              type === 'checkbox' && !!options === true && 'mb-m',
              wideWidth && 'basis-[255px]',
              classNameLabelWrapper
            )}
            data-tp-id='gen-div-92b42b28-c6db-40b7-9704-af0051291f3c'
          >
            {label && (
              <label
                htmlFor={inputProps.id}
                className='font-b-s mb-xxs block text-slate-base'
              >
                {label}
                {required && (
                  <div
                    className='inline text-red-base'
                    data-tp-id='gen-div-4cbf96c5-11be-47dc-99ea-337b73e1edd7'
                  >
                    *
                  </div>
                )}
              </label>
            )}
            {labelRight && !wideWidth && (
              <span data-tp-id='gen-span-c66fce13-2d1b-42bf-acf5-30cc0dca6e6a'>
                {labelRight}
              </span>
            )}
          </div>
        )}
    </>
  );

  return (
    <div
      className={clsxm(
        'relative mb-m flex',
        forceRow
          ? 'flex-col md:flex-row'
          : renderInputFirst
          ? 'flex-row'
          : 'flex-col',
        leftMargin && 'md:ml-m',
        wideWidth && 'flex-row',
        className
      )}
      data-tp-id='gen-div-a3cbd707-2a67-4bb6-b103-c666404b22f2'
    >
      {!renderInputFirst && labelSection}
      <div
        className={clsxm(
          'relative flex flex-1 md:basis-auto',
          wideWidth && 'md:mb-0',
          !hideErrors &&
            message &&
            (invalid || warning) &&
            !renderInputFirst &&
            'mb-xs'
        )}
        data-tp-id='gen-div-8dca3a6b-b6d7-46d3-8f58-2712804cc855'
      >
        <div
          className={clsxm(
            'input-wrapper flex h-max w-full flex-col flex-wrap',
            showCharacterCount && 'text-right'
          )}
          data-tp-id='gen-div-0481dc26-c108-4b44-9ac3-f59d9437542d'
        >
          <InputComponent
            {...inputProps}
            // marginRight={type === 'checkbox' && !!options === true ? 'large' : undefined}
            type={type}
          />
          {showCharacterCount && (
            <div
              className={clsxm(
                'font-b-xs mt-m text-right',
                (valueLength / inputProps.maxLength) * 100 > 90
                  ? 'text-red-base'
                  : 'text-slate-base'
              )}
              data-testid='CharCount'
              data-tp-id='gen-div-f0b4832e-c352-4695-b057-c915068f54bb'
            >
              {valueLength}/{inputProps.maxLength}
            </div>
          )}
        </div>
        {success && (
          <Checkmark
            color='text-green-base'
            size='m'
            className='absolute right-m bottom-m'
          />
        )}
      </div>
      {renderInputFirst && labelSection}
      {labelRight && wideWidth && (
        <div
          className='ml-l align-middle md:ml-0'
          data-tp-id='gen-div-3c6b8574-de33-466c-87bf-57b2f10b54e5'
        >
          {labelRight}
        </div>
      )}
      {!hideErrors && message && (invalid || warning) && (
        <InputMessage
          className={clsxm(
            invalid ? 'text-red-base' : 'text-green-base',
            wideWidth && 'ml-l w-full',
            'font-b-s'
          )}
          name={`${name}${invalid ? 'Error' : 'Warning'}`}
          Icon={invalid ? Close : Warning}
          message={message}
        />
      )}
    </div>
  );
};

Field.propTypes = {
  name: string.isRequired,
  value: oneOfType([string, number, array, bool, object]),
  size: string,
  className: string,
  invalid: bool,
  warning: bool,
  success: bool,
  message: string,
  hideErrors: bool,
  leftMargin: bool,
  label: node,
  required: bool,
  showCharacterCount: bool,
  noLeftMarginStyledInputMessage: bool,
  type: oneOf([
    'textarea',
    'select',
    'choice', // react-select
    'autocomplete',
    'create',
    'community',
    'agent',
    'user',
    'communitychoice',
    'singlechoice',
    'multiplechoice',
    'buttonlist',
    'boxChoice',
    'dateChoice',
    'slider',
    'text',
    'boolean',
    'file',
    'phone',
    'email',
    'password',
    'checkbox',
    'radio',
    'rating',
    'number',
    'iconInput',
    'hidden',
    'date',
    'locationSearch',
    'daterange',
    'richtextarea',
    'button',
    'toggle',
    'toggleOptions',
    'toggleButton',
    'nps',
    'multiDate',
  ]),
  placeholder: string,
  labelRight: node,
  wideWidth: bool,
  hideValue: bool,
  options: arrayOf(object),
  classNameLabelWrapper: string,
  isChatField: bool,
};

Field.defaultProps = {
  type: 'text',
  forceRow: false,
  isChatField: false,
};

export default Field;
