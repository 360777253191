import React, { forwardRef, Ref } from 'react';
import { SimpleIconProps } from '../icon.types';
import Icon from './Icon';

const svg = `<svg viewBox="0 0 24 24" fill="currentColor">
  <path d="M5.07175 21.2033C4.44203 21.2033 3.90543 20.9815 3.46195 20.538C3.01846 20.0946 2.79672 19.558 2.79672 18.9282V5.07175C2.79672 4.44203 3.01846 3.90543 3.46195 3.46195C3.90543 3.01846 4.44203 2.79672 5.07175 2.79672H18.9282C19.558 2.79672 20.0946 3.01846 20.538 3.46195C20.9815 3.90543 21.2033 4.44203 21.2033 5.07175V18.9282C21.2033 19.558 20.9815 20.0946 20.538 20.538C20.0946 20.9815 19.558 21.2033 18.9282 21.2033H5.07175ZM5.07175 18.9282H18.9282V5.07175H5.07175V18.9282ZM7.02392 17.0717H16.9761C17.208 17.0717 17.3779 16.9681 17.4859 16.7609C17.5938 16.5536 17.5752 16.3547 17.4299 16.1641L14.7038 12.4891C14.5918 12.3359 14.4406 12.2582 14.25 12.2562C14.0594 12.2542 13.9062 12.3299 13.7902 12.4831L11.25 15.8804L9.45977 13.4831C9.34384 13.3299 9.19058 13.2542 9 13.2562C8.80941 13.2582 8.65815 13.3359 8.5462 13.4891L6.5701 16.1641C6.42481 16.3547 6.40616 16.5536 6.51412 16.7609C6.62209 16.9681 6.79202 17.0717 7.02392 17.0717Z"/>
</svg>`;

const Image = forwardRef(
  (props: SimpleIconProps, ref: Ref<SVGSVGElement> | undefined) => (
    <Icon ref={ref} name='image' svg={svg} {...props} />
  )
);

Image.displayName = 'ImageIcon';

export default Image;
