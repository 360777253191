const ChatBoxGlobalStyle = `
  body.ChatBox-page-with-sticky-footer #hbl-live-chat-wrapper .olark-launch-button {
    bottom: 6.25rem!important;
  }
  body.ChatBox-footer-reached #hbl-live-chat-wrapper .olark-launch-button {
    bottom: 3.75rem!important;
  }
  .roko-instabot-widget-button  {
    z-index: 10000!important;
    margin-bottom: 4.50rem!important;
  }
  .olark-launch-button {
    z-index: 10000!important;
    margin-bottom: 4.50rem!important;
  }
`;

export default ChatBoxGlobalStyle;
