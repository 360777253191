/** @jsxImportSource react */
import clsxm from '@common/lib/clsxm';
import useCommunityCard from '@react/card/hooks/useCommunityCard';
import { useBreakpoint } from '@react/services/breakpoint';
import { Link } from '@react/system';
import EntityTileBadge from '@sly/frontend/react/card/components/CardBadge';
import { bool, object, string } from 'proptypes';
import { useCallback } from 'react';

import {
  articleTile,
  gallery,
  info,
  link,
  tile,
} from './components/CardClasses';
import EntityTileGallery from './components/CardGallery';
import EntityInfo from './components/CardInfo';

const EntityTile = ({
  entity,
  preloadFirstImage = false,
  event = {},
  intent,
  eventProps = {},
  setHoveredEntity = null,
  className = '',
  bottomPosition = undefined,
  styles = undefined,
  innerRef = undefined,
  like = () => {},
  dislike = () => {},
  liked = false,
  disliked = false,
  ...props
}) => {
  const { tagsArray } = useCommunityCard({ entity });
  const onMouseLeave = useCallback(() => setHoveredEntity?.(null), []);
  const onMouseEnter = useCallback(() => setHoveredEntity?.(entity), [entity]);
  const tagSection = tagsArray?.length > 0 && (
    <div className='absolute top-2 left-2 z-10 flex flex-row flex-wrap gap-1'>
      {tagsArray.map((tag) => (
        <EntityTileBadge key={tag?.text} tag={tag} intent={intent} />
      ))}
    </div>
  );

  const breakpoint = useBreakpoint();

  const newTab = breakpoint?.atLeastLaptop();

  return (
    <Link
      key={entity.id}
      to={entity.url}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      target={newTab && '_blank'}
      className={link({ intent, className })}
      eventProps={eventProps}
      ref={innerRef}
      style={styles}
      data-testid={`entity-tile-${entity.id}`}
      {...props}
    >
      {/* For plus map tiles */}
      {!!bottomPosition && (
        <style jsx>{`
          @media screen and (max-width: 727px) {
            .card-position {
              bottom: calc(${bottomPosition}px + 3%) !important;
              position: absolute;
              display: flex;
              width: 100%;
              justify-content: center;
            }
          }
        `}</style>
      )}
      <article data-testid='card' className={clsxm(articleTile({ intent }))}>
        <div
          className={tile({ intent })}
          data-tp-id='gen-div-a1dd1795-5635-4afe-b204-27edfb617b72'
        >
          <div
            className={gallery({ intent })}
            data-tp-id='gen-div-9057faab-1eeb-4377-ae99-bdf3b985dfa6'
          >
            {tagSection}
            <EntityTileGallery
              entity={entity}
              intent={intent}
              preloadFirstImage={preloadFirstImage}
              newTab={newTab}
            />
          </div>
          <div
            className={info({ intent })}
            data-tp-id='gen-div-0e72a443-df01-43df-9cd3-f49b838907f2'
          >
            <EntityInfo
              like={like}
              dislike={dislike}
              liked={liked}
              disliked={disliked}
              entity={entity}
              event={event}
              intent={intent}
            />
          </div>
        </div>
      </article>
    </Link>
  );
};

EntityTile.propTypes = {
  entity: object,
  className: string,
  preloadFirstImage: bool,
  event: object,
};

export default EntityTile;
