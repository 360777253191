/** @jsxImportSource react */
import clsxm from '@common/lib/clsxm';
import { Close } from '@sly/icons/react';
import React, {
  createContext,
  ForwardedRef,
  forwardRef,
  ForwardRefExoticComponent,
  LegacyRef,
  memo,
  PropsWithoutRef,
  ReactNode,
  RefAttributes,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import ScrollLock from 'react-scrolllock';

const ActiveDialogContext = createContext(false);
import { useChatbox } from '@react/services/chatbox/ChatBoxContext';
import useDialogPolyfill from '@react/shared/hooks/useDialogPolyfill';

const minSwipeDistance = 75;

interface DialogProps {
  closeOnOutsideClick: boolean;
  onRequestClose: () => void;
  open: boolean;
  children: React.ReactNode;
  className?: string;
  trayOnly?: boolean;
}

interface DialogWrapperProps extends DialogProps {
  dialogRef: LegacyRef<HTMLDialogElement> | undefined;
  handleOutsideClick: (event: React.SyntheticEvent) => void;
}

interface DialogBodyProps {
  children: ReactNode;
  className?: string;
}

const Body = forwardRef(
  (
    { className, ...props }: DialogBodyProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const isActive = useContext(ActiveDialogContext);
    return (
      <ScrollLock isActive={isActive}>
        <div
          className={clsxm(className, 'overflow-y-auto p-4 md:p-6')}
          ref={ref}
          {...props}
        />
      </ScrollLock>
    );
  }
);

interface DialogHeaderProps {
  children: ReactNode;
  className?: string;
  onClose: () => void;
  noBorder?: boolean;
  Icon?: ForwardRefExoticComponent<
    PropsWithoutRef<{ size?: string; className?: string; color?: string }> &
      RefAttributes<unknown>
  >;
}

const Header = forwardRef(
  (
    {
      children,
      onClose,
      noBorder,
      className,
      Icon,
      ...props
    }: DialogHeaderProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <div
        className={clsxm(
          className,
          'font-t-m flex h-[76px] flex-shrink-0 flex-grow-0 basis-auto items-center justify-between px-l py-0',
          noBorder ? 'border-none' : 'border-b border-slate-lighter-90'
        )}
        ref={ref}
        {...props}
        data-tp-id='gen-div-2d3cdd5d-8e0c-4c38-adfd-c1475dc398fd'
      >
        <div className='pointer-events-none absolute top-2 right-0 left-0 mx-auto h-1 w-10 rounded border bg-black opacity-20 lg:hidden' />
        <div
          className={`flex items-center ${!!Icon && 'gap-s'}`}
          data-tp-id='gen-div-7a9ba772-00b2-44d9-aa27-ea4472e150ee'
        >
          {!!Icon && (
            <Icon
              className='rounded-full bg-viridian-lighter-90 p-[6px]'
              size='l'
              color='text-viridian-base'
              data-tp-id='gen-Icon-481ece43-73ec-4c81-aebf-40dad835a07a'
            />
          )}

          <h3
            className='font-t-m'
            data-tp-id='gen-h3-e2d58b7d-42ea-44ad-b49f-b4937f4383ec'
          >
            {children}
          </h3>
        </div>

        <button onClick={onClose}>
          <Close
            className='flex-grow-0 cursor-pointer bg-transparent p-0'
            size='m'
          />
        </button>
      </div>
    );
  }
);

const DialogWrapper = memo(
  ({
    onRequestClose,
    handleOutsideClick,
    className,
    open,
    dialogRef,
    children,
    trayOnly,
  }: DialogWrapperProps) => {
    const [transform, setTransform] = useState(0);

    const [touchStart, setTouchStart] = useState<number | null>(null);
    const [touchEnd, setTouchEnd] = useState<number | null>(null);

    const onTouchStart = useCallback((e: React.TouchEvent) => {
      setTouchEnd(null);
      setTouchStart(e.targetTouches[0].clientY);
    }, []);

    const onTouchMove = useCallback(
      (e: React.TouchEvent) => {
        const distance = (touchStart || 0) - (touchEnd || 0);
        if (distance < -5 && open) {
          setTransform(Math.abs(distance));
        }

        setTouchEnd(e.targetTouches[0].clientY);
      },
      [setTransform, touchEnd, touchStart, open]
    );

    const onTouchEnd = useCallback(() => {
      if (!touchStart || !touchEnd) return;
      const distance = touchStart - touchEnd;
      const isSwipeDown = distance < -minSwipeDistance;
      if (isSwipeDown) {
        onRequestClose();
      }
      setTransform(0);
    }, [touchStart, touchEnd, onRequestClose, setTransform]);

    const dialogSyles: Record<string, string | number> = {};
    if (transform) {
      dialogSyles.transform = `translateY(${transform}px)`;
    }

    return (
      <dialog
        ref={dialogRef}
        className={clsxm(
          className,
          'isolate-auto fixed inset-0 mx-auto mt-auto max-h-[90%] min-w-full rounded-t-2xl border border-white p-0 shadow-xl transition-opacity backdrop:bg-gray-400 backdrop:bg-opacity-50',
          !trayOnly &&
            'md:m-auto  md:max-h-[75%] md:w-full md:min-w-[unset] md:max-w-2xl md:rounded-2xl',
          open ? 'flex animate-showDialog' : 'animate-closeDialog'
        )}
        onClick={handleOutsideClick}
        style={{
          ...dialogSyles,
        }}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        {children}
      </dialog>
    );
  }
);

function Dialog({
  closeOnOutsideClick,
  onRequestClose,
  open,
  children,
  className,
  trayOnly,
}: DialogProps) {
  const lastActiveElement = useRef<Element | null>(null);
  const firstRender = useRef(true);
  const timeout = useRef<NodeJS.Timeout | null>(null);
  const dialogRef = useRef<HTMLDialogElement>(null);
  const { setDisableChatBot } = useChatbox();

  useDialogPolyfill(dialogRef);

  useEffect(() => {
    // prevents calling imperative methods on mount since the polyfill will throw an error since we are not using the `open` attribute
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      const dialogNode = dialogRef.current;
      if (open) {
        lastActiveElement.current = document.activeElement;
        if (!dialogNode?.open) {
          setDisableChatBot(true);
          dialogNode?.showModal();
        }
      } else {
        if (dialogNode?.open) {
          timeout.current = setTimeout(() => {
            setDisableChatBot(false);
            dialogNode?.close();
          }, 300);
        }

        (lastActiveElement?.current as HTMLElement)?.focus();
      }
    }

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current as NodeJS.Timeout);
        timeout.current = null;
      }
    };
  }, [open, setDisableChatBot]);

  useEffect(() => {
    const dialogNode = dialogRef.current;
    const handleCancel = (event: Event) => {
      event.preventDefault();
      onRequestClose();
    };

    dialogNode?.addEventListener('cancel', handleCancel);
    return () => {
      dialogNode?.removeEventListener('cancel', handleCancel);
    };
  }, [onRequestClose]);

  const handleOutsideClick = useCallback(
    (event: React.SyntheticEvent) => {
      const dialogNode = dialogRef.current;
      if (closeOnOutsideClick && event.target === dialogNode) {
        onRequestClose();
      }
    },
    [closeOnOutsideClick, onRequestClose]
  );

  return (
    <DialogWrapper
      className={className}
      handleOutsideClick={handleOutsideClick}
      open={open}
      closeOnOutsideClick={closeOnOutsideClick}
      onRequestClose={onRequestClose}
      dialogRef={dialogRef}
      trayOnly={trayOnly}
    >
      <div className='flex max-w-full flex-1 flex-col'>
        <ActiveDialogContext.Provider value={open}>
          {children}

          {open && (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <style jsx global>{`
              body {
                overflow: hidden;
              }
            `}</style>
          )}
        </ActiveDialogContext.Provider>
      </div>
    </DialogWrapper>
  );
}

Dialog.Header = Header;
Dialog.Body = Body;

export default Dialog;
