import React, { forwardRef, Ref } from 'react';
import { SimpleIconProps } from '../icon.types';
import Icon from './Icon';

const svg = `<svg viewBox="0 0 24 24" fill="currentColor">
  <path d="M12 13.4657L14.8043 16.2701C14.9996 16.4654 15.2439 16.562 15.5372 16.56C15.8305 16.5581 16.0748 16.4594 16.2701 16.2641C16.4654 16.0688 16.563 15.8245 16.563 15.5312C16.563 15.2379 16.4654 14.9937 16.2701 14.7984L13.4657 12L16.2701 9.19565C16.4654 9.00036 16.563 8.75607 16.563 8.46277C16.563 8.16947 16.4654 7.92518 16.2701 7.7299C16.0748 7.5346 15.8305 7.43695 15.5372 7.43695C15.2439 7.43695 14.9996 7.5346 14.8043 7.7299L12 10.5342L9.19565 7.7299C9.00037 7.5346 8.75707 7.43695 8.46575 7.43695C8.17445 7.43695 7.93116 7.5346 7.73587 7.7299C7.54057 7.92518 7.44292 8.16947 7.44292 8.46277C7.44292 8.75607 7.54057 9.00036 7.73587 9.19565L10.5342 12L7.7299 14.8043C7.5346 14.9996 7.43795 15.2429 7.43995 15.5342C7.44193 15.8255 7.54057 16.0688 7.73587 16.2641C7.93116 16.4594 8.17545 16.5571 8.46875 16.5571C8.76205 16.5571 9.00634 16.4594 9.20162 16.2641L12 13.4657ZM12 22.2032C10.5848 22.2032 9.25688 21.9358 8.0163 21.4008C6.77572 20.8659 5.69646 20.1394 4.77852 19.2215C3.86059 18.3035 3.13414 17.2243 2.59917 15.9837C2.06421 14.7431 1.79672 13.4152 1.79672 12C1.79672 10.5848 2.06421 9.25688 2.59917 8.0163C3.13414 6.77571 3.86059 5.69646 4.77852 4.77852C5.69646 3.86059 6.77572 3.13414 8.0163 2.59917C9.25688 2.0642 10.5848 1.79672 12 1.79672C13.4152 1.79672 14.7431 2.0642 15.9837 2.59917C17.2243 3.13414 18.3035 3.86059 19.2215 4.77852C20.1394 5.69646 20.8659 6.77571 21.4008 8.0163C21.9358 9.25688 22.2033 10.5848 22.2033 12C22.2033 13.4152 21.9358 14.7431 21.4008 15.9837C20.8659 17.2243 20.1394 18.3035 19.2215 19.2215C18.3035 20.1394 17.2243 20.8659 15.9837 21.4008C14.7431 21.9358 13.4152 22.2032 12 22.2032Z"/>
</svg>`;

const Cancel = forwardRef(
  (props: SimpleIconProps, ref: Ref<SVGSVGElement> | undefined) => (
    <Icon ref={ref} name='cancel' svg={svg} {...props} />
  )
);

Cancel.displayName = 'CancelIcon';

export default Cancel;
