export default function getReferredCommunities(homebase) {
  if (!homebase) {
    return [];
  }

  const client = homebase?.client || {};
  const communitiesInterested = client?.communitiesInterested || [];
  const referredCommunitySlugs =
    client?.uuidAux?.uuidInfo?.referralInfo?.referredCommunitySlugs || [];
  const referredCommunities = communitiesInterested.filter((c) =>
    referredCommunitySlugs.includes(c?.id)
  );

  return referredCommunities;
}
