import { agentId } from '@common/helpers/cta';
import SlyEvent, { objectToEventLabel } from '@common/helpers/events';
import { recordEntityCta } from '@common/helpers/localStorage';
import useHasPricingRequested from '@react/cta/hooks/useHasPricingRequested';
import { normalizeResponse, useQuery } from '@react/services/api';
import {
  PRICING_REQUEST,
  PROFILE_CONTACTED,
} from '@sly/core/constants/actionTypes';
import { useCallback, useEffect, useState } from 'react';
import { isBrowser } from 'sly/config';

const useCta = ({
  mode,
  community,
  isOpenByDefault,
  params,
  isChatMode = false,
  onClose = () => {},
  dontRefetch = false,
}) => {
  const [agent, setAgent] = useState(null);
  const [modalOpened, setModalOpened] = useState(!!isOpenByDefault);
  const getAgent = useQuery('getAgent');

  const {
    completedPricing,
    refetchUUIDActions,
    completedAssessment,
    getPricingRequestedBySlug,
    getPricingRequestedListBySlugs,
  } = useHasPricingRequested({ params });

  useEffect(() => {
    if (completedAssessment && agentId) {
      getAgent({ id: agentId })
        .then((res) => normalizeResponse(res.body))
        .then((res) => setAgent(res))
        .catch((err) => console.error(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createAction = useQuery('createUuidAction');

  const startAssessmentFlow = useCallback(() => {
    if (isBrowser) {
      // to-do sly events
      SlyEvent.getInstance().sendEvent({
        category: 'assessmentWizard',
        action: 'start',
        label: objectToEventLabel(mode),
      });
    }
  }, []);

  const toggleModal = useCallback(() => {
    setModalOpened(!modalOpened);
    SlyEvent.getInstance().sendEvent({
      category: 'assessmentWizard',
      action: `${modalOpened ? 'close' : 'open'}-modal`,
      label: 'layout',
    });
  }, [modalOpened]);

  const handleCompletedAssementModalClose = useCallback(() => {
    toggleModal();
    refetchUUIDActions();
    onClose?.();
  }, [onClose, refetchUUIDActions, toggleModal]);

  const createProfileContactActionBySlug = useCallback(
    async (slug) => {
      try {
        await createAction({
          type: 'UUIDAction',
          attributes: {
            actionType: PROFILE_CONTACTED,
            actionPage: isBrowser ? window.location.pathname : '',
            actionInfo: {
              contactType: PRICING_REQUEST,
              slug,
            },
          },
        });
        recordEntityCta(PRICING_REQUEST, slug);
        refetchUUIDActions();
      } catch (e) {
        console.error(e);
        throw new Error('Create UUID action failed', { cause: e });
      }
    },
    [createAction, refetchUUIDActions]
  );

  const createProfileContactActionBySlugs = useCallback(
    async (slugs) => {
      try {
        await createAction({
          type: 'UUIDAction',
          attributes: {
            actionType: PROFILE_CONTACTED,
            actionPage: isBrowser ? window.location.pathname : '',
            actionInfo: {
              contactType: PRICING_REQUEST,
              slugs,
            },
          },
        });
        refetchUUIDActions();
      } catch (e) {
        console.error(e);
        throw new Error('Create UUID action failed', { cause: e });
      }
    },
    [createAction, refetchUUIDActions]
  );

  const completedAssessmentFlow = useCallback(
    async (evt) => {
      evt?.preventDefault?.();
      if (isBrowser) {
        SlyEvent.getInstance().sendEvent({
          category: 'assessmentWizard',
          action: 'completedStart',
          label: objectToEventLabel(mode),
        });
      }
      if (!completedPricing && community) {
        // send pricing request uuid action

        try {
          await createAction({
            type: 'UUIDAction',
            attributes: {
              actionType: PROFILE_CONTACTED,
              actionPage: isBrowser ? window.location.pathname : '',
              actionInfo: {
                contactType: PRICING_REQUEST,
                slug: community.id,
              },
            },
          });
          recordEntityCta(PRICING_REQUEST, community.id);
          if ((isOpenByDefault || isChatMode) && !dontRefetch) {
            refetchUUIDActions();
          }
          toggleModal();
          onClose?.();
          return;
        } catch (e) {
          console.log(e);
          recordEntityCta(PRICING_REQUEST, community.id);
        }
      }

      toggleModal();
      onClose?.();

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [
      completedPricing,
      community,
      mode,
      createAction,
      isOpenByDefault,
      isChatMode,
      dontRefetch,
      toggleModal,
      onClose,
      refetchUUIDActions,
    ]
  );

  return {
    agent,
    pricingRequested: completedPricing,
    completedAssessment,
    modalOpened,
    setModalOpened,
    startAssessmentFlow,
    completedAssessmentFlow,
    toggleModal,
    isClientSide: isBrowser,
    handleCompletedAssementModalClose,
    getPricingRequestedBySlug,
    createProfileContactActionBySlug,
    createProfileContactActionBySlugs,
    getPricingRequestedListBySlugs,
    refetchUUIDActions,
  };
};

export default useCta;
