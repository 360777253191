import { cva, VariantProps } from 'class-variance-authority';

export const link = cva('hover:no-underline', {
  variants: {
    intent: {
      search: '',
      map: 'z-[10001]',
    },
  },
  defaultVariants: {
    intent: 'search',
  },
});

export const articleTile = cva('bg-white', {
  variants: {
    intent: {
      search: 'border-b border-slate-lighter-90 p-4 md:p-6 mt-0',
      map: 'z-[10001] w-[360px] rounded-lg bg-white md:bottom-[unset] md:left-[unset] md:w-[25rem]',
    },
  },
  defaultVariants: {
    intent: 'search',
  },
});

export const tile = cva('grid h-full', {
  variants: {
    intent: {
      search: [
        'grid-rows-[328px_auto] gap-4 md:grid-rows-none',
        `md:grid-cols-[240px_auto]`,
        'md:gap-6',
      ],
      vertical: 'grid-rows-[200px_auto] gap-4',
      recs: 'grid-rows-[200px_auto] gap-4',
      map: ['grid-cols-[112px_auto] h-[112px] gap-3'],
    },
  },
  defaultVariants: {
    intent: 'search',
  },
});

export const gallery = cva('relative w-full ', {
  variants: {
    intent: {
      search: 'md:h-60',
      map: 'h-28',
    },
  },
});

export const hiddenMap = cva('', {
  variants: {
    intent: {
      map: 'hidden',
    },
  },
});

export const hiddenRecs = cva('', {
  variants: {
    intent: {
      recs: 'hidden',
    },
  },
});

export const showRecs = cva('hidden', {
  variants: {
    intent: {
      recs: '!flex',
    },
  },
});

export const button = cva('w-full md:w-auto', {
  variants: {
    intent: {
      vertical: '!w-full',
      recs: '!w-full',
    },
  },
});

export const ratingAndPricing = cva(
  ['flex items-center justify-between', 'pt-m'],
  {
    variants: {
      intent: {
        map: 'pt-2',
      },
    },
  }
);

export const tags = cva(
  'font-b-xs mr-1 rounded-[80px] border px-2 py-1 text-slate-lighter-40',
  {
    variants: {
      intent: {
        search:
          'font-b-xs mr-1 rounded-[80px] border px-2 py-1 text-slate-lighter-40',
        map: 'px-0.5 py-0.5',
      },
    },
    defaultVariants: {
      intent: 'search',
    },
  }
);

export const info = cva('h-full w-full overflow-hidden', {
  variants: {
    intent: {
      map: 'pr-3 pt-3 pb-3',
    },
  },
});

export const galleryImage = cva('rounded-lg', {
  variants: {
    intent: {
      map: 'rounded-r-none',
    },
  },
});

export const badgeText = cva('font-t-xxs-azo whitespace-nowrap', {
  variants: {
    intent: {
      map: 'hidden',
    },
  },
});

export const badge = cva('rounded-[80px] px-2 py-1 left-2 top-2', {
  variants: {
    intent: {
      map: 'left-1 top-1 !px-0.5 !py-0.5',
    },
  },
});

export const badgeIcon = cva('rounded-tl-[4px] rounded-bl-[4px]', {
  variants: {
    intent: {
      map: 'rounded',
    },
  },
});

export const entityText = cva('', {
  variants: {
    intent: {
      search: 'line-clamp-3 md:line-clamp-2',
      vertical: 'line-clamp-3',
    },
  },
});

export type TileIntent = VariantProps<typeof tile>['intent'];
