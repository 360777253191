/** @jsxImportSource react */
import { formatRating } from '@common/helpers/rating';
import clsxm from '@common/lib/clsxm';
import Rating from '@react/shared/components/Rating';
import { Button } from '@react/system';
import { cva } from 'class-variance-authority';
import cx from 'classnames';
import { bool, func, number, string } from 'proptypes';

export const ratingBodySingle = cva('', {
  variants: {
    intent: {
      search: 'md:hidden',
      map: 'block',
      vertical: 'block',
      recs: 'block',
    },
  },
  defaultVariants: {
    intent: 'search',
  },
});

export const ratingBodyAll = cva('', {
  variants: {
    intent: {
      search: 'hidden md:block',
      map: 'hidden',
      vertical: 'hidden',
      recs: 'hidden',
    },
  },
  defaultVariants: {
    intent: 'search',
  },
});

export const ratingTextReviews = cva('', {
  variants: {
    intent: {
      search: 'hidden md:block',
      map: 'hidden',
      vertical: 'hidden md:block',
      recs: 'hidden md:block',
    },
  },
  defaultVariants: {
    intent: 'search',
  },
});

export const ratingTextNumber = cva('md:hidden', {
  variants: {
    intent: {
      map: 'block md:!block',
      vertical: 'block',
      recs: 'block',
    },
  },
  defaultVariants: {
    intent: 'normal',
  },
});

function ReviewText({ numReviews, textClassName, reviewsText, intent }) {
  return (
    <>
      <div
        className={cx(textClassName, ratingTextReviews({ intent }))}
        data-tp-id='gen-div-abae4dde-bd53-4616-9671-28b9533ae415'
      >
        (
        <span className='underline'>
          {numReviews}
          {reviewsText && (numReviews === 1 ? ' review' : ' reviews')}
        </span>
        )
      </div>
      <div className={cx(textClassName, ratingTextNumber({ intent }))}>
        (<span className='underline'> {numReviews}</span>)
      </div>
    </>
  );
}

const RatingBody = ({
  rating,
  numReviews,
  seedId,
  color,
  variation,
  size,
  reviewsText,
  wrapperClassName,
  showNotYetRatedText,
  onClick,
  intent,
  numberOnly,
}) => {
  let ratingText = '';
  if (rating > 0) {
    ratingText = formatRating(rating);
  } else if (showNotYetRatedText) {
    ratingText = 'Not yet rated';
  }
  return (
    <div
      className={clsxm(
        'cursor-pointer gap-xxs',
        wrapperClassName,
        'flex items-center  hover:no-underline'
      )}
      data-tp-id='gen-div-d776e69e-4b42-4251-8682-9419a3b47b84'
      onClick={onClick}
    >
      {(rating > 0 || showNotYetRatedText) && !numberOnly && (
        <>
          <Rating
            seedId={seedId}
            value={rating}
            color={color}
            variation={variation}
            font={size}
            className={ratingBodyAll({ intent })}
          />
          <Rating
            seedId={seedId}
            value={rating}
            color={color}
            variation={variation}
            font={size}
            className={ratingBodySingle({ intent })}
            innerClassName='w-[1rem]'
            viewBox='0 0 24 24'
            loopCount={1}
          />
        </>
      )}
      {rating > 0 && ratingText && !numberOnly && (
        <div
          className='font-b-s mr-xxs ml-1 h-[18px] font-medium text-slate-base'
          data-tp-id='gen-div-a14be57b-141b-4cff-a7e6-f68693737edb'
        >
          {ratingText}
        </div>
      )}
      {(rating !== 0 || showNotYetRatedText) && (
        <ReviewText
          numReviews={numReviews}
          reviewsText={reviewsText}
          intent={intent}
          textClassName='font-b-s'
        />
      )}
    </div>
  );
};

const CommunityRating = ({
  rating,
  numReviews,
  size,
  color,
  variation,
  goToReviews,
  seedId,
  reviewsText,
  communitySlug,
  wrapperClassName,
  showNotYetRatedText,
  onClick = () => {},
  linkHref,
  intent,
  textClassName,
  numberOnly = false,
}) => {
  const buttonProps = {
    href: linkHref,
    onClick: goToReviews,
    eventProps: {
      text: 'Reviews',
      location: 'summary action list',
      communitySlug,
    },
  };

  if (!goToReviews && !linkHref) {
    return (
      <RatingBody
        rating={rating}
        numberOnly={numberOnly}
        numReviews={numReviews}
        seedId={seedId}
        color={color}
        variation={variation}
        size={size}
        reviewsText={reviewsText}
        showNotYetRatedText={showNotYetRatedText}
        wrapperClassName={wrapperClassName}
        onClick={onClick}
        intent={intent}
        textClassName={textClassName}
      />
    );
  }

  return (
    <Button
      variant='custom'
      className={clsxm(
        wrapperClassName,
        'flex items-center gap-xxs !p-0 text-viridian-base hover:no-underline'
      )}
      {...buttonProps}
      data-tp-id='gen-Link-82d989a7-8141-43c8-a98b-d8008e8ed53b'
    >
      <RatingBody
        rating={rating}
        numReviews={numReviews}
        seedId={seedId}
        color={color}
        variation={variation}
        numberOnly={numberOnly}
        size={size}
        reviewsText={reviewsText}
        showNotYetRatedText={showNotYetRatedText}
        textClassName={textClassName}
      />
    </Button>
  );
};

CommunityRating.propTypes = {
  description: string,
  rating: number.isRequired,
  numReviews: number,
  goToReviews: func,
  size: string,
  color: string,
  variation: string,
  className: string,
  seedId: string,
  reviewsText: bool,
  communitySlug: string,
  wrapperClassName: string,
  showNotYetRatedText: bool,
  onClick: func,
  textClassName: string,
  linkHref: string,
};

CommunityRating.defaultProps = {
  size: 'body-medium',
  color: 'primary',
  reviewsText: false,
  showNotYetRatedText: false,
  textClassName: 'text-viridian-base',
  intent: undefined,
};

export default CommunityRating;
