export function abbreviatePrice(value: number) {
  return (
    '$' +
    Intl.NumberFormat('en-US', {
      notation: 'compact',
      maximumFractionDigits: 1
    }).format(value)
  );
}

export function abbreviatePriceWithOptions(
  value: number,
  options: { notation: 'compact' | 'standard'; maximumFractionDigits?: number }
) {
  return '$' + Intl.NumberFormat('en-US', options).format(value);
}
