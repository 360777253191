import React, { forwardRef, Ref } from 'react';
import { SimpleIconProps } from '../icon.types';
import Icon from './Icon';

const svg = `<svg viewBox="0 0 24 24" fill="currentColor">
  <path d="M10.5821 13.5907L8.5875 11.5962C8.38025 11.3889 8.11901 11.2853 7.8038 11.2853C7.48858 11.2853 7.22735 11.3889 7.0201 11.5962C6.81286 11.8034 6.71024 12.0647 6.71222 12.3799C6.71422 12.6951 6.81884 12.9563 7.02607 13.1636L9.78042 15.9179C10.0098 16.1458 10.2773 16.2598 10.5831 16.2598C10.8889 16.2598 11.1558 16.1458 11.3837 15.9179L16.944 10.3576C17.1513 10.1504 17.2549 9.89013 17.2549 9.5769C17.2549 9.26368 17.1513 9.00345 16.944 8.7962C16.7368 8.58895 16.4755 8.48532 16.1603 8.48532C15.8451 8.48532 15.5839 8.58895 15.3766 8.7962L10.5821 13.5907ZM12 22.2032C10.5848 22.2032 9.25691 21.9356 8.01637 21.4003C6.77584 20.865 5.69675 20.1385 4.7791 19.2209C3.86145 18.3032 3.13497 17.2242 2.59967 15.9836C2.06437 14.7431 1.79672 13.4152 1.79672 12C1.79672 10.5848 2.06437 9.25691 2.59967 8.01637C3.13497 6.77584 3.86145 5.69675 4.7791 4.7791C5.69675 3.86145 6.77584 3.13497 8.01637 2.59967C9.25691 2.06437 10.5848 1.79672 12 1.79672C13.4152 1.79672 14.7431 2.06437 15.9836 2.59967C17.2242 3.13497 18.3032 3.86145 19.2209 4.7791C20.1385 5.69675 20.865 6.77584 21.4003 8.01637C21.9356 9.25691 22.2033 10.5848 22.2033 12C22.2033 13.4152 21.9356 14.7431 21.4003 15.9836C20.865 17.2242 20.1385 18.3032 19.2209 19.2209C18.3032 20.1385 17.2242 20.865 15.9836 21.4003C14.7431 21.9356 13.4152 22.2032 12 22.2032Z"/>
</svg>`;

const CheckmarkCircle = forwardRef(
  (props: SimpleIconProps, ref: Ref<SVGSVGElement> | undefined) => (
    <Icon ref={ref} name='checkmarkcircle' svg={svg} {...props} />
  )
);

CheckmarkCircle.displayName = 'CheckmarkCircleIcon';

export default CheckmarkCircle;
