// import ExitIntentModalContainer from '@react/shared/components/ExitIntentModalContainer';
import {
  addToLocalStorage,
  retrieveLocalStorage,
} from '@common/helpers/localStorage';
import { useUser } from '@react/services/auth';
import { useChatbox } from '@react/services/chatbox/ChatBoxContext';
import { usePopups } from '@react/services/popupsContext';
import { useRetentionPopup } from '@react/services/retentionPopup/RetentionPopupProvider';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { isBrowser, isServer } from 'sly/config';
const ExitIntentModalContainer = dynamic(() =>
  import(
    /* webpackChunkName: "hydrateCarousel" */ '@react/shared/components/ExitIntentModalContainer'
  )
);
import {
  RETENTION_POPUP_DISABLED_PAGES,
  RETENTION_POPUP_FOCUS_TIMEOUT,
  RETENTION_POPUP_MODAL_SHOWN,
  RETENTION_POPUP_MOUSEOUT_TIMEOUT,
} from '@sly/core/constants/retentionPopup';

const RetentionPopup = ({ exitIntentSchema }) => {
  const { user } = useUser();
  const router = useRouter();
  const ifvisible = useRef(null);
  const renderTime = useRef(null);
  const lastBlur = useRef(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isChatBotActive } = useChatbox();
  const { disableRetentionPopup } = useRetentionPopup();
  const { isPopupActive, setPopupActive } = usePopups();

  const modalShown = () => {
    const { asPath: pathname } = router;
    return (
      isModalOpen ||
      retrieveLocalStorage(RETENTION_POPUP_MODAL_SHOWN) ===
        RETENTION_POPUP_MODAL_SHOWN ||
      user ||
      RETENTION_POPUP_DISABLED_PAGES.some((regex) => regex.test(pathname)) ||
      isChatBotActive() ||
      disableRetentionPopup ||
      isPopupActive()
    );
  };

  useEffect(() => {
    if (isModalOpen) {
      setPopupActive(true);
    } else {
      setPopupActive(false);
    }
  }, [isModalOpen]);

  const showVariantModal = () => {
    console.log(modalShown());
    if (!modalShown()) {
      setIsModalOpen(true);
      // eslint-disable-next-line no-undef
      removeAllHandlers();
      addToLocalStorage(
        RETENTION_POPUP_MODAL_SHOWN,
        RETENTION_POPUP_MODAL_SHOWN
      );
    }
  };

  const onMouseoutHandler = (e) => {
    const currentTime = new Date().getTime();
    const activeTime = Math.abs(currentTime - renderTime.current);

    // Get the current viewport width.
    const vpWidth = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    );

    // If the current mouse X position is within 50px of the right edge
    // of the viewport, return.
    if (e.clientX >= vpWidth - 50) return;

    // If the current mouse Y position is not within 50px of the top
    // edge of the viewport, return.
    if (e.clientY >= 50) return;

    // Reliable, works on mouse exiting window and
    // user switching active program

    const from = e.relatedTarget || e.toElement;
    const timeout =
      exitIntentSchema?.data?.exitModalTimeout ||
      RETENTION_POPUP_MOUSEOUT_TIMEOUT;

    if (!from && activeTime >= timeout) {
      showVariantModal();
    }
  };

  useEffect(() => {
    if (isBrowser) {
      ifvisible.current = require('ifvisible.js');
      if (user || isServer || modalShown()) {
        return;
      }
      renderTime.current = new Date().getTime();
      console.log('retention popup - adding handlers');
      document.addEventListener('mouseout', onMouseoutHandler);

      return removeAllHandlers;
    }
  }, [disableRetentionPopup]);

  const onPopstateHandler = () => {
    showVariantModal();
  };
  const onBlur = () => {
    lastBlur.current = new Date().getTime();
  };

  const onFocus = () => {
    const currentTime = new Date().getTime();
    const inactiveTime = Math.abs(currentTime - lastBlur.current);

    if (inactiveTime >= RETENTION_POPUP_FOCUS_TIMEOUT) {
      showVariantModal();
    } else {
      lastBlur.current = currentTime;
    }
  };

  const removeAllHandlers = () => {
    console.log('retention popup - removing handlers');

    window.removeEventListener('popstate', onPopstateHandler);
    document.removeEventListener('mouseout', onMouseoutHandler);

    ifvisible.current.off('blur', onBlur);
    ifvisible.current.off('focus', onFocus);
  };

  useEffect(() => {
    if (user) {
      removeAllHandlers();
    }
  }, [user]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <ExitIntentModalContainer
      isOpen={isModalOpen}
      onClose={toggleModal}
      location={router}
      exitIntentSchema={exitIntentSchema}
    />
  );
};

export default RetentionPopup;
