/** @jsxImportSource react */
import React, { forwardRef } from 'react';

interface ProgressBarProps {
  maxValue?: number;
  minValue?: number;
  value: number;
  backgroundColor?: string;
  className?: string;
}

const ProgressBar = forwardRef<HTMLDivElement, ProgressBarProps>(
  (
    {
      maxValue = 100,
      minValue = 0,
      value,
      backgroundColor = 'bg-viridian-base',
      className,
    },
    ref
  ) => {
    const percentage = (value / (maxValue - minValue)) * 100;
    const width = `${percentage}%`;
    return (
      <div
        className={`h-1 w-full rounded-[40px] bg-slate-lighter-80 text-right ${className}`}
        ref={ref}
      >
        <div
          className={`h-full rounded-l-[40px] text-right ${backgroundColor}`}
          style={{ width, transition: 'width 1s' }}
        />
      </div>
    );
  }
);

export default ProgressBar;
