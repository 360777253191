/** @jsxImportSource react */
import clsxm from '@sly/frontend/common/lib/clsxm';
import { Button } from '@sly/frontend/react/system';
import { useState } from 'react';

export interface TabStore {
  activeTab: string;
}

export interface TabsProps {
  tabs: { tab: string; id: string }[] | string[];
  store?: TabStore;
  initialTab?: string;
  wrapperClass?: string;
  tabClass?: string;
  headerClass?: string;
  textActiveClass?: string;
  textInactiveClass?: string;
  textClass?: string;
  contentClass?: string;
  eventProps?: Record<string, any> | Record<string, any>[];
  rightHeader?: JSX.Element;
  leftHeader?: JSX.Element;
  footer?: JSX.Element;
  content: JSX.Element[];
  onTabChange?: (tab: string) => void;
}
// A little hacky but makes the tabs array simpler. Slots don't like names with spaces apparently
export const TabbedContent = ({
  initialTab = '0',
  tabs,
  wrapperClass,
  eventProps,
  tabClass = '',
  headerClass = '',
  textActiveClass = '',
  textInactiveClass = '',
  textClass = '',
  onTabChange,
  rightHeader,
  footer,
  content,
  leftHeader,
}: TabsProps) => {
  const [activeTab, setActiveTab] = useState(initialTab);

  return (
    <div
      className={clsxm('rounded border border-slate-lighter-90', wrapperClass)}
    >
      <div
        className={clsxm(
          'flex w-full flex-row justify-between bg-slate-lighter-95',
          headerClass
        )}
      >
        {rightHeader}
        <div
          className={clsxm(
            'flex flex-row gap-6 overflow-auto px-4 lg:px-6',
            tabClass
          )}
        >
          {tabs.map((tab, i) => {
            let id = i.toString();
            let tabName = '';
            if (typeof tab === 'object') {
              tabName = tab.tab;
              id = tab.id;
            } else {
              tabName = tab;
            }

            return (
              <Button
                className={clsxm(
                  id == activeTab
                    ? ['font-t-xs-azo border-b-4 border-black', textActiveClass]
                    : ['font-b-m text-slate-lighter-40', textInactiveClass],
                  'py-5',
                  'whitespace-nowrap',
                  'outline-none',
                  textClass
                )}
                variant='none'
                onClick={() => {
                  setActiveTab(id);
                  onTabChange?.(tabName);
                }}
                eventProps={
                  Array.isArray(eventProps) ? eventProps[i] : eventProps
                }
                key={id}
              >
                {tabName}
              </Button>
            );
          })}
        </div>
        {leftHeader}
      </div>
      {content[Number(activeTab)]}
      {footer}
    </div>
  );
};

export default TabbedContent;
