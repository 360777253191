import React from 'react';
import { isBrowser } from 'sly/config';

let dialogPolyfill = null;

export default function useDialogPolyfill(ref) {
  React.useLayoutEffect(() => {
    if (isBrowser && window.HTMLDialogElement === undefined) {
      if (dialogPolyfill) {
        dialogPolyfill.registerDialog(ref.current);
      } else {
        import('dialog-polyfill').then((polyfill) => {
          polyfill.default.registerDialog(ref.current);
          dialogPolyfill = polyfill.default;
        });
      }
    }
  }, [ref]);
}
