import { isProd, isServer, isStaging, isTest } from 'sly/config';

import segment from './segment';

// global.SLY_EVENT_ENV = isProd ? 'production' : 'development';

export const events = {
  identify(userId, userData) {
    if (isServer || isTest) {
      return;
    }

    if (!isProd && !isStaging) {
      console.info('EVENT identify', userId, userData);
    } else {
      segment.identify(userId, userData);
      // rudder.identify(userId, userData);
    }
  },

  track(...args) {
    if (isServer || isTest) {
      return;
    }
    if (!isProd && !isStaging) {
      console.info('EVENT track', ...args);
    } else {
      segment.track(...args);
    }
  },

  page(...args) {
    if (isServer || isTest) {
      return;
    }
    if (!isProd && !isStaging) {
      const { pathname, search } = window.location;
      console.info('EVENT page', `${pathname}${search}`, ...args);
    } else {
      segment.page(...args);
    }
  }
};
