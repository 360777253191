/** @jsxImportSource react */
import { eventNames, useEvents } from '@react/services/events';
import {
  ButtonProps,
  GetButtonPropsType,
} from '@react/system/Button/button.types';
import cx from 'classnames';
import React, { forwardRef } from 'react';
// todo: most probably should be common in future

const baseClasses = [
  'min-h-m',
  'py-s',
  'px-l',
  'whitespace-nowrap',
  'overflow-hidden',
  'cursor-pointer',
  'text-ellipsis',
  'block',
  'text-center',
  'font-t-xs-azo',
];

const getBackgroundClasses = (
  disabled: boolean,
  [disabledBg, background, hover, active]: [string, string, string, string]
) => {
  if (disabled) {
    return [disabledBg];
  }

  return [background, hover, active];
};

const getClasses = ({
  variant,
  palette,
  disabled,
}: {
  variant: string;
  palette: string;
  disabled: boolean;
}) => {
  /**
   * Mention this for tailwind to collect
   * border-slate-base
   * border-viridian-base
   * text-viridian-base
   * text-slate-base
   * bg-viridian-base
   * bg-white-base
   * bg-viridian-lighter-80
   * hover:bg-viridian-darker-20
   * active:bg-viridian-darker-40
   * active:bg-viridian-lighter-80
   * hover:bg-viridian-lighter-90
   * active:bg-viridian-lighter-90
   * hover:bg-viridian-lighter-95
   * bg-slate-base
   * hover:bg-slate-darker-20
   * active:bg-slate-darker-40
   * active:bg-slate-lighter-80
   * hover:bg-slate-lighter-90
   * active:bg-slate-lighter-90
   * hover:bg-slate-lighter-95
   * bg-red-base
   * hover:bg-red-darker-20
   * active:bg-red-darker-40
   * active:bg-red-lighter-80
   * hover:bg-red-lighter-90
   * active:bg-red-lighter-90
   * hover:bg-red-lighter-95
   * bg-green-base
   * hover:bg-green-darker-20
   * active:bg-green-darker-40
   * active:bg-green-lighter-80
   * hover:bg-green-lighter-90
   * active:bg-green-lighter-90
   * hover:bg-green-lighter-95
   * bg-yellow-base
   * hover:bg-yellow-darker-20
   * active:bg-yellow-darker-40
   * active:bg-yellow-lighter-80
   * hover:bg-yellow-lighter-90
   * active:bg-yellow-lighter-90
   * hover:bg-yellow-lighter-95
   * bg-blue-base
   * hover:bg-blue-darker-20
   * active:bg-blue-darker-40
   * active:bg-blue-lighter-80
   * hover:bg-blue-lighter-90
   * active:bg-blue-lighter-90
   * hover:bg-blue-lighter-95
   * bg-harvest-base
   * hover:bg-harvest-darker-20
   * active:bg-harvest-darker-40
   * active:bg-harvest-lighter-80
   * hover:bg-harvest-lighter-90
   * active:bg-harvest-lighter-90
   * hover:bg-harvest-lighter-95
   * bg-ebony-base
   * hover:bg-ebony-darker-20
   * active:bg-ebony-darker-40
   * active:bg-ebony-lighter-80
   * hover:bg-ebony-lighter-90
   * active:bg-ebony-lighter-90
   * hover:bg-ebony-lighter-95
   * rounded-[1.5rem]
   */
  switch (variant) {
    case 'primary':
      return [
        ...baseClasses,
        'text-white',
        'rounded-md',
        ...getBackgroundClasses(disabled, [
          `bg-${palette}-lighter-80`,
          `bg-${palette}-base`,
          `hover:bg-${palette}-darker-20`,
          `active:bg-${palette}-darker-40`,
        ]),
      ];
    case 'secondary':
      return [
        ...baseClasses,
        `text-${palette}-base`,
        'rounded-box',
        'bg-white-base',
        `hover:bg-${palette}-lighter-90`,
        `active:bg-${palette}-lighter-80`,
      ];
    case 'neutral':
      return [
        ...baseClasses,
        'text-slate-base',
        'rounded-box',
        'bg-white-base',
        `hover:bg-slate-lighter-95`,
        `active:bg-slate-lighter-90`,
        'rounded-[1.5rem]',
      ];
    case 'searchFilter':
      return [
        ...baseClasses,
        'text-slate-base',
        'bg-white-base',
        `hover:bg-viridian-lighter-90`,
        `active:bg-slate-lighter-90`,
        'hover:border-slate-lighter-60',
        'rounded-[1.5rem]',
        'border border-slate-lighter-90',
      ];
    case 'outlined':
      return [
        ...baseClasses,
        'rounded',
        !disabled && 'border',
        `border-${palette}-base`,
        'bg-white',
        'font-medium',
        !disabled && `text-${palette}-base`,
      ];

    case 'underlined':
      return ['text-viridian-base', 'font-medium', 'underline'];
    case 'custom':
      return baseClasses;
    case 'none':
      return [];

    default:
      return baseClasses;
  }
};

const getTarget = (href: string) => {
  if (!href.match(/https?:\/\//)) {
    return {};
  }

  return {
    target: '_blank',
    rel: 'noopener',
  };
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      to,
      href: hrefProps,
      event,
      onClick: onClickProp,
      eventProps = {},
      className,
      as = 'button',
      disabled = false,
      variant = 'primary',
      palette = 'viridian',
      ...props
    },
    ref
  ) => {
    const { events } = useEvents();

    const getProps = () => {
      const onClick = (...args: any[]) => {
        if (event || eventProps) {
          if (typeof event !== 'object') {
            if (to || hrefProps) {
              eventProps.href = to || hrefProps;
            }

            const eventName =
              typeof event === 'string' ? event : eventNames.ButtonClicked;
            events.track(eventName, {
              text: typeof props?.children === 'string' ? props.children : null,
              ...eventProps,
            });
          } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            events.track(event);
          }
        }
        if (onClickProp) {
          return onClickProp(...args);
        }
        return null;
      };

      const href = to || hrefProps;

      if (href) {
        return {
          ButtonComponent: 'a',
          ...props,
          disabled,
          href,
          variant,
          palette,
          ...getTarget(href),
          onClick,
        };
      }

      return {
        ButtonComponent: 'button',
        disabled,
        variant,
        palette,
        onClick,
        ...props,
      } as GetButtonPropsType;
    };

    const { ButtonComponent, ...extraProps } = getProps();
    const classes = cx([...getClasses(extraProps), className]);
    return (
      <ButtonComponent as={as} ref={ref} className={classes} {...extraProps} />
    );
  }
);

export default Button;
