/** @jsxImportSource react */
import clsxm from '@common/lib/clsxm';
import { SlyChat } from '@sly/icons/react';

export default function ChatIcon({ className }: { className?: string }) {
  return (
    <div
      className={clsxm(
        'flex h-7 w-7 shrink-0 select-none items-center justify-center rounded-md border border-slate-lighter-90 bg-white text-viridian-base transition-all',
        className
      )}
    >
      <SlyChat size='s' />
    </div>
  );
}
