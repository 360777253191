export interface HeadingProps {
  as?: keyof typeof HeadingFonts;
  font?: keyof typeof HeadingLevels;
  children: React.ReactNode;
  className?: string;
  innerRef?: any;
  id?: string;
}

export const HeadingFonts = {
  h1: 'font-t-xl',
  h2: 'font-t-l',
  h3: 'font-t-m',
  h4: 'font-t-s-azo',
  h5: 'font-t-xs-azo',
};

export const HeadingLevels = {
  'title-xxlarge': 'h1',
  'title-xlarge': 'h1',
  'title-large': 'h2',
  'title-medium': 'h3',
  'title-small': 'h4',
  'title-small-azo': 'h4',
  'title-xsmall-azo': 'h5',
  'font-t-l': 'h2',
  'font-t-xl': 'h2',
  'font-b-m': 'h2',
  'font-t-m': 'h2',
  'font-t-s-azo': 'h2',
  'font-t-xxl': 'h2',
  'font-b-l': 'h2',
  'font-t-m-azo': 'h2',
  'font-t-s': 'h2',
  'font-t-xs-azo': 'h2',
  'font-t-l md:font-t-xl': 'h2',
  'font-b-xs': 'h2',
  'font-l-m': 'h2',
  'font-b-s': 'h2',
};
