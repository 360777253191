import queryString from 'query-string';

export default function applyUrlWithPlaceholders(
  url,
  placeholders,
  options = { encode: true, arrayFormat: 'none' }
) {
  const query = {};

  const completeUrl = Object.keys(placeholders).reduce((acc, key) => {
    const token = `:${key}`;

    if (acc.indexOf(token) !== -1) {
      return acc.replace(token, encodeURIComponent(placeholders[key]));
    }

    if (placeholders[key] !== null) {
      query[key] = placeholders[key];
    }

    return acc;
  }, url);

  if (Object.keys(query).length > 0) {
    const qs = queryString.stringify(query, {
      arrayFormat: options.arrayFormat,
      encode: options.encode,
    });
    return `${completeUrl}?${qs}`;
  }

  return completeUrl;
}
