/* eslint-disable class-methods-use-this */
import { events } from '@common/events/events';
import { stringify } from 'query-string';

export default class SlyEvent {
  static seInstance = null;

  static getInstance() {
    if (this.seInstance === null) {
      this.seInstance = new SlyEvent();
    }
    return this.seInstance;
  }

  sendPageView() {
    events.page();
  }

  sendEvent(event) {
    events.track(event);
  }
}

export const objectToEventLabel = obj => {
  return stringify(obj, ';', ':');
};
