/** @jsxImportSource react */
import clsxm from '@common/lib/clsxm';
import { Checkmark } from '@sly/icons/react';
import React from 'react';

export interface IconWithTextProps {
  children?: string | React.ReactNode;
  as?: React.ElementType;
  Icon?: React.ElementType;
  className?: string;
  iconClassName?: string;
  iconSize?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
  isIconActive?: boolean;
}

export default function IconWithText({
  children,
  as,
  Icon = Checkmark,
  className,
  iconClassName = '',
  iconSize = 'm',
  isIconActive = false,
}: IconWithTextProps) {
  const Tag: React.ElementType = as || 'li';

  return (
    <Tag
      className={clsxm(
        className,
        'grid grid-cols-[max-content,auto] items-center gap-2'
      )}
    >
      <Icon size={iconSize} className={iconClassName} active={isIconActive} />
      <span>{children}</span>
    </Tag>
  );
}
