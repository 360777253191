/** @jsxImportSource react */
import { getAgentUrl } from '@common/helpers/url';
import clsxm from '@common/lib/clsxm';
import AgentStats from '@react/shared/components/AgentStats';
import Avatar from '@react/shared/components/Avatar';
import CommunityRating from '@react/shared/components/CommunityRating';
import { Link } from '@react/system';

export default function AgentCard({
  agent,
  className,
  communityCity = '',
  isSmallCard = false,
}) {
  const {
    name,
    info: { displayName } = {},
    aggregateRating: { ratingValue, numRatings } = {},
    address,
  } = agent || {};

  return (
    <>
      {isSmallCard ? (
        <>
          <div className={clsxm('flex gap-4', className)}>
            <div className='h-[88px] w-[88px]'>
              <Avatar
                name={displayName || name}
                path={agent?.gallery?.images?.[0]?.path}
              />
            </div>
            <div className='flex flex-col gap-1'>
              <h3 className='font-t-s mt-1'>{displayName || name}</h3>
              <div className='font-b-s'>{communityCity} Seniorly Advisor</div>
              {ratingValue > 0 && (
                <CommunityRating
                  numReviews={numRatings}
                  rating={ratingValue}
                  reviewsText
                  goToReviews={() => {}}
                  textColor='!slate-lighter-40'
                  reviewsTextFont='font-b-s'
                  textClassName='!text-slate-lighter-40 font-b-s'
                />
              )}
            </div>
          </div>
        </>
      ) : (
        <div className={clsxm('space-y-6 rounded  p-6  shadow-lg', className)}>
          <div className='grid grid-cols-[4.5rem_1fr] items-center gap-m'>
            <Avatar
              size='l'
              path={agent?.gallery?.images?.[0]?.path}
              name={displayName}
            />
            <div className='flex flex-col justify-center text-left'>
              {displayName && (
                <Link
                  to={getAgentUrl(agent)}
                  className='font-t-s-azo !text-slate-base'
                  target='_blank'
                >
                  {displayName}
                </Link>
              )}
              <div className='font-b-m text-viridian-base'>
                {address?.city} Seniorly Advisor
              </div>
            </div>
          </div>
          <AgentStats agent={agent} className='text-sm md:!grid-cols-1' />
        </div>
      )}
    </>
  );
}
