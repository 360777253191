export const leadSourceMap = {
  DirectCallLS: 'Direct Call',
  AnswerConnectLS: 'AnswerConnect',
  LeadGinieLS: 'LeadGinie',
  VoicemailLS: 'Voicemail',
  BotLS: 'Bot',
  AgentNetworkLS: 'Referral Agent Network',
  SeniorlySourceLS: 'Seniorly',
  AskAgentCommunityPageLS: 'Ask agent a question on the community page',
  AskAgentAgentPageLS: 'Ask agent a question on agent page',
  HomePageLS: 'Home Page',
  HomepageGetStartedLS: 'Homepage - Get Started',
  HomepageSpeakWithExpertLS: 'Homepage - Speak with an expert',
  HomepageQuizLS: 'Homepage - Take our quiz',
  HeaderSignupLS: 'Signup from Header',
  GetPricingLS: 'Get Pricing',
  GetPricingCompareLS: 'Get Pricing - Compare page',
  EmailLS: 'Email',
  WalkInsLS: 'Walk-ins',
  OtherSourceLS: 'Other',
  EbookLS: 'Ebook',
  TourRequestLS: 'Tour Request (Gallery)',
  TourOnFaqLS: 'Tour Request (FAQ section)',
  SearchPageGwizzyLS: 'Get personalized recomm on Search Page',
  SearchPageGetStartedLs: 'Get started on Search Page',
  ScheduleTourOnProfilePage: 'Schedule tour on profile page',
  ScheduleTourGwizzyHybridCTA: 'Schedule Tour (Gwizzy Hybrid CTA)',
  GetPricingCommunityTile: 'Get Pricing - Community tile',
  ScheduleTourViaEmail: 'Schedule tour via email',
  GetPricingTable: 'Get pricing line item on the pricing table',
  ContactUs: 'Contact Us',
  AskAgentRegionLS: 'Ask agent a question on the agents search page',
  GetExpertOptionsCommunityLS: 'Get Expert Options on Community Profile',
  ExitPopup: 'Exit Popup',
  AIChatbotLS: 'Seniorly AI Chatbot',
  GptChatbotLS: 'Seniorly GPT Chatbot',
  ProfileSharedFromLs: 'Profile Shared From',
  ProfileSharedToLs: 'Profile Shared To',
  ProfileSavedLs: 'Profile Saved',
  CostCalculatorLS: 'Cost Calculator',
  WellAhead: 'WellAhead',
  VeteransCare: 'Veterans Care',
  HomethriveLS: 'Homethrive'
};

export const marketingChannelMap = {
  PaidSearchMC: 'Paid Search',
  EmailMC: 'Email',
  OrganicMC: 'Organic (Website)',
  CommunityWebsiteMC: 'Community Website',
  DirectMailMC: 'Direct Mail',
  PartnerWebsiteCaringMC: 'Partner Website - caring.com',
  PartnerWebsiteOtherMC: 'Partner Website - others',
  EventsMC: 'Events',
  SeniorlyPlusAdsMC: 'Seniorly Plus Advertisements',
  OtherAdsMC: 'Other Advertisements',
  OtherMC: 'Other',
  ExitPopup: 'Exit Popup'
};

export const marketingMediumMap = {
  OnlineMM: 'Online',
  PhoneMM: 'Phone',
  InPersonMM: 'In Person'
};
