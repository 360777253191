/** @jsxImportSource react */
import { Button } from '@react/system';
import { bool, func, node, object, string } from 'proptypes';
import React from 'react';

const PostConversionGreetingForm = ({
  community,
  heading,
  description,
  className,
  children,
  onReturnClick,
}) => {
  return (
    <div
      className={className}
      data-tp-id='gen-div-810108b6-3138-4605-8778-fc6a2297b6e0'
    >
      <div
        className='w-auto p-l pt-m'
        data-tp-id='gen-div-ec662296-6f46-4d85-9de5-cb9c384adbc2'
      >
        <h3
          className='font-t-s-azo mb-m text-xl'
          data-tp-id='gen-h3-a543de08-ef59-4a4f-8406-131e912054b0'
        >
          {heading}
        </h3>
        {description && (
          <div
            className='mb-m'
            data-tp-id='gen-div-82c25ad3-5855-4845-af4c-c1894ab37b68'
          >
            {description}
          </div>
        )}
        {children && (
          <div
            className='mb-m'
            data-tp-id='gen-div-3cefc75a-b1a1-4db4-820b-e948cae79e13'
          >
            {children}
          </div>
        )}
        {onReturnClick && (
          <Button
            className='!font-b-s mb-0 w-full border border-slate-lighter-90'
            onClick={onReturnClick}
            data-tp-id='gen-Button-7ad863e3-886c-4ef2-9713-3a7f2a6b1bd9'
          >
            Return to {community ? 'Profile' : 'Home'}
          </Button>
        )}
      </div>
    </div>
  );
};

PostConversionGreetingForm.propTypes = {
  community: object,
  heading: string.isRequired,
  description: string,
  className: string,
  children: node,
  hasBox: bool,
  onReturnClick: func,
};

PostConversionGreetingForm.defaultProps = {
  heading: "You're all set! A Seniorly Local Advisor will reach out shortly.",
  hasBox: true,
  onSubmit: (_) => _,
};

export default PostConversionGreetingForm;
