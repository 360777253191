/** @jsxImportSource react */

import Avatar from '@react/shared/components/Avatar';
import { Button, Link } from '@react/system';
import { Mail, Phone } from '@sly/icons/react';
import React from 'react';

const MatchedAgent = ({ heading, agent, onReturn }) => {
  let path;
  if (agent?.gallery?.images?.length) {
    path = agent?.gallery.images[0].path;
  }
  return (
    <div
      className='p-l'
      data-tp-id='gen-div-94fd4247-d0fd-4f1e-90a1-c1dcef98f777'
    >
      <Avatar size='xxxLarge' path={path} name={agent?.name} />
      <h3
        className='font-t-s-azo my-l text-xl'
        data-tp-id='gen-h3-691e7c9f-bd81-49f9-8e3c-e719b630a56a'
      >
        {heading}
      </h3>
      <div
        className='mb-m'
        data-tp-id='gen-div-a3b21079-6630-4649-a1d5-8fd03347892f'
      >
        Questions? You can contact {agent?.name.split(' ')[0]} by phone or
        email:
      </div>
      <div data-tp-id='gen-div-88866df3-1a77-48d2-b9c6-16691ae07f2a'>
        <Mail />
        <span
          className='ml-m'
          data-tp-id='gen-span-e6f2ced5-b443-48a6-b647-1bbfb8c7bca6'
        >
          <Link
            to={`mailto:${agent.info.email}`}
            data-tp-id='gen-Link-446f1ad4-93ac-4137-b28b-b608a1c2f8fe'
          >
            {agent.info.email || 'test@email.com'}
          </Link>
        </span>
      </div>
      <div data-tp-id='gen-div-87f2a090-b43b-44ea-98f8-fa2f6c0f80e7'>
        <Phone />
        <span
          className='ml-m'
          data-tp-id='gen-span-8a067de3-31a8-442c-a14d-aa37d81ee890'
        >
          <Link
            to={`tel:${agent.info.workPhone}`}
            data-tp-id='gen-Link-74fc674e-b230-41a1-a1e2-37d05a30838a'
          >
            {agent.info.workPhone || '12312312'}
          </Link>
        </span>
      </div>
      {onReturn && (
        <Button
          onClick={onReturn}
          className='mt-l w-full'
          data-tp-id='gen-Button-be310551-547d-497a-9974-78cb694d5a65'
        >
          Return to profile
        </Button>
      )}
    </div>
  );
};

export default MatchedAgent;
