import { getDefaultCommunityImageUrl } from '@common/helpers/community';
import { FLOOR_PLANS, SITE_PLANS } from '@sly/core/constants/imageCategories';
import { Community, Image, ImageCategory } from '@sly/core/types';

export interface GalleryImage {
  idx?: number;
  id?: string;
  path?: string;
  description?: string | undefined;
  alt?: string;
  src?: string;
  category?: ImageCategory;
}

export function getImages({
  gallery,
  id,
  mainImage,
  propInfo,
  name,
  address,
  size = undefined,
  aspectRatio = '3:2'
}: Community & { size?: number; aspectRatio?: '3:2' | '1:1' }): {
  isPlaceHolder: boolean;
  images: GalleryImage[];
  floorPlanIndex: number;
} {
  const defaultImageUrl = getDefaultCommunityImageUrl(
    id,
    propInfo?.communitySize,
    aspectRatio === '1:1'
  );

  const { city, state } = address || {};
  const fallbackAlt = [name, city, state].filter(Boolean).join(', ');

  // Filter out all images with a category of "Floor Plan" and add them to the end of the array
  const floorPlanImages: Array<Image> = [];
  const sitePlanImages: Array<Image> = [];
  let images: Array<Image> = [];
  gallery?.images?.forEach(image => {
    if (image?.category?.name === FLOOR_PLANS) {
      floorPlanImages.push(image);
    } else if (image?.category?.name === SITE_PLANS) {
      sitePlanImages.push(image);
    } else {
      images.push(image);
    }
  });

  // If there are no floor plans we want index to be undefined so we don't show the floor plan button
  const floorPlanIndex =
    floorPlanImages?.length > 0 || sitePlanImages?.length > 0
      ? images?.length
      : -1;
  if (floorPlanImages?.length > 0) {
    images = [...images, ...floorPlanImages];
  }

  if (sitePlanImages?.length > 0) {
    images = [...images, ...sitePlanImages];
  }

  let formattedImages: Array<
    | {
        id: string;
        path: string;
        description: string | undefined;
        alt: string;
        category?: ImageCategory;
      }
    | { src: string }
  > = images.map((image, i) =>
    image?.src
      ? { src: image.src, description: image.description }
      : {
          id: image.id,
          path: image.path,
          description: image.description,
          alt: image.description || `${fallbackAlt} ${i + 1}`,
          category: image.category
        }
  );

  // if images is empty add default image
  let isPlaceHolder = false;
  if (formattedImages?.length === 0) {
    isPlaceHolder = true;
    formattedImages = [{ src: defaultImageUrl }];
  }

  // If there is a mainImage put it in front
  const mainImageIndex = images.findIndex(image => {
    return image?.path && mainImage?.indexOf(image?.path) !== -1;
  });

  if (mainImageIndex !== -1) {
    const [communityMainImage] = images.splice(mainImageIndex, 1);
    images.unshift(communityMainImage);
  }

  return {
    isPlaceHolder,
    images: formattedImages
      .map((image, idx) => ({ ...image, idx }))
      .slice(0, size || (!!images.length ? images.length : 1)),
    floorPlanIndex
  };
}
