import React, { forwardRef, Ref } from 'react';
import { SimpleIconProps } from '../icon.types';
import Icon from './Icon';

const svg = `<svg viewBox="0 0 24 24" fill="currentColor">
  <path d="M10.9968 12.7081L12 11.9369L12.9843 12.7081C13.1995 12.8821 13.4284 12.8892 13.671 12.7296C13.9135 12.57 13.9892 12.3556 13.8979 12.0864L13.5028 10.8081L14.6011 9.94782C14.8163 9.77788 14.8805 9.56248 14.7935 9.30162C14.7066 9.04075 14.5285 8.91032 14.2593 8.91032H12.9479L12.5408 7.65107C12.4495 7.38187 12.2682 7.24726 11.997 7.24726C11.7258 7.24726 11.5446 7.38187 11.4533 7.65107L11.0522 8.91032H9.74078C9.47158 8.91032 9.29033 9.04392 9.19703 9.31114C9.10374 9.57834 9.16471 9.79057 9.37993 9.94782L10.4783 10.8081L10.0772 12.0864C9.9859 12.3556 10.0594 12.57 10.2976 12.7296C10.5358 12.8892 10.7689 12.8821 10.9968 12.7081ZM7.4147 22.6755C7.03754 22.8081 6.69252 22.7605 6.37965 22.5326C6.06679 22.3047 5.91035 22.0002 5.91035 21.619V15.4364C5.24514 14.7125 4.72657 13.8836 4.35465 12.9497C3.98272 12.0158 3.79675 11.0206 3.79675 9.96412C3.79675 7.67897 4.59268 5.74046 6.18453 4.14861C7.77638 2.55678 9.71488 1.76086 12 1.76086C14.2852 1.76086 16.2237 2.55678 17.8155 4.14861C19.4074 5.74046 20.2033 7.67897 20.2033 9.96412C20.2033 11.0206 20.0173 12.0158 19.6454 12.9497C19.2735 13.8836 18.7549 14.7125 18.0897 15.4364V21.619C18.0897 22.0002 17.9333 22.3047 17.6204 22.5326C17.3075 22.7605 16.9625 22.8081 16.5854 22.6755L12 21.1674L7.4147 22.6755ZM12 15.8924C13.6468 15.8924 15.0465 15.316 16.1992 14.1633C17.3519 13.0106 17.9283 11.6109 17.9283 9.96412C17.9283 8.31738 17.3519 6.91766 16.1992 5.76494C15.0465 4.61222 13.6468 4.03586 12 4.03586C10.3533 4.03586 8.95357 4.61222 7.80085 5.76494C6.64814 6.91766 6.07178 8.31738 6.07178 9.96412C6.07178 11.6109 6.64814 13.0106 7.80085 14.1633C8.95357 15.316 10.3533 15.8924 12 15.8924ZM8.00003 20.1206L12 19.0538L16 20.1206V17.1103C15.4127 17.4397 14.7825 17.6982 14.1095 17.8859C13.4365 18.0735 12.7334 18.1674 12 18.1674C11.2667 18.1674 10.5635 18.0735 9.89053 17.8859C9.21751 17.6982 8.58734 17.4397 8.00003 17.1103V20.1206Z"/>
</svg>`;

const Best = forwardRef(
  (props: SimpleIconProps, ref: Ref<SVGSVGElement> | undefined) => (
    <Icon ref={ref} name='best' svg={svg} {...props} />
  )
);

Best.displayName = 'BestIcon';

export default Best;
