import React, { forwardRef, Ref } from 'react';
import { SimpleIconProps } from '../icon.types';
import Icon from './Icon';

const svg = `<svg viewBox="0 0 24 24" fill="currentColor">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M3.99995 3.20001H20C21.1 3.20001 22 4.10001 22 5.20001V17.2C22 18.3 21.1 19.2 20 19.2H5.99995L3.70995 21.5C3.07995 22.13 1.99995 21.68 1.99995 20.79V5.20001C1.99995 4.10001 2.89995 3.20001 3.99995 3.20001ZM11.9267 5.20001L11.925 5.2024L11.9226 5.20001H11.9267ZM11.9267 8.45896C12.9955 7.33006 12.9949 6.24454 11.925 5.2024C11.1665 6.32589 11.1671 7.41141 11.9267 8.45896ZM4.99995 10.6953C6.55551 10.0707 7.66313 10.5636 8.32282 12.174C6.90843 12.4782 5.80081 11.9853 4.99995 10.6953ZM5.26465 14.6632C7.05367 15.7685 8.73275 15.6979 10.3019 14.4514C8.48571 12.8545 6.80527 12.9251 5.26057 14.6632H5.26465ZM11.1855 14.8751C11.3801 14.6757 11.6481 14.5652 11.9266 14.5695C12.2089 14.5597 12.4823 14.6691 12.68 14.871C12.8795 15.0719 12.9883 15.3456 12.9813 15.6287C12.9801 16.0542 12.7207 16.4363 12.3257 16.5942C12.2209 16.642 12.107 16.6669 11.9918 16.6675H11.9266C11.6481 16.6718 11.3801 16.5613 11.1855 16.362C10.9877 16.1713 10.8772 15.9075 10.8801 15.6328C10.8727 15.3488 10.9833 15.0745 11.1855 14.8751ZM8.13549 7.021C10.967 7.88462 12.095 10.1537 11.5194 13.8281H11.4991C9.18882 12.5789 8.06763 10.3098 8.13549 7.021ZM13.698 14.4515C15.2645 15.6981 16.9435 15.7687 18.7353 14.6633C17.177 12.9307 15.4911 12.86 13.6777 14.4515H13.698ZM16.0151 7.021C16.0911 10.3071 14.974 12.5762 12.6637 13.8281H12.6434C12.0692 10.1537 13.1768 7.8887 16.0151 7.021ZM15.6771 12.174C17.0915 12.4782 18.1991 11.9853 18.9999 10.6953C17.4335 10.0707 16.3205 10.5636 15.6608 12.174H15.6771Z"/>
</svg>`;

const SlyChat = forwardRef(
  (props: SimpleIconProps, ref: Ref<SVGSVGElement> | undefined) => (
    <Icon ref={ref} name='slychat' svg={svg} {...props} />
  )
);

SlyChat.displayName = 'SlyChatIcon';

export default SlyChat;
