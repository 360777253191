export const PROFILE_VIEWED = 'profileViewed';
export const PROFILE_CONTACTED = 'profileContacted';
export const CONSULTATION_REQUESTED = 'consultationRequested';
export const PROFILE_ASK_QUESTION = 'profileAskQuestion';
export const AA_CONSULTATION_REQUESTED = 'activeAdultConsultationRequested';
export const CARE_ASSESSMENT = 'careAssessmentProgress';
export const PROFILE_RATING = 'profileRating';
export const USER_SAVE = 'userSave';
export const USER_SHARE = 'userShare';
export const TOUR_BOOKED = 'tourBooked';
export const AGENT_ASK_QUESTIONS = 'agentAskQuestions';
export const FLOOR_PLAN_REQUESTED = 'floorPlanRequested';
export const HOME_CARE_REQUESTED = 'homeCareRequested';
export const CURTIANUP_SUBSCRIBE = 'curtainupSubscribe';
export const WIZARD_STEP_COMPLETED = 'wizardStepCompleted';
export const WIZARD_POSTCONVERSION_INFO = 'wizardPostConversionInfo';
export const EXTERNAL_LINK_CLICK = 'externalLinkClicked';
export const SEARCH_PAGE_VIEWED = 'searchPageViewed';
export const UUID_AUX_UPDATE = 'uuidAuxUpdate';

export const PRICING_REQUEST = 'pricingRequest';
export const AVAILABILITY_REQUEST = 'availabilityRequest';

export const EXIT_INTENT_ASK_QUESTIONS = 'exitIntentAskQuestions';
export const EBOOK_SEND_EMAIL = 'ebookSendEmail';
export const CONVERSATION_MESSAGE_BUTTONLIST_BUTTON_CLICKED =
  'conversationMessageButtonListButtonClicked';

export const LISTING_CONSULTATION_REQUEST =
  'listingConsultationRequestedAction';
