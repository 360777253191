export const LOGINSIGNUP = 'LoginSignup';
export const RESETPASSWORD = 'ResetPassword';
export const INFOPROMPT = 'InfoPrompt';
export const PROIVDERSIGNUP = 'ProviderSignup';
export const MAGICLINKEXPIRED = 'MagicLinkExpired';
export const SIGNUP = 'Signup';
export const CUSTOMERSIGNUPCONFIRMATION = 'CustomerSignupConfirmation';
export const MAGICLINKSUCCESS = 'MagicLinkSuccess';
export const LOGINWITHPASSWORD = 'LoginWithPassword';
export const AGENTSIGNUP = 'AgentSignup';
export const PROVIDERFINDCOMMUNITY = 'ProviderFindCommunity';
export const PROVIDERCLAIMNEEDSAPPROVAL = 'ProviderClaimNeedsApproval';
export const PROVIDERCOMMUNITYNOTFOUND = 'ProviderCommunityNotFound';
export const OTPLOGIN = 'OtpLogin';
export const PROVIDERCONFIRMATION = 'ProviderConfirmation';

export const noAutoLoginStatuses = ['invite'];

export const RESOURCE_EXPIRED_STATUS = 'resource-expired';

export const AGENT = 'agent';
export const PROVIDER = 'provider';
export const CUSTOMER = 'customer';
export const REGISTER = 'register';
export const LOGGINGIN = 'loggingin';
