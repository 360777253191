/** @jsxImportSource react */
import {
  ratingBadge,
  RatingBadgeProps,
} from '@sly/frontend/common/cva/ratingBadge';
import clsxm from '@sly/frontend/common/lib/clsxm';

export default function RatingBadge({
  rating,
  intent,
  size,
  className,
}: RatingBadgeProps) {
  return (
    <div className={clsxm(ratingBadge({ intent, size }), className)}>
      {rating}
    </div>
  );
}
