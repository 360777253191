import React, { forwardRef, Ref } from 'react';
import { SimpleIconProps } from '../icon.types';
import Icon from './Icon';

const svg = `<svg viewBox="0 0 24 24" fill="currentColor">
  <path d="M4.07175 21.2033C3.44204 21.2033 2.90544 20.9815 2.46195 20.538C2.01849 20.0946 1.79675 19.558 1.79675 18.9282V8.1674C1.79675 7.53768 2.0185 7.00108 2.462 6.5576C2.90547 6.11413 3.44206 5.8924 4.07178 5.8924H7.8147V3.8924C7.8147 3.26268 8.03644 2.72608 8.47993 2.2826C8.92339 1.83911 9.45999 1.61737 10.0897 1.61737H13.9104C14.5401 1.61737 15.0767 1.83911 15.5201 2.2826C15.9636 2.72608 16.1854 3.26268 16.1854 3.8924V5.8924H19.9283C20.558 5.8924 21.0946 6.11413 21.5381 6.5576C21.9815 7.00108 22.2033 7.53768 22.2033 8.1674V18.9282C22.2033 19.558 21.9815 20.0946 21.5381 20.538C21.0946 20.9815 20.558 21.2033 19.9283 21.2033H4.07175ZM10.0897 5.8924H13.9103V3.8924H10.0897V5.8924ZM19.9283 14.9282H15V15.7907C15 16.1099 14.8902 16.3793 14.6707 16.5989C14.4511 16.8185 14.1817 16.9282 13.8625 16.9282H10.1375C9.8183 16.9282 9.54892 16.8185 9.32935 16.5989C9.10979 16.3793 9 16.1099 9 15.7907V14.9282H4.07175V18.9282H19.9283V14.9282ZM11 14.9282H13V12.9282H11V14.9282ZM4.07175 12.9282H9V12.0657C9 11.7465 9.10979 11.4772 9.32935 11.2576C9.54892 11.038 9.8183 10.9282 10.1375 10.9282H13.8625C14.1817 10.9282 14.4511 11.038 14.6707 11.2576C14.8902 11.4772 15 11.7465 15 12.0657V12.9282H19.9283V8.1674H4.07175V12.9282Z"/>
</svg>`;

const Business = forwardRef(
  (props: SimpleIconProps, ref: Ref<SVGSVGElement> | undefined) => (
    <Icon ref={ref} name='business' svg={svg} {...props} />
  )
);

Business.displayName = 'BusinessIcon';

export default Business;
