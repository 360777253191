export const costSections = [
  'Assisted Living',
  'Independent Living',
  'Memory Care',
  'Additional Costs'
];

const memoryCareRoomTypes = [
  { label: 'Private Room', value: 'privateRoom' },
  { label: 'Shared Room', value: 'sharedBedroom' }
];

const assistedLivingIndependentLivingRoomTypes = [
  { label: 'Studio', value: 'studio' },
  { label: 'One Bedroom', value: 'oneBedroom' },
  { label: 'Two Bedroom', value: 'twoBedroom' },
  ...memoryCareRoomTypes
];

export const valueToLabel = {
  studio: 'Studio',
  studioWithAlcove: 'Studio with alcove',
  oneBedroom: 'One Bedroom',
  oneBedroomWithAlcove: 'One Bedroom with alcove',
  twoBedroom: 'Two Bedroom',
  threeBedroom: 'Three bedroom',
  privateCottage: 'Private cottage/casita',
  privateRoom: 'Private Room',
  sharedBedroom: 'Shared Bedroom',
  additionalService: 'Additional levels of care',
  secondPerson: 'Second person fee',
  communityFee: 'One time community fee',
  unknown: 'Unknown',
  ccrcEntryFees: 'CCRC entry fees'
};

export const validRoomTypes = [
  'studio',
  'oneBedroom',
  'twoBedroom',
  'privateRoom',
  'sharedBedroom'
];

export const valueToLabelSection = {
  assistedLiving: 'Assisted Living',
  independentLiving: 'Independent Living',
  memoryCare: 'Memory Care',
  additionalCosts: 'Additional Costs'
};

export const orderedLabels = [
  'Studio',
  'One Bedroom',
  'Two Bedroom',
  'Private Room',
  'Shared Room',
  'Additional levels of care',
  'Second person fee'
];

export const costSectionOptions = {
  'Assisted Living': {
    costHeading: 'Room Types',
    costTypes: [...assistedLivingIndependentLivingRoomTypes]
  },
  'Independent Living': {
    costHeading: 'Room Types',
    costTypes: [...assistedLivingIndependentLivingRoomTypes]
  },
  'Memory Care': {
    costHeading: 'Room Types',
    costTypes: [...memoryCareRoomTypes]
  },
  'Additional Costs': {
    costHeading: 'Other fees and services',
    costTypes: [
      { label: 'Additional levels of care', value: 'additionalService' },
      { label: 'Second person fee', value: 'secondPerson' },
      { label: 'One time community fee', value: 'communityFee' }
    ]
  }
};

export const costTypeOptions = [
  { value: 'range', label: 'Range' },
  { value: 'from', label: 'From' }
];

export const memoryCareCostTypeOptions = [
  { value: 'range', label: 'Range' },
  { value: 'from', label: 'From' },
  { value: 'inclusive', label: 'All Inclusive' }
];

export const defaultInitialValues = {
  'Assisted Living': {
    0: ['privateRoom', 'sharedBedroom'],
    20: ['privateRoom', 'sharedBedroom', 'studio', 'oneBedroom'],
    51: ['privateRoom', 'sharedBedroom', 'studio', 'oneBedroom', 'twoBedroom']
  },
  'Independent Living': {
    0: ['privateRoom', 'sharedBedroom', 'studio', 'oneBedroom', 'twoBedroom']
  },
  'Memory Care': {
    0: ['privateRoom', 'sharedBedroom']
  }
};

export const communityDefaultImageTemplates = {
  'up to 20 Beds': [
    'vectors/Small_A.svg',
    'vectors/Small_B.svg',
    'vectors/Small_C.svg'
  ],
  '20 - 51 Beds': [
    'vectors/Medium_A.svg',
    'vectors/Medium_B.svg',
    'vectors/Medium_C.svg'
  ],
  '51 +': ['vectors/Large_A.svg', 'vectors/Large_B.svg', 'vectors/Large_C.svg']
};

export const communityDefaultImageV2Templates = {
  'up to 20 Beds': [
    'vectors/Small A_V2.svg',
    'vectors/Small B_V2.svg',
    'vectors/Small C_V2.svg'
  ],
  '20 - 51 Beds': [
    'vectors/Medium A_V2.svg',
    'vectors/Medium B_V2.svg',
    'vectors/Medium C_V2.svg'
  ],
  '51 +': [
    'vectors/Large A_V2.svg',
    'vectors/Large B_V2.svg',
    'vectors/Large C_V2.svg'
  ]
};

export const communityDefaultImageTemplates1x1 = {
  'up to 20 Beds': [
    'vectors/Small_A1x1.svg',
    'vectors/Small_B1x1.svg',
    'vectors/Small_C1x1.svg'
  ],
  '20 - 51 Beds': [
    'vectors/Medium_A1x1.svg',
    'vectors/Medium_B1x1.svg',
    'vectors/Medium_C1x1.svg'
  ],
  '51 +': [
    'vectors/Large_A1x1.svg',
    'vectors/Large_B1x1.svg',
    'vectors/Large_C1x1.svg'
  ]
};
