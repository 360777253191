import React, { forwardRef, Ref } from 'react';
import { SimpleIconProps } from '../icon.types';
import Icon from './Icon';

const svg = `<svg viewBox="0 0 24 24" fill="currentColor">
  <path d="M10.8625 19V16.1451C10.0458 15.9498 9.31365 15.6 8.666 15.0956C8.01835 14.5913 7.53457 13.9578 7.21465 13.1951C5.869 13.1009 4.73848 12.5531 3.82308 11.5516C2.90766 10.5502 2.44995 9.36628 2.44995 7.99998V6.99998C2.44995 6.37026 2.67169 5.83367 3.11518 5.3902C3.55866 4.94672 4.09526 4.72498 4.72498 4.72498H6.72498C6.72498 4.17498 6.92081 3.70414 7.31248 3.31248C7.70414 2.92081 8.17498 2.72498 8.72498 2.72498H15.275C15.825 2.72498 16.2958 2.92081 16.6875 3.31248C17.0791 3.70414 17.275 4.17498 17.275 4.72498H19.275C19.9047 4.72498 20.4413 4.94672 20.8848 5.3902C21.3282 5.83367 21.55 6.37026 21.55 6.99998V7.99998C21.55 9.36628 21.0923 10.5502 20.1769 11.5516C19.2615 12.5531 18.131 13.1009 16.7853 13.1951C16.4654 13.9578 15.9816 14.5913 15.334 15.0956C14.6863 15.6 13.9541 15.9498 13.1375 16.1451V19H15.9103C16.2295 19 16.4989 19.1098 16.7185 19.3293C16.938 19.5489 17.0478 19.8183 17.0478 20.1375C17.0478 20.4567 16.938 20.7261 16.7185 20.9456C16.4989 21.1652 16.2295 21.275 15.9103 21.275H8.08965C7.77045 21.275 7.50107 21.1652 7.2815 20.9456C7.06193 20.7261 6.95215 20.4567 6.95215 20.1375C6.95215 19.8183 7.06193 19.5489 7.2815 19.3293C7.50107 19.1098 7.77045 19 8.08965 19H10.8625ZM6.72498 10.8V6.99998H4.72498V7.99998C4.72498 8.63331 4.90831 9.20414 5.27498 9.71248C5.64164 10.2208 6.12498 10.5833 6.72498 10.8ZM12 14C12.8333 14 13.5416 13.7083 14.125 13.125C14.7083 12.5416 15 11.8333 15 11V4.99998H8.99998V11C8.99998 11.8333 9.29164 12.5416 9.87498 13.125C10.4583 13.7083 11.1666 14 12 14ZM17.275 10.8C17.875 10.5833 18.3583 10.2208 18.725 9.71248C19.0916 9.20414 19.275 8.63331 19.275 7.99998V6.99998H17.275V10.8Z"/>
</svg>`;

const Award = forwardRef(
  (props: SimpleIconProps, ref: Ref<SVGSVGElement> | undefined) => (
    <Icon ref={ref} name='award' svg={svg} {...props} />
  )
);

Award.displayName = 'AwardIcon';

export default Award;
