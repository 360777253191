/** @jsxImportSource react */
import { retrieveLocalStorage } from '@common/helpers/localStorage';
import { ConversionSchemaType } from '@common/helpers/transformGwizzyCommunity';
import { gwizzyAsyncHandler } from '@react/gwizzy/asyncHandlers/conversionAsyncHandler';
import GwizzyWizard from '@react/gwizzy/components/GwizzyWizard';
import { getAASchema } from '@react/gwizzy/schema/aaSchema';
import { getConversionSchema } from '@react/gwizzy/schema/conversionSchema';
import { getD2CSchema } from '@react/gwizzy/schema/d2cSchema';
import { getNonD2CSchema } from '@react/gwizzy/schema/nonD2CSchema';
import { normalizeResponse } from '@react/services/api';
import { useQuery } from '@react/services/api';
import { useAuth } from '@react/services/auth';
import { useEvents } from '@react/services/events';
import parseName from '@react/shared/helpers/parseName';
import { DEFAULT_EMAIL_VALUE } from '@sly/core/constants/retentionPopup';
import set from 'lodash/set';
import { useEffect, useMemo, useState } from 'react';

export default function ConversionWizard({
  ctaActions,
  community,
  eventProps = {},
  contactInfo,
  showLocationStep,
  mode,
  formClassName = '',
  isChatMode,
  formWrapperClassName = '',
  enabledDisclosureState = '',
  communitySlugs,
  conversionSchemaOverride = '',
  hasExperimentalOnBack = true,
}) {
  const { events } = useEvents();
  const createAction = useQuery('createUuidAction');
  const applyFormValidation = useQuery('applyFormValidation');
  const auth = useAuth();
  const { user } = auth;
  const isReadOnlyEmail = !user && retrieveLocalStorage(DEFAULT_EMAIL_VALUE);
  const conversionSchemaType =
    conversionSchemaOverride || community?.conversionSchemaType;
  const [isResidentNameQuestionRequired, setIsResidentNameQuestionRequired] =
    useState(false);

  const isGwizzyQuestionRequiredAPICall = useQuery('isGwizzyQuestionRequired');

  const fetchResidentNameQuestionData = async () => {
    try {
      const response = await isGwizzyQuestionRequiredAPICall({
        type: 'GwizzyQuestion',
        attributes: {
          questionType: 'residentName',
          communitySlug: community.id,
        },
      });
      const normalizedRes = normalizeResponse(response.body);
      if (normalizedRes?.[0]?.info?.isResidentNameRequired) {
        setIsResidentNameQuestionRequired(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchResidentNameQuestionData();
  }, []);

  const conversionSchema = useMemo(() => {
    switch (conversionSchemaType) {
      case ConversionSchemaType.ActiveAdult:
        return getAASchema({
          community,
          contactInfo,
          showLocationStep,
          isReadOnlyEmail,
          isChatMode,
          isResidentNameQuestionRequired,
        });
      case ConversionSchemaType.D2C:
        return getD2CSchema({
          community,
          contactInfo,
          showLocationStep,
          isReadOnlyEmail,
          isChatMode,
          isResidentNameQuestionRequired,
        });
      case ConversionSchemaType.NoAgent:
        return getNonD2CSchema({
          community,
          contactInfo,
          showLocationStep,
          isReadOnlyEmail,
          isChatMode,
          isResidentNameQuestionRequired,
        });
      default:
        return getConversionSchema({
          community,
          contactInfo,
          showLocationStep,
          isReadOnlyEmail,
          isChatMode,
          isResidentNameQuestionRequired,
        });
    }
  }, [
    conversionSchemaType,
    community,
    contactInfo,
    showLocationStep,
    isReadOnlyEmail,
    isChatMode,
    isResidentNameQuestionRequired,
  ]);

  const formInitialValues = useMemo(() => {
    const initialValues = {};
    const emailFromStorage = retrieveLocalStorage(DEFAULT_EMAIL_VALUE);
    if (emailFromStorage) {
      initialValues['email'] = emailFromStorage;
    }

    const outOfBudget = retrieveLocalStorage('outOfBudget');

    if (outOfBudget) {
      const outOfBudgetData = JSON.parse(outOfBudget);

      if (outOfBudgetData?.adl) {
        initialValues['adl'] = outOfBudgetData.adl;
      }
    }

    if (!user) {
      return initialValues;
    }

    const { firstName, lastName } = parseName(user?.name);

    set(initialValues, 'email', user.email);
    set(initialValues, 'firstName', firstName);
    set(initialValues, 'lastName', lastName);
    set(initialValues, 'phone', user?.phoneNumber);

    return initialValues;
  }, []);

  return (
    <GwizzyWizard
      initialValues={formInitialValues}
      asyncActionHandlers={gwizzyAsyncHandler({
        events,
        createAction,
        auth,
        community,
        ctaActions,
        applyFormValidation,
        eventProps,
        mode,
        enabledDisclosureState,
        communitySlugs,
      })}
      formSchema={conversionSchema}
      isDefaultSubmitDisabled={true}
      localStorageKey={conversionSchemaType}
      hasExperimentalOnBack={hasExperimentalOnBack}
      isChatMode={isChatMode}
      formClassName={formClassName}
      formWrapperClassName={formWrapperClassName}
    />
  );
}
