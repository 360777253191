import React, { forwardRef, Ref } from 'react';
import { SimpleIconProps } from '../icon.types';
import Icon from './Icon';

const svg = `<svg viewBox="0 0 24 24" fill="currentColor">
  <path d="M5.07175 21.2033C4.44203 21.2033 3.90543 20.9816 3.46195 20.5381C3.01846 20.0946 2.79672 19.558 2.79672 18.9283V5.07178C2.79672 4.44206 3.01846 3.90546 3.46195 3.46198C3.90543 3.01849 4.44203 2.79675 5.07175 2.79675H18.9282C19.558 2.79675 20.0946 3.01849 20.538 3.46198C20.9815 3.90546 21.2033 4.44206 21.2033 5.07178V18.9283C21.2033 19.558 20.9815 20.0946 20.538 20.5381C20.0946 20.9816 19.558 21.2033 18.9282 21.2033H5.07175ZM5.07175 18.9283H18.9282V5.07178H5.07175V18.9283Z"/>
  <path style="fill: var(--active-color)" d="M10.5821 13.2147L8.58152 11.2201C8.37427 11.0129 8.11404 10.9103 7.80082 10.9123C7.48759 10.9143 7.22735 11.0189 7.0201 11.2261C6.81286 11.4334 6.70925 11.6926 6.70925 12.0038C6.70925 12.3151 6.81286 12.5743 7.0201 12.7816L9.78042 15.5479C10.0083 15.7758 10.2755 15.8897 10.5821 15.8897C10.8886 15.8897 11.1558 15.7758 11.3837 15.5479L16.944 9.98155C17.1513 9.7743 17.2539 9.51407 17.2519 9.20085C17.2499 8.88762 17.1453 8.62738 16.938 8.42013C16.7308 8.21289 16.4716 8.10928 16.1603 8.10928C15.8491 8.10928 15.5898 8.21289 15.3826 8.42013L10.5821 13.2147ZM5.07175 21.2033C4.44203 21.2033 3.90543 20.9816 3.46195 20.5381C3.01846 20.0946 2.79672 19.558 2.79672 18.9283V5.07178C2.79672 4.44206 3.01846 3.90546 3.46195 3.46198C3.90543 3.01849 4.44203 2.79675 5.07175 2.79675H18.9282C19.558 2.79675 20.0946 3.01849 20.538 3.46198C20.9815 3.90546 21.2033 4.44206 21.2033 5.07178V18.9283C21.2033 19.558 20.9815 20.0946 20.538 20.5381C20.0946 20.9816 19.558 21.2033 18.9282 21.2033H5.07175Z"/>
</svg>`;

const Checkbox = forwardRef(
  (props: SimpleIconProps, ref: Ref<SVGSVGElement> | undefined) => (
    <Icon ref={ref} name='checkbox' svg={svg} {...props} />
  )
);

Checkbox.displayName = 'CheckboxIcon';

export default Checkbox;
