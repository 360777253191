import { assetPath } from '@common/lib/path';
import { getHashInRange } from '@common/helpers/utils';
import { communityDefaultImageTemplates } from '@sly/core/constants/communityPricing';
import { COMMUNITY_ENTITY_TYPE } from '@sly/core/constants/entityTypes';
import { USER_SAVE_DELETE_STATUS } from '@sly/core/constants/userSaves';
import { Community } from '@sly/core/api/types/communities';
import { UserSave } from '@sly/core/api/types/userSaves';

export function getCommunityUserSave(
  community: Community,
  userSaves: UserSave[]
) {
  return (
    userSaves &&
    userSaves.find(
      ({ entityType, entitySlug }) =>
        entityType === COMMUNITY_ENTITY_TYPE && entitySlug === community.id
    )
  );
}

export function isCommunityAlreadySaved(
  community: Community,
  userSaves: UserSave[]
) {
  const userSaveOfCommunity = getCommunityUserSave(community, userSaves);
  return (
    userSaveOfCommunity &&
    userSaveOfCommunity.status !== USER_SAVE_DELETE_STATUS
  );
}

export const getCommunityParams = (params: Record<string, any>) => {
  const [slug, modal1, modal2] = params?.communitySlug || {};

  return {
    toc: params.toc,
    state: params.state,
    city: params.city,
    slug,
    modal1,
    modal2
  };
};

export const getDefaultCommunityImageUrl = (
  id: string,
  communitySize: keyof typeof communityDefaultImageTemplates = 'up to 20 Beds'
) => {
  return assetPath(
    communityDefaultImageTemplates[communitySize]?.[getHashInRange(id, 3)]
  );
};
