import {
  CCRC,
  CONTINUING_CARE_RETIREMENT_COMMUNITY,
  SKILLED_NURSING_FACILITY
} from '@sly/core/constants/tags';

const arrayOfHiddenPricingToc = [
  CONTINUING_CARE_RETIREMENT_COMMUNITY,
  CCRC,
  SKILLED_NURSING_FACILITY
];
const shouldHidePricing = (toc: string[]) =>
  toc.some(value => arrayOfHiddenPricingToc.includes(value));

const getIsPricingHidden = (typeCare: string | string[]) => {
  let toc;
  if (typeof typeCare === 'string') {
    toc = typeCare.split('·').map(t => t.trim());
  } else if (Array.isArray(typeCare)) {
    toc = typeCare;
  }

  return toc ? shouldHidePricing(toc) : false;
};

export default getIsPricingHidden;
