// import { useParams } from 'react-router';
import { usePrefetch } from '@react/services/api';
import {
  CONSULTATION_REQUESTED,
  PROFILE_CONTACTED,
} from '@sly/core/constants/actionTypes';
import { useCallback } from 'react';

const useHasPricingRequested = ({ params }) => {
  const slug = params?.slug || params?.communitySlug?.[0];

  const {
    requestInfo: { normalized: uuidActions },
    fetch: refetchUUIDActions,
  } = usePrefetch(
    'getUuidActions',
    {
      'filter[actionType]': `${PROFILE_CONTACTED},${CONSULTATION_REQUESTED}`,
    },
    { sessionOnly: true }
  );

  const getPricingRequestedListBySlugs = useCallback(
    (slugs) => {
      if (!slugs || !uuidActions) {
        return [];
      }
      const pricingRequestSlugs = uuidActions.reduce((acc, curr) => {
        if (curr?.actionType !== PROFILE_CONTACTED) {
          return acc;
        }
        if (slugs?.includes(curr?.actionInfo?.slug)) {
          acc.push(curr?.actionInfo?.slug);
        }

        if (curr?.actionInfo?.slugs) {
          curr?.actionInfo?.slugs?.forEach((slug) => {
            if (slugs.includes(slug)) {
              acc.push(slug);
            }
          });
        }

        return acc;
      }, []);

      return pricingRequestSlugs;
    },
    [uuidActions]
  );

  const getPricingRequestedBySlug = useCallback(
    (slug) => {
      const [pricingRequestedSlug] = getPricingRequestedListBySlugs([slug]);

      return pricingRequestedSlug;
    },
    [getPricingRequestedListBySlugs]
  );

  const pricingRequestActionSlug = getPricingRequestedBySlug(slug);

  const pricingRequested =
    !!pricingRequestActionSlug && pricingRequestActionSlug === slug;

  return {
    completedPricing: pricingRequested,
    refetchUUIDActions,
    completedAssessment: !!uuidActions?.length,
    uuidActions,
    getPricingRequestedBySlug,
    getPricingRequestedListBySlugs,
  };
};

export default useHasPricingRequested;
