/** @jsxImportSource react */
import { cn } from '@common/lib/clsxm';
import { Dialog } from '@headlessui/react';
import ModalHeader from '@react/shared/components/Modal/header';
import { useRef } from 'react';

export default function WizardDialog({
  handleClose,
  children,
  isOpen,
  className,
}: {
  handleClose: () => Promise<void> | void;
  children: React.ReactNode;
  isOpen: boolean;
  className?: string;
}) {
  const overlayRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  return (
    <Dialog
      open={isOpen}
      as='div'
      onClose={handleClose}
      initialFocus={overlayRef}
      className={cn('group relative z-[10001]', className)}
    >
      <>
        <div className='fixed inset-0 bg-black/80 backdrop-blur-sm group-data-[headlessui-state=open]:animate-in group-data-[headlessui-state=closed]:animate-out group-data-[headlessui-state=closed]:fade-out-0 group-data-[headlessui-state=open]:fade-in-0' />

        <div className='fixed inset-0 overflow-y-auto data-[headlessui-state=closed]:zoom-out-95 group-data-[headlessui-state=open]:animate-in group-data-[headlessui-state=closed]:animate-out group-data-[headlessui-state=closed]:fade-out-0 group-data-[headlessui-state=open]:fade-in-0 group-data-[headlessui-state=open]:zoom-in-95 group-data-[headlessui-state=closed]:slide-out-to-bottom-[48%] group-data-[headlessui-state=open]:slide-in-from-bottom-[48%]'>
          <div className='flex min-h-full items-center justify-center p-4'>
            <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all'>
              <ModalHeader className='py-6' toggleModal={handleClose} />
              {children}
            </Dialog.Panel>
          </div>
        </div>
      </>
    </Dialog>
  );
}
