/** @jsxImportSource react */
import { Checkbox as CheckboxIcon } from '@sly/icons/react';
import { bool, string } from 'proptypes';

const Checkbox = ({ checked, palette, ...props }) => {
  return (
    <CheckboxIcon
      palette={palette}
      active={checked}
      {...props}
      data-tp-id='gen-Icon-53f15f9a-af73-4091-be0b-2afff8bd8af5'
    />
  );
};

Checkbox.propTypes = {
  checked: bool.isRequired,
  palette: string,
};

Checkbox.defaultProps = {
  checked: false,
  palette: 'primary',
};

export default Checkbox;
