/** @jsxImportSource react */
import clsxm from '@common/lib/clsxm';
import { Button } from '@react/system';
import { node, string } from 'proptypes';

const CtaButton = ({
  children,
  palette,
  className,
  Icon,
  buttonRef,
  buttonId,
  ...props
}) => {
  const buttonWrapperClass = Icon ? 'flex gap-m justify-between' : '';
  return (
    <Button
      data-tp-id='gen-Button-61790c47-e176-4583-88fe-1876bda62dd3'
      className={clsxm(
        'h-max w-full text-center',
        className,
        buttonWrapperClass
      )}
      palette={palette}
      ref={buttonRef}
      id={buttonId}
      {...props}
    >
      {children}
      {Icon && (
        <Icon
          color='text-white-base'
          data-tp-id='gen-Icon-fdc59694-8ce8-43f7-a9d6-538202bd83a5'
        />
      )}
    </Button>
  );
};

CtaButton.propTypes = {
  children: node,
  palette: string,
};

export default CtaButton;
