export const formatMoney = (amount: string | number, decimalCount = 0) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimalCount,
    maximumFractionDigits: decimalCount
  });

  const number =
    typeof amount === 'string'
      ? parseFloat(amount.replace(/[^\d.]/g, ''))
      : amount;
  return formatter.format(number);
};

export const formatPricingFromMinAndMax = (min: number, max: number) => {
  if (min === max) {
    return formatMoney(min);
  }
  return `${formatMoney(min)} - ${formatMoney(max)}`;
};

export const getAverageFromMinAndMax = (min: number, max: number) => {
  if (!min || !max) return 0;
  const avg = (min + max) / 2;
  return formatMoney(avg);
};

export const formatNumber = (num: number) => {
  return new Intl.NumberFormat().format(num);
};

// Convert any number in the thousands to a string with a K suffix
// e.g. 1000 => '1K'
// e.g. 2000 => '2K'
// e.g. 2500 => '2.5K'
export const formatNumberToK = (num: number) => {
  if (num < 1000) return num.toString();
  const k = num / 1000;
  const rounded = Math.round(k * 10) / 10;
  return `${rounded}K`;
};
