import { VariantProps, cva } from 'class-variance-authority';

export type RatingBadgeIntent = VariantProps<typeof ratingBadge>['intent'];

export interface RatingBadgeProps extends VariantProps<typeof ratingBadge> {
  rating: string;
  class?: string;
  className?: string;
}

export const ratingBadge = cva('flex items-center justify-center h-max', {
  variants: {
    intent: {
      darkGreen: 'bg-green-darker-40',
      green: 'bg-green-base',
      yellow: 'bg-yellow-darker-40',
      red: 'bg-red-base',
      slate: 'bg-slate-base',
      slateLighter: 'bg-slate-lighter-80'
    },
    size: {
      large: 'rounded-lg py-0.5 px-3 text-2xl font-bold leading-10 text-white',
      medium:
        'rounded-lg py-0.5 px-2 text-base w-10 font-medium leading-6 text-white',
      small: 'font-b-s py-.05 mr-2 w-8 rounded text-white'
    }
  },
  defaultVariants: {
    intent: 'green',
    size: 'large'
  }
});
