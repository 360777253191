import proptypes from 'prop-types';

const {
  bool,
  func,
  number,
  object,
  oneOfType,
  string,
  any,
  array,
  arrayOf,
  element,
  checkPropTypes,
  elementType,
  exact,
  instanceOf,
  node,
  nominalTypeHack,
  objectOf,
  oneOf,
  resetWarningCache,
  shape,
  symbol,
} = proptypes;

export {
  bool,
  func,
  number,
  object,
  oneOfType,
  string,
  any,
  array,
  arrayOf,
  element,
  checkPropTypes,
  elementType,
  exact,
  instanceOf,
  node,
  nominalTypeHack,
  objectOf,
  oneOf,
  resetWarningCache,
  shape,
  symbol,
};

export default proptypes;
