import { RatingBadgeIntent } from '@common/cva/ratingBadge';
import round from '@react/lib/helpers/round';

export const getScoreTextAndIntent = (
  score: number
): { text: string; color: RatingBadgeIntent } => {
  if (score >= 8) return { text: 'Excellent', color: 'green' };
  if (score >= 6) return { text: 'Very good', color: 'green' };
  if (score >= 3) return { text: 'Average', color: 'yellow' };
  return { text: 'Below average', color: 'red' };
};

export function convertZeroToHundredToScaleOfTen(value: number): number {
  const result = value / 10;
  if (result < 0) {
    return 0;
  }
  if (result > 10) {
    return 10;
  }
  return round(result, 1);
}
