/** @jsxImportSource react */
import clsxm from '@common/lib/clsxm';
import Spinner from '@react/shared/components/Spinner';
import { Button } from '@react/system';
import { useWizarForm } from '@react/wizards/context/wizardContext';
import { Chevron } from '@sly/icons/react';
import { useFormState } from 'react-final-form';

export default function GwizzyActions({
  onClick,
  variant,
  text,
  className,
  action,
  fields,
  noOfActions,
  isChatMode,
}) {
  const { values, submitting, errors, validating } = useFormState();
  const fieldsWithError = Object.keys(errors);

  const {
    state: { status },
  } = useWizarForm();

  const isInvalid = !!fieldsWithError.filter((f) => fields.includes(f)).length;

  return (
    <Button
      disabled={
        action !== 'onBack' &&
        (submitting || isInvalid || validating || status === 'pending')
      }
      type='button'
      variant={action === 'onBack' ? 'secondary' : variant}
      className={clsxm(
        className,
        action === 'onBack' && 'text-black',
        noOfActions === 1 && 'w-full text-center',
        isChatMode && '!py-2'
      )}
      eventProps={{
        text,
        action,
        location: 'gwizzy-action',
      }}
      onClick={() => onClick(values)}
    >
      <div className='flex justify-center gap-2'>
        {action === 'onBack' ? (
          <Chevron className='min-w-[24px] -rotate-90' size='m' />
        ) : status === 'pending' ? (
          <Spinner />
        ) : null}

        <div>{text}</div>
      </div>
    </Button>
  );
}
