/** @jsxImportSource react */
import clsxm from '@common/lib/clsxm';
import { Dialog } from '@headlessui/react';
import { Close } from '@sly/icons/react';
import { ReactNode } from 'react';
import { useSwipeable } from 'react-swipeable';

export default function ModalHeader({
  toggleModal,
  title,
  className,
  children,
  noBorder,
}: {
  toggleModal: () => Promise<void> | void;
  title?: string;
  className?: string;
  children?: ReactNode;
  noBorder?: boolean;
}) {
  const handlers = useSwipeable({
    onSwipedDown: async () => await toggleModal(),
    trackMouse: true,
    delta: 30,
  });
  return (
    <>
      <div
        {...handlers}
        className={clsxm(
          'flex items-center px-6 pt-6',
          className,
          title || children ? 'justify-between' : 'justify-end',
          noBorder ? 'border-none' : 'border-b border-slate-lighter-90'
        )}
      >
        {title && (
          <Dialog.Title as='h3' className='font-t-m '>
            {title}
          </Dialog.Title>
        )}
        {children && children}
        <button onClick={async () => await toggleModal()}>
          <Close className='hover:cursor-pointer' />
        </button>
      </div>
    </>
  );
}
