/** @jsxImportSource react */
/* eslint-disable no-mixed-operators */
import cx from 'classnames';
import { number, object, string } from 'proptypes';
import React, { Fragment } from 'react';

const times = (nr, fn) => Array.from(Array(nr).keys()).map((_, i) => fn(i));

// eslint-disable-next-line react/prop-types
const StarPath = ({ ...props }) => (
  <path
    fill='currentColor'
    fillRule='evenodd'
    d='M12 17.5l-4.672 2.456a1 1 0 0 1-1.451-1.054l.892-5.202-3.78-3.685a1 1 0 0 1 .555-1.706l5.223-.759 2.336-4.733a1 1 0 0 1 1.794 0l2.336 4.733 5.223.76a1 1 0 0 1 .555 1.705L17.23 13.7l.892 5.202a1 1 0 0 1-1.45 1.054L12 17.5z'
    {...props}
  />
);

const randHash = () => Math.random().toString(36).substring(7);

// eslint-disable-next-line react/prop-types
const MaskedStar = ({ id, value, ...props }) => {
  const x = Math.round(((value % 1) * 0.8 + 0.1) * 24);
  return (
    <>
      <mask id={id}>
        <rect x='0' y='0' width='24' height='24' fill='white' />
        <rect x={x} y='0' width='24' height='24' fill='black' />
      </mask>
      <StarPath mask={`url(#${id})`} {...props} />
    </>
  );
};

const Rating = React.forwardRef(
  (
    {
      value,
      size,
      width,
      seedId,
      fillColor,
      className,
      innerClassName,
      loopCount,
      viewBox,
    },
    ref
  ) => (
    <div
      className={cx('whitespace-nowrap leading-none', className)}
      data-tp-id='gen-div-6f78c38b-9a8a-4906-b4b2-c1e940e4cab1'
    >
      <svg
        className={innerClassName}
        ref={ref}
        size={size}
        width={width}
        viewBox={viewBox}
      >
        {times(loopCount, (i) => (
          <Fragment key={`star${i}`}>
            {value >= i + 1 && (
              <StarPath
                className={fillColor ? fillColor : 'fill-yellow-base'}
                transform={`translate(${i * 24}, 0)`}
              />
            )}
            {value < i + 1 && (
              <StarPath
                className='fill-slate-lighter-80'
                transform={`translate(${i * 24}, 0)`}
              />
            )}
            {value > i && value < i + 1 && (
              <MaskedStar
                className={fillColor ? fillColor : 'fill-yellow-base'}
                id={`${seedId}_${i}`}
                value={value}
                transform={`translate(${i * 24}, 0)`}
              />
            )}
          </Fragment>
        ))}
      </svg>
    </div>
  )
);

Rating.displayName = 'Rating';

Rating.propTypes = {
  size: string,
  innerRef: object,
  value: number.isRequired,
  color: string,
  seedId: string,
  width: number,
  fillColor: string,
  className: string,
  loopCount: number,
  innerClassName: string,
  viewBox: string,
};

Rating.defaultProps = {
  size: 'body',
  color: 'primary',
  seedId: `${randHash()}`,
  loopCount: 5,
  innerClassName: 'w-[5rem]',
  viewBox: '0 0 120 24',
};

export default Rating;
