import { DisclosureFieldNames } from '@react/gwizzy/constants';
import { Agent, Community } from '@sly/core/types';
import { GwizzyCommunity } from './transformGwizzyCommunity';
import { AGENT_STATUS_LIVE } from '@sly/core/constants/agents';

export const isDisclosureEnabledForState = (state: string) => {
  return DisclosureFieldNames[state]?.isEnabled;
};

export const isD2cCommunity = (community: Community) => {
  return !!community?.rgsAux?.rgsInfo?.contract_info?.isAutoReferable;
};

export const getDisclosureEnabledStatesInD2CCommunities = (
  communities: Community[]
) => {
  return getDisclosureEnabledStates(
    communities?.map(community => {
      return {
        state: community?.address?.state,
        isD2c: isD2cCommunity(community)
      };
    })
  );
};

export const getDisclosureEnabledStatesInD2CGwizzyCommunities = (
  communities: GwizzyCommunity[]
) => {
  return getDisclosureEnabledStates(
    communities?.map(community => {
      return {
        state: community.state,
        isD2c: community.isAutoReferable
      };
    })
  );
};

const getDisclosureEnabledStates = (
  data: { state: string; isD2c: boolean }[]
) => {
  const dislosureEnabledStates = Object.entries(DisclosureFieldNames)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .filter(([_, value]) => value?.isEnabled)
    .map(([key]) => key);

  const enabledStates = new Set();

  data?.forEach(({ state, isD2c }) => {
    if (dislosureEnabledStates.includes(state) && isD2c) {
      enabledStates.add(state);
    }
  });
  return Array.from(enabledStates);
};

export const getLivePartnerAgent = (partnerAgents: Agent[] | undefined) => {
  if (!partnerAgents) return null;
  const livePartnerAgents = partnerAgents.filter(
    a => a.status >= AGENT_STATUS_LIVE
  );
  return livePartnerAgents.length > 0 ? livePartnerAgents[0] : null;
};

export const getPartnerAgent = (community: Community) => {
  if (!community) return null;
  const { partnerAgents } = community;

  return getLivePartnerAgent(partnerAgents);
};
