import React, { forwardRef, Ref } from 'react';
import { SimpleIconProps } from '../icon.types';
import Icon from './Icon';

const svg = `<svg viewBox="0 0 24 24" fill="currentColor">
  <path d="M12 10.9603L8.19566 14.7647C7.98442 14.9759 7.71921 15.0815 7.40001 15.0815C7.08081 15.0815 6.81559 14.9759 6.60436 14.7647C6.39312 14.5534 6.28751 14.2882 6.28751 13.969C6.28751 13.6498 6.39312 13.3846 6.60436 13.1734L11.1984 8.57936C11.3103 8.46739 11.4346 8.3856 11.5712 8.33398C11.7078 8.28235 11.8507 8.25653 12 8.25653C12.1493 8.25653 12.2922 8.28235 12.4288 8.33398C12.5654 8.3856 12.6897 8.46739 12.8016 8.57936L17.3957 13.1734C17.6069 13.3846 17.7125 13.6498 17.7125 13.969C17.7125 14.2882 17.6069 14.5534 17.3957 14.7647C17.1844 14.9759 16.9192 15.0815 16.6 15.0815C16.2808 15.0815 16.0156 14.9759 15.8044 14.7647L12 10.9603Z"/>
</svg>`;

const Chevron = forwardRef(
  (props: SimpleIconProps, ref: Ref<SVGSVGElement> | undefined) => (
    <Icon ref={ref} name='chevron' svg={svg} {...props} />
  )
);

Chevron.displayName = 'ChevronIcon';

export default Chevron;
