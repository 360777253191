import getConfig from 'next/config';

// defaults to dev env, otherwise specify with env vars
const tryJson = (value) => {
  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
};

// Get next runtime config from next.config.js
const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();

const envPick = (value, otherwise = undefined) => {
  return typeof value !== 'undefined' && value !== ''
    ? tryJson(value)
    : otherwise;
};

export const SLY_ENV = envPick(publicRuntimeConfig.SLY_ENV, 'development');
export const GA_ENV = envPick(process.env.GA_ENV, 'development');
export const ASSETS_URL = envPick(
  process.env.NEXT_PUBLIC_ASSETS_URL || process.env.ASSETS_URL,
  'https://d354o3y6yz93dt.cloudfront.net'
);
export const HOST = envPick(
  publicRuntimeConfig.HOST,
  'https://www.seniorly.dev'
);
export const API_URL = envPick(
  publicRuntimeConfig.API_URL,
  'https://www.seniorly.dev/v0'
);
export const CMS_URL = envPick(
  publicRuntimeConfig.CMS_URL,
  'https://cms.seniorly.dev/api'
);
export const DOMAIN = envPick(publicRuntimeConfig.DOMAIN, 'seniorly.dev');
export const GOOGLE_CLIENT_ID = envPick(
  publicRuntimeConfig.GOOGLE_CLIENT_ID,
  '522248695659-f0b3obj2ggorooclkfnt2fsfpo14urti.apps.googleusercontent.com'
);
export const GOOGLE_MAPS_API_KEY = envPick(
  publicRuntimeConfig.GOOGLE_MAPS_API_KEY,
  'AIzaSyALxJg-oMW7SvkQ27KFTuWjTLedXcAhrZE'
);
export const RASA_URL = envPick(
  publicRuntimeConfig.RASA_URL,
  'http://localhost:5005'
);
export const VERSION = process.env.VERSION;
export const FB_CLIENT_ID = envPick(
  process.env.FB_CLIENT_ID,
  '624602444328776'
);
export const HIDE_CHATBOX = envPick(
  process.env.NEXT_PUBLIC_HIDE_CHATBOX || process.env.HIDE_CHATBOX,
  false
);
export const DISABLE_EXPERIMENTS = envPick(
  process.env.DISABLE_EXPERIMENTS,
  false
);
export const MUTE_REDUX_LOGGER = envPick(process.env.MUTE_REDUX_LOGGER, true);
export const ENABLE_EXPERIMENT_DEBUGGER = envPick(
  process.env.ENABLE_EXPERIMENT_DEBUGGER,
  false
);
export const SEGMENT_API_KEY = envPick(
  process.env.NEXT_PUBLIC_SEGMENT_API_KEY || process.env.SEGMENT_API_KEY,
  'fYjvSNdbzzyXTCK61Gou6X0WRVzX7tlj'
);
export const TYPEFORM_FORMID = envPick(
  publicRuntimeConfig.TYPEFORM_FORMID,
  'IwEipLO8'
);

export const MEILI_HOST = envPick(
  publicRuntimeConfig.MEILI_HOST,
  'https://meili.seniorly.com'
);

export const MEILI_KEY = envPick(
  publicRuntimeConfig.MEILI_KEY,
  'ZTRmZjY1ZjlhOTFlOTIyZmNmNmYxNDk2'
);

export const CAUSEWAY_KEY = envPick(process.env.CAUSEWAY_KEY, 'BA0scc8gCg');

export const NODE_ENV = envPick(process.env.NODE_ENV, 'development');
