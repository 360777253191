import NextLink from 'next/link';
import { func } from 'proptypes';
import React from 'react';

const createNextLink = () => {
  const NextLinkAnchor = React.forwardRef(({ to, href, ...props }, ref) => {
    return (
      <NextLink legacyBehavior href={to || href}>
        <a ref={ref} {...props} />
      </NextLink>
    );
  });

  NextLinkAnchor.propTypes = {
    navigate: func,
    onClick: func,
  };

  return NextLinkAnchor;
};

export default createNextLink;
