/** @jsxImportSource react */

import { Checkbox } from '@sly/icons/react';
import cx from 'classnames';
import { bool, func, node, string } from 'proptypes';
import React, { forwardRef, useCallback } from 'react';
import { Button } from 'sly/system';

const buttonBaseStyles =
  'box-border font-b-m p-m whitespace-nowrap overflow-hidden text-ellipsis cursor-pointer focus:outline-none';

const borderlessButtonBaseStyles =
  'border-none font-b-m p-0 whitespace-nowrap overflow-hidden cursor-pointer text-ellipsis focus:outline-none';

const getColors = (
  disabled,
  value,
  palette,
  withBorder,
  [defaultBackground, activeBackground]
) => {
  if (disabled) {
    return 'text-slate-base bg-slate-lighter-90 ';
  }

  const baseColor = `!text-${palette}-base`;
  return cx(
    value ? baseColor : 'text-slate-base',
    value && withBorder ? activeBackground : defaultBackground,
    value ? `!border-${palette}-base` : 'border-slate-lighter-90',
    `hover:text-${palette}-base`,
    'hover:border-current',
    `active:text-${palette}-base`,
    'active:border-current'
  );
};

const getStyles = ({ palette, value, disabled, withBorder }) => {
  const buttonStyles = withBorder
    ? buttonBaseStyles
    : borderlessButtonBaseStyles;
  return cx(
    buttonStyles,
    getColors(disabled, value, palette, withBorder, [
      'white-base',
      `${palette}-lighter-90`,
    ])
  );
};

const CheckboxButton = forwardRef(({ children, className, ...props }, ref) => {
  const onChange = useCallback(
    (event) => {
      props.onChange(!props.value);
      event.stopPropagation();
      event.preventDefault();
    },
    [props.onChange, props.value]
  );

  const { withBorder, palette, value, disabled, ...otherProps } = props;

  return (
    <Button
      ref={ref}
      variant='neutral'
      onClick={onChange}
      {...otherProps}
      disabled={disabled}
      palette={palette}
      className={cx(
        'rounded-box p-m',
        getStyles({ withBorder, palette, value, disabled }),
        className
      )}
      data-tp-id='gen-Button-ae39084e-0c62-4395-801e-a4b171d5795e'
    >
      <Checkbox
        size='m'
        active={props.value}
        className={cx(
          'icon-style mr-s',
          props.value ? `text-${props.palette}-base` : ''
        )}
      />
      <span data-tp-id='gen-span-0a53be39-b21a-491c-ab66-f57178e69541'>
        {children}
      </span>
    </Button>
  );
});

CheckboxButton.propTypes = {
  palette: string,
  value: bool,
  disabled: bool,
  onChange: func,
  children: node,
  withBorder: bool,
};

CheckboxButton.defaultProps = {
  palette: 'viridian',
  withBorder: true,
};

export default CheckboxButton;
