/** @jsxImportSource react */

import clsxm from '@common/lib/clsxm';
import { node, object, oneOfType, string } from 'proptypes';
import React from 'react';

import { HeadingFonts, HeadingLevels, HeadingProps } from './Heading.types';

const Heading = ({
  as: asProp = 'h2',
  font = 'font-t-l',
  children,
  className,
  ...props
}: HeadingProps) => {
  const baseClass = font || HeadingFonts[asProp] || 'font-t-l';
  const { innerRef, ...rest } = props;
  return React.createElement(
    asProp || HeadingLevels[font] || 'h2',
    { className: clsxm(baseClass, className), ref: innerRef, ...rest },
    children
  );
};

Heading.propTypes = {
  children: node,
  as: oneOfType([string, node]),
  font: string,
  innerRef: object,
};

export default Heading;
