/** @jsxImportSource react */
import {
  badge,
  badgeIcon,
  badgeText,
} from '@react/card/components/CardClasses';
import cx from 'classnames';

const CardBadge = ({ tag, intent, className }) => {
  return (
    tag && (
      <div
        className={cx(
          badge({ intent }),
          tag.textBgColor || 'bg-white',
          className
        )}
        data-tp-id='gen-div-57b63ff4-9bb9-4989-8507-d50337179790'
      >
        <div className='flex items-center gap-1'>
          <tag.Icon
            size='xs'
            color={tag.iconColor}
            className={badgeIcon({ intent })}
          />

          <div
            className={cx(
              badgeText({ intent }),
              tag.textBgColor,
              tag.textColor ? tag.textColor : 'text-slate-base'
            )}
            data-tp-id='gen-span-a12f95a5-2eb3-4d61-92a2-3a97353eb9e8'
          >
            {tag.text}
          </div>
        </div>
      </div>
    )
  );
};

export default CardBadge;
