import { logWarn } from '@common/helpers/logging';

const identity = (a) => a;

const makeMiddleware = (pendingPromises, next) => (action) => {
  const {
    payload: {
      call,
      placeholders,
      options,
      actionName,
      path,
      isJsonApi,
      intercept = identity,
    },
  } = action;

  if (!call || !actionName || !placeholders) {
    logWarn(
      new Error('dispatching undefined action, check redux-bees queries')
    );
    return Promise.reject();
  }

  const actionKey = `${actionName}#${JSON.stringify(placeholders)}`;

  if (typeof pendingPromises[actionKey] !== 'undefined') {
    return pendingPromises[actionKey];
  }

  const meta = {
    api: true,
    name: actionName,
    params: placeholders,
    isJsonApi,
  };

  next({
    type: `api/${actionName}/request`,
    meta: { ...meta, type: 'request' },
  });

  const promise = call(path, placeholders, options)
    .then((result) => {
      next({
        type: `api/${actionName}/response`,
        payload: result,
        meta: { ...meta, type: 'response' },
      });
      return Promise.resolve(result);
    })
    .then(intercept)
    .catch((result) => {
      next({
        type: `api/${actionName}/error`,
        payload: result,
        meta: { ...meta, type: 'error' },
      });
      return Promise.reject(result);
    })
    .finally(() => {
      delete pendingPromises[actionKey];
    });

  promise.path = path;

  pendingPromises[actionKey] = promise;

  return promise;
};

export default makeMiddleware;
