/** @jsxImportSource react */
import { bool, string } from 'proptypes';
import React from 'react';
import clsxm from 'sly/lib/clsxm';

const InputMessage = ({
  name,
  Icon,
  iconSize = 'm',
  palette,
  message,
  className,
  ...props
}) => (
  <>
    {message && (
      <div
        className={clsxm('flex items-center gap-xs', className)}
        {...props}
        data-tp-id='gen-div-dfcb46dd-c04d-4c2e-b315-1d1717a09e41'
      >
        {Icon && (
          <Icon
            size={iconSize}
            palette={palette}
            data-tp-id='gen-Icon-84a383d3-c294-4a8a-8472-3c6d0fccd7bc'
          />
        )}
        <span
          id={name}
          role='alert'
          data-tp-id='gen-span-5f534d8e-f34e-4205-8d85-0a8d030afd3d'
        >
          {message}
        </span>
      </div>
    )}
  </>
);

InputMessage.propTypes = {
  name: string.isRequired,
  icon: string.isRequired,
  iconSize: string,
  palette: string.isRequired,
  message: string.isRequired,
  showIcon: bool,
};

InputMessage.defaultProps = {
  verticalAlign: 'middle',
  display: 'flex',
  showIcon: true,
};

export default InputMessage;
