/** @jsxImportSource react */
import hoistNonReactStatics from 'hoist-non-react-statics';
import { node } from 'proptypes';
import React, { createContext, useContext } from 'react';
import { isServer } from 'sly/config';

export const PopupsContext = createContext(null);

export const PopupsProvider = (props) => {
  let active = false;

  const setPopupActive = (bool) => {
    active = bool;
  };

  const isBotActive = () => {
    if (isServer) {
      return false;
    }
    const elementExists = document.getElementsByClassName(
      'roko-instabot-widget'
    )?.[0];
    if (!elementExists) {
      return false;
    }
    return getComputedStyle(elementExists, null).display !== 'none';
  };

  const isPopupActive = () => {
    return active || isBotActive();
  };

  const contextValue = {
    isPopupActive,
    setPopupActive,
  };

  return (
    <PopupsContext.Provider value={contextValue}>
      {props.children}
    </PopupsContext.Provider>
  );
};

PopupsProvider.propTypes = {
  children: node,
};

export const usePopups = () => useContext(PopupsContext);

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default function withPopups(InnerComponent) {
  const Wrapper = (props) => {
    const { isPopupActive, setPopupActive } = usePopups();
    return (
      <InnerComponent
        {...props}
        isPopupActive={isPopupActive}
        setPopupActive={setPopupActive}
      />
    );
  };

  Wrapper.displayName = `withPopups(${getDisplayName(InnerComponent)})`;
  Wrapper.WrappedComponent = InnerComponent.WrappedComponent || InnerComponent;
  hoistNonReactStatics(Wrapper, InnerComponent);

  return Wrapper;
}
