const careTypes = [
  'senior-living',
  'assisted-living',
  'independent-living',
  'board-and-care-home',
  'memory-care',
  'active-adult',
  'continuing-care-retirement-community',
  'skilled-nursing-facility',
  'care-home'
];
export default careTypes;

export const CARE_TYPES_OBJECT = {
  seniorLiving: 'senior-living',
  assistedLiving: 'assisted-living',
  independentLiving: 'independent-living',
  boardAndCareHome: 'board-and-care-home',
  memoryCare: 'memory-care',
  activeAdult: 'active-adult',
  ccrc: 'continuing-care-retirement-community',
  snf: 'skilled-nursing-facility',
  careHome: 'care-home'
};

export const CARE_TYPES_OBJECT_TITLES = {
  seniorLiving: 'Senior Living',
  assistedLiving: 'Assisted Living',
  independentLiving: 'Independent Living',
  boardAndCareHome: 'Board and Care Home',
  memoryCare: 'Memory Care',
  activeAdult: 'Active Adult',
  ccrc: 'Continuing Care Retirement Community',
  snf: 'Nursing Homes',
  careHome: 'Care Home'
};
